//TODO: fix questions with only one subquestion IN questions.js

export function getNumQuestionsAnswered(result){
    return result.filter(r =>r !== undefined).length
} 

//create array of an array of correct/ incorrect choices
export function getSubQuestionBooleanResults(result, answers) {
  const Array1 = [];
  for (let i = 0; i < answers.length; i++) {
    const Array2 = [];
   
    for (let j = 0; j < answers[i].length; j++) { //answers[i].length
      if (answers[i].length == 1){
        Array2.push(result[i][j] === answers[i][j]);
        Array2.push(result[i][j] === answers[i][j]);
        Array2.push(result[i][j] === answers[i][j]);
        Array2.push(result[i][j] === answers[i][j]);
      }
      Array2.push(result[i][j] === answers[i][j]);
    }
    Array1.push(Array2);
  }
  return Array1;
}

//create array of correct/ incorrect questions
export function getQuestionBooleanResults(result, answers){
  return result.map((element, i )=> answers[i].toString()===element.toString())
}

export function getQuestionIntResults(result, answers, queu) {
  const booleanResults = getSubQuestionBooleanResults(result, answers);
  const pointsArray = [];

  if (!queu || queu.length !== answers.length) {

    return pointsArray; 
  }
  
  for (let i = 0; i < 15; i++) {
    const points = queu[i].question_points;

    const countTrue = booleanResults[i].reduce((count, value) => count + (value === true), 0);
    const countFalse = booleanResults[i].reduce((count, value) => count + (value === false), 0);
    let questionPoints = (countTrue - countFalse) * (points/4);
  
    if (questionPoints < 0) {
      questionPoints = 0;
    } else if (questionPoints > points){
      questionPoints = points
    }

    pointsArray.push(questionPoints);
  }

  return pointsArray;
}

export function getTotalEarnedPoints(result, answers, queu){
  return getQuestionIntResults(result, answers, queu).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

export function getTotalPotentialPoints(queu){
  var points = 0;
  for (let i = 0; i < queu.length; i++) {
    points = points + queu[i].question_points;
}
return points;
}

export function getScore(potentialPoints, earnedPoints){
    return (`${(earnedPoints / potentialPoints * 100).toFixed(2)}`)
}

export function getVerdict(score){
    return score >= 70;
}