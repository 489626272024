export default {

    "deu":{

        "points": "punkte",
        "question": "Frage",
        "submitQuizConfirmation": "Sind Sie sicher, dass Sie an dem Quiz teilnehmen möchten?",
        "missingAnswersWarning": "Bitte geben Sie alle Ihre Antworten ein, bevor Sie das Quiz abschicken",
        "submitQuiz": "Quiz abschicken",
        "resetQuiz": "Quiz neu starten",
        "previous": "Vorherige",
        "next": "Nächste",
        "quizDin": "DIN 14675/ EN 16763 Brandmeldanlagen Prüfungsvorbereitung",
        "back": "Zurück",
        "selectOne": "(nur einen auswählen)",
        "selectMultiple": "(alle zutreffenden Punkte ankreuzen)",
        "quizRule1": "Du wirst 15 Fragen gestellt.",
        "quizRule2": "Punkte werden pro richtiger Antwort vergeben.",
        "quizRule3": "Jede Frage hat vier Optionen.",
        "quizRule4": "Du kannst Antworten überprüfen und ändern, bevor du den Test abschickst.",
        "quizRule5": "Das Ergebnis wird am Ende des Tests angezeigt.",
        "startQuiz": "Quiz starten",
        "FASQuizPrep": 'Brandmeldanlagen Prüfungsvorbereitung',
        "quizResults": "Quiz Ergebnisse",
        "quizReview": "Fragen überprüfen",
        "totalQuestions": "Gesamtanzahl der Fragen",
        "totalPotentialPoints": "Gesamtpunktzahl",
        "earnedPoints": "Erzielte Punkte",
        "score": "Punktzahl",
        "result": "Ergebnis",
        "pass": "Bestanden",
        "fail": "Nicht bestanden"
    },

    "eng": {
        
        "points" : "points",
        "question" : "question",
        "submitQuizConfirmation" : "Are you sure you want to submit the quiz?",
        "missingAnswersWarning" : "Please enter all your answers before submitting the quiz",
        "submitQuiz" : "Submit Quiz",
        "resetQuiz" : "Reset Quiz",
        "previous" : "Previous",
        "next" : "Next",
        "quizDin" : "DIN 14675/ EN 16763 fire alarm systems exam preparation",
        "back" : "Back",
        "selectOne": "(select one)",
        "selectMultiple": "(check all that apply)",
        "quizRule1": "You will be asked 15 questions.",
        "quizRule2": "Points are awarded for each correct answer.",
        "quizRule3": "Each question has four options.",
        "quizRule4": "You can review and change answers before submitting the test.",
        "quizRule5": "The results will be displayed at the end of the test.",
        "startQuiz": "Start Quiz",
        "FASQuizPrep": 'FAS exam preperation',
        "quizResults": "Quiz Results",
        "quizReview": "Review questions",
        "totalQuestions": "Total questions",
        "totalPotentialPoints": "Total potential points",
        "earnedPoints": "Earned points",
        "score": "Score",
        "result": "Result",
        "pass": "Pass",
        "fail": "Fail",
    }
    
}