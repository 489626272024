import _252 from './252.png';
import _253 from './253.png';
import _254 from './254.png';
import _255 from './255.png';
import _256 from './256.png';
import _257 from './257.png';
import _258 from './258.png';
import _259 from './259.png';
import _265 from './265.png';
import _267 from './267.png';
import _268 from './268.png';
import _270 from './270.png';
import _273 from './273.png';
import _274 from './274.png';
import _276 from './276.png';
import _277 from './277.png';
import _278 from './278.png';
import _279 from './279.png';
import _280 from './280.png';
import _281 from './281.png';
import _715 from './715.png';
import _716 from './716.png';
import _717 from './717.png';
import _718 from './718.png';
import _719 from './719.png';
import _720 from './720.png';
import _721 from './721.png';
import _722 from './722.png';
import _723 from './723.png';
import _724 from './724.png';
import _725 from './725.png';
import _726 from './726.png';
import _738 from './738.png';

export default {
    _252,
    _253,
    _254,
    _255,
    _256,
    _257,
    _258,
    _259,
    _265,
    _267,
    _268,
    _270,
    _273,
    _274,
    _276,
    _277,
    _278,
    _279,
    _280,
    _281,
    _715,
    _716,
    _717,
    _718,
    _719,
    _720,
    _721,
    _722,
    _723,
    _724,
    _725,
    _726,
    _738,
        };