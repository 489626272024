import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import '../styles/App.css';
import Main from './Main';
import Quiz from './Quiz';
import Result from './Result';
import Review from './Review';

const router = createBrowserRouter([
  {
  path: '/',
  element: <Main>Root component</Main>
}, {
  path: '/quiz',
  element: <Quiz>Quiz component</Quiz>
}, {
  path: '/result',
  element: <Result>Result component</Result>
},{
  path: '/review',
  element: <Review>Review answers</Review>
},
]);

function App() {
  return (
   <>
      <RouterProvider router={router}/>
   </>
  );
}

export default App;
