import {createSlice} from "@reduxjs/toolkit";


export const resultReducer = createSlice({
    name: 'result',
    initialState: {
        userId: null,
        result: [] //store user result here

    },
    reducers: {
        setUserId: (state, action) =>{
            state.userId = action.payload
        },
        pushResultAction: (state, action) => {
            state.result.push(action.payload)
        },
        updateResultAction: (state, action)=>{
            const { trace, checked } = action.payload;
            state.result.fill(checked, trace, trace + 1) //start is inclusive; end is exclusive
        },
        resetResultAction : () => {
            return {
                userId: null,
                result: []

            }
        }
    }
});

export const { setUserId, pushResultAction, resetResultAction, updateResultAction} = resultReducer.actions;
export default resultReducer.reducer;