import * as Action from '../redux/result_reducer';


export const PushAnswer = (result) => async (dispatch) => {
    try {
        await dispatch(Action.pushResultAction(result)); //store result (array) in results array
    } catch (error) {
        console.log(error);
    }
}

export const updateResult = (index) => async (dispatch) => {
    try {
        dispatch(Action.updateResultAction(index)); //{trace, checked}
    } catch (error) {
        console.log(error);
    }
}