// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container ol li{
    font-size: 1.4em;
    color: #cecece;
}

.start{
    display: flex;
    justify-content: center;
    padding-top: 2em;
}

.start .btn{
    /* display : flex; */
    padding: .2em 1.7em;
    border: none;
    border-radius: .1em;
    font-size: 1.2em;
    color: #202020;
    text-decoration: none;
    background-color: #faff5a;
}

#form{
    display: flex;
    justify-content: center;
    margin-top: 4em;
}

#form .userid{
    padding: .7em 2em;
    width: 50%;
    border: none;
    border-radius: 3px;
    font-size: 1em;
}`, "",{"version":3,"sources":["webpack://./src/styles/Main.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".container ol li{\n    font-size: 1.4em;\n    color: #cecece;\n}\n\n.start{\n    display: flex;\n    justify-content: center;\n    padding-top: 2em;\n}\n\n.start .btn{\n    /* display : flex; */\n    padding: .2em 1.7em;\n    border: none;\n    border-radius: .1em;\n    font-size: 1.2em;\n    color: #202020;\n    text-decoration: none;\n    background-color: #faff5a;\n}\n\n#form{\n    display: flex;\n    justify-content: center;\n    margin-top: 4em;\n}\n\n#form .userid{\n    padding: .7em 2em;\n    width: 50%;\n    border: none;\n    border-radius: 3px;\n    font-size: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
