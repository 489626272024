import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionIntResults, getQuestionBooleanResults, getSubQuestionBooleanResults, getVerdict, getScore, getTotalEarnedPoints, getTotalPotentialPoints, getNumQuestionsAnswered } from '../helper/helper';

import '../styles/Result.css';
import Review from './Review';
import { resetAllAction } from '../redux/question_reducer';
import { resetResultAction } from '../redux/result_reducer';
import textToTranslate from '../database/text';

export default function Result() {

  const dispatch = useDispatch();
  const {questions: { queu, answers }, result: { result, userId }}= useSelector(state => state);

  const questionsAnswered = getNumQuestionsAnswered(result);

  const potentialPoints = getTotalPotentialPoints(queu);
  const earnedPoints = getTotalEarnedPoints(result, answers, queu);

  const score = getScore(potentialPoints, earnedPoints);
  const verdict = getVerdict(score);

  const subquestionBooleanResults = getSubQuestionBooleanResults(result, answers);
  const questionBooleanResults = getQuestionBooleanResults(result, answers);
  const questionIntResults = getQuestionIntResults(result, answers, queu);

  const colorVar = verdict ? "#2aff95" : "#ff2a66";

  const language = 'eng';
  const text = textToTranslate[language];

  useEffect(()=>{
    console.log(result)
    console.log(queu)
    console.log(answers)
    console.log(subquestionBooleanResults)
  })

  function onRestart(){ //reset state when restart is pressed
    dispatch(resetAllAction());
    dispatch(resetResultAction());
  }

  return (
    <div className='container'>
      <h2 className='text-primary' style={{ color: 'white', textAlign: 'center'  }}>{text.quizResults}</h2>

        <div style={{ fontSize: '20px', border: `1px solid ${colorVar}`}} className='flex-center-question-results'>
            <div className='flex'>
            <span className='text-light'>{text.totalQuestions} </span>
              <span className='text-light' style={{ color: `${colorVar}`}}>{queu.length || 0}</span>
            </div>
            <div className='flex'>
            <span className='text-light'>{text.totalPotentialPoints} </span>
              <span className='text-light' style={{ color: `${colorVar}`}} >{potentialPoints || 0}</span>
            </div>
            <div className='flex'>
            <span className='text-light'>{text.earnedPoints} </span>
              <span className='text-light' style={{ color: `${colorVar}`}} >{earnedPoints || 0}</span>
            </div>
            <div className='flex'>
            <span className='text-light'>{text.score}  </span>
              <span className='text-light' style={{ color: `${colorVar}`}} >{score}%</span>
            </div>
            <div className='flex'>
            <span className='text-light'>{text.result}  </span>
              <span className='text-light' style={{ color: `${colorVar}`}} >{verdict ? text.pass : text.fail}</span>
            </div>
        </div>
        <div className="container">
          <Review>{text.quizReview}</Review>
        </div>
        <div className='start'>
          <Link className='btn' to={'/'} onClick={onRestart}>
            {text.resetQuiz}
          </Link>
        </div>
    </div>
  )
}
