import { useState, useEffect } from "react";
import data from "../database/data";
import { useDispatch } from "react-redux"; //cant access hooks via functions, only other hooks
//fetch question hook to fetch api data and set values to store

//redux actions
import * as Action from '../redux/question_reducer';
import { getRandomQuestions } from "../helper/randomizeQuestions";

export const useFetchQuestion = () => { //custom hook needs to start with use
    const [getData, setGetData] = useState({isLoading: false, apiData: [], serverError: null});
    const dispatch = useDispatch();
    useEffect(()=>{
        setGetData(prev=> ({...prev, isLoading: true}));

        //async function to patch backend data
        (async () => {
           try{
            // let question = await data.questions; //await not needed ATM => data in json file

            let question = getRandomQuestions(data, 15);
            // let categories = await data.categories;
            let answers = question.map(question =>
                question.question_choices.map(choice => choice.correct_answer)
              );
              console.log(answers);


            if(question.length > 0){
                setGetData(prev=> ({...prev, isLoading: false}));
                setGetData(prev=> ({...prev, apiData: {question, answers}}));

                //dispatch an action and update store
                dispatch(Action.startExamAction({question, answers})); //question is the payload
            } else{
                throw new Error("No question available");
            }

           }catch(error){
        setGetData(prev=> ({...prev, isLoading: false}));
        setGetData(prev=> ({...prev, serverError: JSON.stringify(error)}));

        
           }

        })();
    }, [dispatch]);

    return [getData, setGetData];
}

//next action Dispatch question
export const MoveNextQuestion = () => async (dispatch) => {
    try{
        dispatch(Action.moveNextAction());
    }catch(error){
        console.log(error);
    }
};
//previous action Dispatch question
export const MovePrevQuestion = () => async (dispatch) => {
    try{
        dispatch(Action.movePrevAction());
    }catch(error){
        console.log(error);
    }
}