import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchQuestion } from '../hooks/FetchQuestion';
import { updateResult } from '../hooks/setResult';
import data from "../database/data";
import quizPictures from "../database/pictures";
import textToTranslate from '../database/text';



const subcategoryMapping = data.categories.flatMap(category => category.subcategories).reduce((mapping, subcategory) => {
  mapping[subcategory.subcategory_id] = subcategory.subcategory_name;
  return mapping;
}, {});

function getCategoryNameById(categoryId) {
  const foundCategory = data.categories.find(category => category.category_id === categoryId);
  return foundCategory ? foundCategory.category_name : null;
}

export default function Questions({ onChecked }) {
    
    const { queu, trace, answers, result, userId } = useSelector(state => ({
      ...state.questions,
      ...state.result }));

    const questions = queu[trace];
    const dispatch = useDispatch();
    const [selectedOptions, setSelectedOptions] = useState(Array(4).fill(false));
    const [checked, setChecked] = useState(undefined);
    const [{isLoading, serverError}] = useFetchQuestion();
    const language = 'eng';
    const text = textToTranslate[language];
    
    useEffect(()=>{
      dispatch(updateResult({trace, checked}));
    },[checked]);

    useEffect(() => {
      if (trace >= result.length) {
        setSelectedOptions(Array(4).fill(false));
      }
    }, [ trace, result]);

    function onSelect(choiceIndex){
        const updatedOptions = [...selectedOptions];
        updatedOptions[choiceIndex] = !updatedOptions[choiceIndex];
        setSelectedOptions(updatedOptions);
        onChecked(updatedOptions);
        setChecked(updatedOptions);
        dispatch(updateResult({trace, checked}))
    }

    if(isLoading){return (<h3 className='text-light'>isLoading</h3>)};
    if(serverError){return (<h3 className='text-light'>{serverError || "unkown error"}</h3>)};


    const currentCategory = getCategoryNameById(parseInt(questions?.subcategory_id));
    const currentSubCategory = subcategoryMapping[questions?.subcategory_id];
    const currentCategoryId = parseInt(questions?.subcategory_id);
    const currentProgress = `${trace+1}/${queu.length}`;
    const currentQuestionId = questions?.question_id;
    const currentQuestionPoints = questions?.question_points;
    const currentQuestionMetaInfo = questions?.question_points == 1 ? text.selectOne : text.selectMultiple;
    const currentQuestionPicture = quizPictures[`_${currentQuestionId}`];
    const currentChoiceArrayResult = result[trace];
    const existsPicture = currentQuestionPicture != undefined;
    const choiceIndex = 0;
    const isCurrentChoiceChecked = currentChoiceArrayResult && typeof currentChoiceArrayResult[choiceIndex] === 'boolean' && currentChoiceArrayResult[choiceIndex];

  return (
    <>
    {/*------------------------------------------------------------------------------------------------------------------------------------------------Question header*/}
      <div className='flex-center-question-header'>
          <div className='text-light'>
              <div style={{ textAlign: 'left' }}>
                  <h1>{currentCategoryId}. {currentCategory}</h1>
                  <h2>{questions?.subcategory_id}. {currentSubCategory}</h2>
              </div>
              <div style={{ textAlign: 'right' }}>
                  <h2>{text.question} {currentProgress}</h2>
                  <h2>{currentQuestionPoints} {text.points}</h2>
              </div>
          </div>
      </div>

    {/*----------------------------------------------------------------------------------------------------------------------------------------------Question & choices*/}
      <div className='flex-center-question-body'>
          <h2 className='text-light'>{questions?.question_text} {currentQuestionMetaInfo}</h2>
          {
            existsPicture && 
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                  <img src={currentQuestionPicture} style={{height: '600px', width: '800px'}} />
              </div>
          }
          <ul style={{overflow: 'hidden'}} key={currentQuestionId}>
              {
              questions?.question_choices.map((choiceData, choiceIndex) => (
                  <li key={choiceIndex}>
                      <input type='checkbox' value={false} style={{ display: 'none' }} checked={isCurrentChoiceChecked} 
                      name='options' id={`choiceData${choiceIndex}-option`} onChange={()=> onSelect(choiceIndex)} />
                      <label className='text-primary' htmlFor={`choiceData${choiceIndex}-option`}>
                          {choiceData.choice}
                      </label>
                      <div className={`check ${isCurrentChoiceChecked || selectedOptions[choiceIndex] ? 'checked' : '' }`} ></div>
                  </li>
              ))}
          </ul>
      </div>
    </>
  )}
