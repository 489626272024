import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';

import '../styles/Main.css';
import textToTranslate from '../database/text';


export default function Main() {

const language = 'eng';
const text = textToTranslate[language];

  return (
    <div className='container'>
        <h3 style={{ color: 'white' }}>DIN 14675/ EN 16763</h3>
            <h3 style={{fontSize: '24px'}}className='title text-light'>{text.FASQuizPrep}</h3>
        
                <ol className='result flex-center'>
                    <li>{text.quizRule1}</li>
                    <li>{text.quizRule2}</li>
                    <li>{text.quizRule3}</li>
                    <li>{text.quizRule4}</li>
                    <li>{text.quizRule5}</li>
                </ol>

                <div className='start'>
                    <Link className='btn' to={'quiz'}>
                        {text.startQuiz}
                    </Link>
                </div>
    </div>
  )
}
