import _ from 'lodash';

export function getRandomQuestions(data, numQuestions) {
  // Check if there are enough questions
  if (data.questions.length < numQuestions) {
    throw new Error('Not enough questions available');
  }

  // Shuffle the questions to randomize their order
  const shuffledQuestions = _.shuffle(data.questions);

  // Select the specified number of questions
  const randomQuestions = shuffledQuestions.slice(0, numQuestions);

  return randomQuestions;
}
