import { createSlice } from "@reduxjs/toolkit"

//create reducer
export const questionReducer = createSlice({
    name: 'questions',
    initialState:{
        queu: [], //store all questions
        answers: [], //store all answers
        trace: 0 //page/ question number
    },
    reducers: { //reducers can specify/ dispatch actions which can change the values of the store
        startExamAction: (state, action)=>{
            let { question, answers } = action.payload
            return {
                ...state,
                // queu: action.payload//
                queu: question,
                answers: answers
            }
        },
        moveNextAction: (state, action) => {
            return {
                ...state,
                trace : state.trace +1
            }
        },
        movePrevAction: (state, action) => {
            return {
                ...state,
                trace : state.trace -1
            }
        },
        resetAllAction:()=>{
            return{
                queu: [],
                answers: [],
                trace: 0
            }
        }
    }
});

export const {startExamAction, moveNextAction, movePrevAction, resetAllAction} = questionReducer.actions;

export default questionReducer.reducer; //dont need import { questionReducer} => import questionReducer is enough