import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { questionReducer } from '../redux/question_reducer';
import { getQuestionIntResults } from '../helper/helper';
import quizPictures from "../database/pictures";
import textToTranslate from '../database/text';


export default function Review() {
  const {questions: { queu, answers }, result: { result, userId }}= useSelector(state => state);
  const state  = useSelector(state => state);

  const questionIntResults = getQuestionIntResults(result, answers, queu);
  const language = 'eng';
  const text = textToTranslate[language];

  useEffect(() => {
    console.log(state);
  }, []);


  return (
    <div >
      <h2 className='text-primary' style={{ color: 'white', textAlign: 'center'  }}>{text.quizReview}</h2>
      <div style={{ color: 'white' }} className='questions'>
        <ul >
        
        {queu.map((question, questionIndex) => (
          <h3 style={{ border: `1px solid ${question.question_choices.map(choice => choice.correct_answer.toString())==result[questionIndex].toString() 
          ? '#00FF00' : '#FF0000'}`, marginBottom: '30px', borderRadius: '0%'}} className='flex-center-question-results'>
          <ul style={{overflow: 'hidden'}} key={question.question_id}>
          <div className='text-primary' style={{color: 'white', fontSize: '22px'}}>{questionIndex+1}. {question.question_text} {question.question_points == 1 ? text.selectOne : text.selectMultiple}</div>
          <h3 style={{textAlign: 'right'}}className='text-light'>{questionIntResults[questionIndex]}/{question.question_points} {text.points}</h3>
          {quizPictures[`_${question.question_id}`] != undefined && 
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
              <img src={quizPictures[`_${question.question_id}`]} style={{height: '600px', width: '800px'}} />
          </div>}
            
            {     
                  question.question_choices.map((choiceData, choiceIndex) => (
                        <li key={choiceIndex}>
                            <input 
                                type='checkbox'
                                value={false}
                                style={{ display: 'none' , color:'white', border: '5px solid #AAAAAA'}}
                                // Check if there is a true or false value in the result array for the specific choice
                                checked={true}
                                name='options'
                                id={`choiceData${choiceIndex}-option`}/> 
                              
                            <label style={{ color: choiceData.correct_answer === result[questionIndex][choiceIndex] ? 'white' : '#FF0000'}} className='text-primary' htmlFor={`choiceData${choiceIndex}-option`}>
                                {choiceData.choice}
                            </label> {/*choiceData.correct_answer === result[questionIndex][choiceIndex] ? '#00FF00' : '#FF0000'*/}
                            <div style={{border: '5px solid #cecece !', color: '5px solid #cecece !', backround: '5px solid #cecece !'}} className={`check ${result[questionIndex][choiceIndex] ? 'checked' : ''}`}></div>
                        </li>
                    ))}
            </ul>
            </h3>
            ))}
            
        </ul>
      </div>
    </div>
  );
}
