export default {
        
    "categories":[
        {
            "category_id": 1,
            "category_name": "Allgemeiner Brandschutz und Schutzziele",
            "subcategories":[
                {
                "subcategory_id": 1.1,
                "subcategory_name": "Schutzziele"
                },
                {
                "subcategory_id": 1.2,
                "subcategory_name": "Brandverhalten von Baustoffen und -teilen"
                },
                {
                "subcategory_id": 1.3,
                "subcategory_name": "Baurecht (Sonderbauverordnungen) / Anschaltbedingungen Feuerwehr"
                },
                {
                "subcategory_id": 1.4,
                "subcategory_name": "Brandschutzkonzept / Kompetenz"
                }
              ]
        },
        {
            "category_id": 2,
            "category_name": "Allgemeine Gerätetechnik",
            "subcategories":[
                {
                "subcategory_id": 2.1,
                "subcategory_name": "Brandmeldeanlagen (allgemeine Kenntnisse)"
                },
                {
                "subcategory_id": 2.2,
                "subcategory_name": "Brandmelderzentrale"
                },
                {
                "subcategory_id": 2.3,
                "subcategory_name": "Energieversorgung"
                },
                {
                "subcategory_id": 2.4,
                "subcategory_name": "Anzeige- und Bediengeräte"
                },
                {
                "subcategory_id": 2.5,
                "subcategory_name": "Feuerwehrschlüsseldepot"
                },
                {
                "subcategory_id": 2.6,
                "subcategory_name": "Schnittstellen (ohne Löschtechnik)"
                },
                {
                "subcategory_id": 2.7,
                "subcategory_name": "Arten und Typen von Brandmeldern (Funktionsprinzip und Bauart)"
                }
              ]
        },
        {
            "category_id": 3,
            "category_name": "Brandmeldespezifische Elektrotechnik",
            "subcategories":[
                {
                "subcategory_id": 3.1,
                "subcategory_name": "Elektromagnetische Verträglichkeit (EMV)"
                },
                {
                "subcategory_id": 3.2,
                "subcategory_name": "Überspannungsschutz"
                },
                {
                "subcategory_id": 3.3,
                "subcategory_name": "Leitungsberechnung"
                }
            ]
        },
        {
            "category_id": 4,
            "category_name": "Planung und Projektierung",
            "subcategories":[
                {
                "subcategory_id": 4.1,
                "subcategory_name": "Grundsätze"
                },
                {
                "subcategory_id": 4.2,
                "subcategory_name": "Planung und Projektierung"
                },
                {
                "subcategory_id": 4.3,
                "subcategory_name": "Alarmierung"
                },
                {
                "subcategory_id": 4.4,
                "subcategory_name": "Leitungsverlegung, Funktionserhalt"
                }
            ]
        },
        {
            "category_id": 5,
            "category_name": "Montage, Inbetriebsetzung, Instandhaltung",
            "subcategories":[
                {
                "subcategory_id": 5.1,
                "subcategory_name": "Inbetriebsetzung / Ausführungsunterlagen"
                },
                {
                "subcategory_id": 5.2,
                "subcategory_name": "Laufkarten"
                },
                {
                "subcategory_id": 5.3,
                "subcategory_name": "Abnahmeprüfung"
                },
                {
                "subcategory_id": 5.4,
                "subcategory_name": "Funktionsprüfung"
                },
                {
                "subcategory_id": 5.5,
                "subcategory_name": "Instandhaltung"
                },
                {
                "subcategory_id": 5.6,
                "subcategory_name": "Begehung"
                }
            ]
        },
        {
            "category_id": 6,
            "category_name": "Ansteuerung anderer Anlagen",
            "subcategories":[
                {
                "subcategory_id": 6.1,
                "subcategory_name": "Löschanlagensteuerung"
                },
                {
                "subcategory_id": 6.2,
                "subcategory_name": "Ansteuerungmi Brandfal"
                }
            ]
        }
 
    ],
    "questions":[
        {
        "subcategory_id":1.1,
        "question_id":572,
        "question_block_id": 1,
        "question_points":4,
        "question_text":"Mit wem sind die Mindestanforderungen an Schutzziele, Schutzumfang und die Alarmorganisation abzusprechen ?",
        "question_choices":
        [{
            "choice": "mit dem Auftraggeber /Nutzer",
            "correct_answer": true
        },
        {
            "choice": "mit der Alarmempfangsstelle (AES)",
            "correct_answer": false
        },
        {
            "choice": "mit der Bauaufsichtsbehörde bzw. Prüfsachverständiger / Prüfingenieur für Brandschutz",
            "correct_answer": true
        },
        {
            "choice": "mit der ständig besetzten Stelle",
            "correct_answer": false
        }]
    },
    {
        "subcategory_id":1.1,
        "question_id":573,
        "question_block_id": 1,
        "question_points":4,
        "question_text":"mit wem sind die Mindestanforderungen an Schutzziele, Schutzumfang und die Alarmorganisation abzusprechen?",
        "question_choices":
        [{
            "choice": "mit der Brandschutzdienststelle",
            "correct_answer": true
        },
        {
            "choice": "mit der Bauaufsichtsbehörde bzw. dem Prüfsachverständigen / Prüfingenieur für Brandschutz",
            "correct_answer": true
        },
        {
            "choice": "mit dem Ordnungsamt",
            "correct_answer": false
        },
        {
            "choice": "mit der für die Montage und Inbetriebsetzung zuständigen Fachfirma",
            "correct_answer": false
        }]
    },
    {
        "subcategory_id":1.1,
        "question_id":574,
        "question_block_id": 2,
        "question_points":4,
        "question_text":"Welche Schutzziele sind ni der jeweiligen Landesbauordnung hinsichtlich des Brandschutzes eindeutig definiert?",
        "question_choices":
        [{
            "choice": "Vorbeugung der Entstehung von Bränden sowie der Ausbreitung von Feuer und Rauch",
            "correct_answer": true
        },
        {
            "choice": "Ermöglichung der Retung von Menschen und Tieren",
            "correct_answer": true
        },
        {
            "choice": "Ermöglichung wirksamer Löscharbeiten",
            "correct_answer": true
        },
        {
            "choice": "Aufrechterhaltung des Betriebes während eines Brandes",
            "correct_answer": false
        }]
    },
    {
        "subcategory_id":1.1,
        "question_id":575,
        "question_block_id": 2,
        "question_points":4,
        "question_text":"Welche Schutzziele sind ni der jeweiligen Landesbauordnung hinsichtlich des Brandschutzes eindeutig definiert?",
        "question_choices":
        [{
            "choice": "Ermöglichung der Rettung von Menschen und Tieren",
            "correct_answer": true
        },
        {
            "choice": "Ermöglichung wirksamer Löscharbeiten",
            "correct_answer": true
        },
        {
            "choice": "Reduzierung des Risikos für den Gebäudeversicherer und damit Senkung des Versicherungsbeitrags",
            "correct_answer": false
        },
        {
            "choice": "Vorbeugung der Entstehung von Bränden sowie der Ausbreitung von Feuer und Rauch",
            "correct_answer": true
        }]
    },
    {
        "question_id":3,
        "question_block_id": 3,
        "subcategory_id":1.1,
        "question_points":4,
        "question_text":"Welche Schutzziele können mit einer Brandmeldeanlage nach DIN 14675-1 unter anderem erreicht werden?",
        "question_choices":
        [{
            "choice": "Entdeckung von Bränden ni der Entstehungsphase",
            "correct_answer": true
        },
        {
            "choice": "Automatische Ansteuerung von Brandschutz- und Betriebseinrichtungen, sofernvorhanden",
            "correct_answer": true
        },
        {
            "choice": "Schnelle Information und Alarmierung der betroffenen Menschen",
            "correct_answer": true
        },
        {
            "choice": "Schnelle Alarmierung der Feuerwehr und/oder anderer hilfeleistender Stellen",
            "correct_answer": true
        }]
    },
    {
        
        "question_id":4,
        "question_block_id": 4,
        "subcategory_id":1.1,
        "question_points":4,
        "question_text":"Der Schutzumfang wird ni 4 Kategorien nach DIN 14675-1 eingeteilt. Welche Aussagen sind für die Kategorie 2(Teilschutz) richtig?",
        "question_choices":
        [{
            "choice": "Es muss immer mindestens ein Geschoss eines Brandabschnittes oder ein notwendiger Treppenraum überwacht werden.",
            "correct_answer": true
        },
        {
            "choice": "Es werden nur einzelne, mi Brandschutzkonzept benannte, Räume überwacht.",
            "correct_answer": false
        },
        {
            "choice": "Bei Teilschutzanlagen werden nur Einbauten, z. B. Serverschränke, überwacht.",
            "correct_answer": false
        },
        {
            "choice": "Bei Teilschutzanlagen werden die besonders gefahrdeten Beraiche eines Gebaudes überwacht",
            "correct_answer": true
        }]
    }, {
        
        "question_id":5,
        "question_block_id": 5,
        "subcategory_id":1.1,
        "question_points":1,
        "question_text":"Wie ist der Überwachungsumfang gemäß DNI VDE 0833-2 bei Personengefährdung zu gestalten?",
        "question_choices":
        [{
            "choice": "Alle Rettungswege, die von gebäudefremden Personen oder Personen, die auf fremde Hilfe angewiesen sind, benutzt werden, sind ni die Überwachung einzubeziehen",
            "correct_answer": false
        },
        {
            "choice": "Ale Gebäudeteile, ni denen sich gebäudefremde Personen oder Personen, die auf fremde Hilfe angewiesen sind, dauernd oder zeitweise aufhalten, sind flächendeckend ni die Uberwachung einzubeziehen",
            "correct_answer": false
        },
        {
            "choice": "Ale Räume mit Brandlasten größer 25 MJ, bezogen auf eine Fläche von 25m, sind ni die Überwachung einzubeziehen",
            "correct_answer": false
        },
        {
            "choice": "Ale Räume, ni denen sich gebäudefremde Personen oder Personen, die auf fremde Hilfe angewiesen sind, dauernd oder zeitweise aufhalten, sowie angrenzende Räume sind ni die Überwachung einzubeziehen",
            "correct_answer": true
        }]
    },{
        
        "question_id": 6 ,
        "question_block_id": 6 ,
        "subcategory_id":1.1,
        "question_points": 1 ,
        "question_text":"Welche Ausnahme von der Überwachung ist gem. DIN VDE 0833-2 bei Sachgefährdung zulässig?",
        "question_choices":
        [{
            "choice": "Keine Überwachung ni Teilbereichen, die nur eine geringe Brandlast aufweisen, bzw. ni denen keine Brandausbreitung möglich ist, sofern diese Teilbereiche mindestens feuerbeständig abgetrennt ist.",
            "correct_answer": true
        },
        {
            "choice": "Keine Überwachung ni Bereichen bis 400 m7, die nur eine geringe Brandlast aufweisen bzw. keine Brandausbreitung ermöglichen. Eine Größenbeschränkung der Bereiche ist nicht gefordert.",
            "correct_answer": false
        },
        {
            "choice": "Keine Überwachung ni Bereichen, ni denen der Versicherungswert unter 1.000 Euro pro Quadratmeter liegt.",
            "correct_answer": false
        },
        {
            "choice": "Keine Uberwachung von begehbaren Kabelschächten.",
            "correct_answer": false
        }]
    },{
        
        "question_id": 7 ,
        "question_block_id": 7 ,
        "subcategory_id": 1.1 ,
        "question_points": 4 ,
        "question_text": "Welche Festlegungen sind mi Brandmelde- und Alarmierungskonzept unter anderem zu treffen?",
        "question_choices":
        [{
            "choice": "Schutzziele, die mit der BMA erreicht werden sollen",
            "correct_answer": true
        },
        {
            "choice": "Sicherungsbereiche und Überwachungsumfang",
            "correct_answer": true
        },
        {
            "choice": "Meldebereiche",
            "correct_answer": false
        },
        {
            "choice": "Alarmierungsbereiche",
            "correct_answer": true
        }]
    },{
        
         "question_id": 8 ,
         "question_block_id": 7 ,
         "subcategory_id": 1.1 ,
         "question_points": 4 ,
         "question_text": "Welche Festlegungen sind mi Brandmelde- und Alarmierungskonzept unter anderem zu treffen?",
         "question_choices":
         [{
             "choice": "Art und Anordnung der Melder",
             "correct_answer": false
         },
         {
             "choice": "Standort der BMZ",
             "correct_answer": true
         },
         {
             "choice": "Alarmorganisation des Betreibers",
             "correct_answer": true
         },
         {
             "choice": "Alarmierung der Feuerwehr",
             "correct_answer": true
         }]
     },{
         
         "question_id": 802 ,
         "question_block_id": 407 ,
         "subcategory_id": 1.1 ,
         "question_points": 4 ,
         "question_text": "Welche Aussagen zum Brandschutzkonzept sowie zum Brandmelde- und Alarmierungskonzept sind richtig?",
         "question_choices":
         [{
             "choice": "Das Brandschutzkonzept ist identisch mit dem Brandmelde- und Alarmierungskonzept.",
             "correct_answer": false
         },
         {
             "choice": "I Brandschutzkonzept sind Maßnahmen des vorbeugenden (baulich, anlagentechnisch, organisatorisch) und abwehrenden Brandschutzes gesamthaft dargestellt.",
             "correct_answer": true
         },
         {
             "choice": "I Brandmelde- und Alarmierungskonzept können neben den bauordnungsrechtlichen Anforderungen an die BMA weitere Anforderungen definiert werden (z.B. aus Versicherungsauflagen oder feuerwehrspezifischen Bestimmungen).",
             "correct_answer": true
         },
         {
             "choice": "Das Brandmelde- und Alarmierungskonzept wird ni der Regel durch den Ersteller des Brandschutzkonzeptes erstellt.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 804 ,
         "question_block_id": 407 ,
         "subcategory_id": 1.1 ,
         "question_points": 4 ,
         "question_text": "Welche Bereiche müssen als Grundlage für die Planung der Brandmeldeanlage mi Brandmelde- und Alarmierungskonzept beschrieben werden?",
         "question_choices":
         [{
             "choice": "Bereiche mit Teppichböden, da sich hier Brände ni der Regel schneller ausbreiten können.",
             "correct_answer": false
         },
         {
             "choice": "Gefährliche und explosionsgefährdete Bereiche",
             "correct_answer": true
         },
         {
             "choice": "Bereich mit besonderer EMV-Exposition",
             "correct_answer": true
         },
         {
             "choice": "Bereiche, ni denen der zweite Retungsweg nicht über Geräte der Feuerwehr sichergestellt werden kann",
             "correct_answer": false
         }]
     },{
         
         "question_id": 576 ,
         "question_block_id": 8 ,
         "subcategory_id": 1.2 ,
         "question_points": 4 ,
         "question_text": "Welche Feuerwiderstandsklasse erfülen die bauaufsichtlichen Anforderungen an feuerbeständige Bauteile?",
         "question_choices":
         [{
             "choice": "REI 90 [nb]",
             "correct_answer": true
         },
         {
             "choice": "F 90-A",
             "correct_answer": true
         },
         {
             "choice": "T 60 RS",
             "correct_answer": false
         },
         {
             "choice": "S 30",
             "correct_answer": false
         }]
     },{
         
         "question_id": 577 ,
         "question_block_id": 8 ,
         "subcategory_id": 1.2 ,
         "question_points": 4 ,
         "question_text": "Welche Feuerwiderstandsklasse erfüllen die bauaufsichtlichen Anforderungen an feuerbeständige Bauteile?",
         "question_choices":
         [{
             "choice": "EI 90",
             "correct_answer": true
         },
         {
             "choice": "EI 60-M",
             "correct_answer": false
         },
         {
             "choice": "REI 30",
             "correct_answer": false
         },
         {
             "choice": "F 90-A",
             "correct_answer": true
         }]
     },{
         
         "question_id": 578 ,
         "question_block_id": 9 ,
         "subcategory_id": 1.2 ,
         "question_points": 4 ,
         "question_text": "Welche Feuerwiderstandsklasse erfüllen die bauaufsichtlichen Anforderungen an feuerhemmende Bauteile?",
         "question_choices":
         [{
             "choice": "EI 90",
             "correct_answer": false
         },
         {
             "choice": "EI 60-M",
             "correct_answer": false
         },
         {
             "choice": "REI 30",
             "correct_answer": true
         },
         {
             "choice": "F 90-A",
             "correct_answer": false
         }]
     },{
         
         "question_id": 579 ,
         "question_block_id": 9 ,
         "subcategory_id": 1.2 ,
         "question_points": 4 ,
         "question_text": "Welche Feuerwiderstandsklasse erfüllen die bauaufsichtlichen Anforderungen an feuerbeständige Bauteile?",
         "question_choices":
         [{
             "choice": "EI 90",
             "correct_answer": true
         },
         {
             "choice": "EI 60",
             "correct_answer": false
         },
         {
             "choice": "REI 30",
             "correct_answer": false
         },
         {
             "choice": "F 90-A",
             "correct_answer": true
         }]
     },{
         
         "question_id": 11 ,
         "question_block_id": 10 ,
         "subcategory_id": 1.3 ,
         "question_points": 4 ,
         "question_text": "Welche baurechtlichen Vorschriften müssen bei der Planung, Errichtung und dem Betrieb von Brandmeldeanlagen beachtet werden?",
         "question_choices":
         [{
             "choice": "Die Bauordnung des jeweiligen Bundeslandes, einschließlich der anwendbaren Verordnungen für Sonderbauten",
             "correct_answer": true
         },
         {
             "choice": "Die Baugenehmigung der Bauaufsichtsbehörden für das jeweilige Projekt",
             "correct_answer": true
         },
         {
             "choice": "Die bauaufsichtlich eingeführten technischen Baubestimmungen",
             "correct_answer": true
         },
         {
             "choice": "Die Richtlinien der VdS-Schadenverhütung",
             "correct_answer": false
         }]
     },{
         
         "question_id": 16 ,
         "question_block_id": 15 ,
         "subcategory_id": 1.3 ,
         "question_points": 4 ,
         "question_text": "Was beinhalten die Anschlussbedingungen der örtlichen Feuerwehr?",
         "question_choices":
         [{
             "choice": "Regelungen für den Löscheinsatz mi Brandfall.",
             "correct_answer": false
         },
         {
             "choice": "Die Anschlussbedingungen ergänzen und konkretisieren die normativen Regelwerke mi organisatorischen Bereich",
             "correct_answer": true
         },
         {
             "choice": "Verfahren bei der wiederkehrenden Prüfung von FAT, FBF, ÜE und FSD",
             "correct_answer": true
         },
         {
             "choice": "Sie dienen zur Forderung von Brandmeldeanlagen, wenn diese mi Brandschutzkonzept vergessen wurden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 17 ,
         "question_block_id": 16 ,
         "subcategory_id": 1.3 ,
         "question_points": 1 ,
         "question_text": "Gelten die Anschaltbedingungen für Brandmeldeanlagen zur Feuerwehr bundesweit?",
         "question_choices":
         [{
             "choice": "Ja, grundsätzlich gelten ale Anforderungen ni jedem Bundesland gleich.",
             "correct_answer": false
         },
         {
             "choice": "Nein, grundsätzlich sind ni jedem Bundesland andere Anforderungen zu beachten.",
             "correct_answer": false
         },
         {
             "choice": "Nein, die Anforderungen können regional und kommunal abweichen.",
             "correct_answer": true
         },
         {
             "choice": "Ja, da die Anforderungen innerhalb der Unfallverhütungsvorschrift (UVV) bundesweit geregelt sind.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 19 ,
         "question_block_id": 18 ,
         "subcategory_id": 1.3 ,
         "question_points": 4 ,
         "question_text": "Wie kann die DIN 14675-1 bauordnungsrechtlich verbindlichen Charakter erhalten?",
         "question_choices":
         [{
             "choice": "Mit Erscheinen beim DIN.",
             "correct_answer": false
         },
         {
             "choice": "Durch Aufführung in der Baugenehmigung oder im Brandschutzkonzept",
             "correct_answer": true
         },
         {
             "choice": "Durch Erscheinen beim DIN und einer Frist von 1Jahr.",
             "correct_answer": false
         },
         {
             "choice": "Durch Aufnahme ni das Baurecht über die Technischen Baubestimmungen des jeweiligen Bundeslandes",
             "correct_answer": true
         }]
     },{
         
         "question_id": 805 ,
         "question_block_id": 408 ,
         "subcategory_id": 1.3 ,
         "question_points": 4 ,
         "question_text": "Welche der nachfolgenden Gebäude werden im Sinne der Musterbauordnung (MBO) als Sonderbau bezeichnet?",
         "question_choices":
         [{
             "choice": "Gebäude mit einer Höhe von mehr als 22m",
             "correct_answer": true
         },
         {
             "choice": "Einfamilienhäuser",
             "correct_answer": false
         },
         {
             "choice": "Schulen, Hochschulen und ähnliche Einrichtungen",
             "correct_answer": true
         },
         {
             "choice": "Gebäude mit Räumen, die einer Büro- oder Verwaltungsnutzung dienen und einzeln eine Grundfläche von mehr als 400m2  haben",
             "correct_answer": true
         }]
     },{
         
         "question_id": 806 ,
         "question_block_id": 409 ,
         "subcategory_id": 1.3 ,
         "question_points": 4 ,
         "question_text": "Bei welchen Gebäuden muss unter anderem gem. Musterbauordnung der Brandschutznachweis bauaufsichtlich oder durch einen Prüfsachverständigen bescheinigt sein?",
         "question_choices":
         [{
             "choice": "Bei Gebäuden der Gebäudeklassen 1-4",
             "correct_answer": false
         },
         {
             "choice": "Bei Gebäuden der Gebäudeklassen 5",
             "correct_answer": true
         },
         {
             "choice": "Bei Gebäuden, die gem. §2 MBO als Sonderbauten eingestuft werden",
             "correct_answer": true
         },
         {
             "choice": "Für alle Gebäude",
             "correct_answer": false
         }]
     },{
         
         "question_id": 807 ,
         "question_block_id": 409 ,
         "subcategory_id": 1.3 ,
         "question_points": 4 ,
         "question_text": "In welchem Dokument sind in der Regel die bauordnungsrechtlichen Anforderungen an eine Brandmeldeanlage beschrieben?",
         "question_choices":
         [{
             "choice": "DIN 14675-1",
             "correct_answer": false
         },
         {
             "choice": "Verwaltungsvorschrift Technische Baubestimmungen des jeweiligen Bundeslandes (VV TB)",
             "correct_answer": true
         },
         {
             "choice": "VDE 0833-2",
             "correct_answer": false
         },
         {
             "choice": "Richtigstelungsgesetz",
             "correct_answer": false
         }]
     },{
         
         "question_id": 808 ,
         "question_block_id": 409 ,
         "subcategory_id": 1.3 ,
         "question_points": 4 ,
         "question_text": "In welchem Dokument sind ni der Regel die bauordnungsrechtlichen Anforderungen an Alarmierungseinrichtungen und Alarmierungsanlagen beschrieben?",
         "question_choices":
         [{
             "choice": "DIN 14675-1",
             "correct_answer": false
         },
         {
             "choice": "Verwaltungsvorschrift Technische Baubestimmungen des jeweiligen Bundeslandes (VV TB)",
             "correct_answer": true
         },
         {
             "choice": "VDE 0833-4",
             "correct_answer": false
         },
         {
             "choice": "Richtigstellungsgesetz",
             "correct_answer": false
         }]
     },{
         
         "question_id": 12 ,
         "question_block_id": 11 ,
         "subcategory_id": 1.4 ,
         "question_points": 1 ,
         "question_text": "Wie sollte jedes Geschoss eines Brandabschnittes innerhalb eines Teilschutzkonzeptes (Kategorie 2) nach DIN 14675-1 überwacht werden?",
         "question_choices":
         [{
             "choice": "Einrichtungsschutz ist ausreichend ",
             "correct_answer": false
         },
         {
             "choice": "Wie bei Vollschutz",
             "correct_answer": true
         },
         {
             "choice": "Nur die Teppenräume sind zu überwachen",
             "correct_answer": false
         },
         {
             "choice": "Nur die mi Brandschutzkonzept festgelegten, einzelnen Räume müssen überwacht werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 13 ,
         "question_block_id": 12 ,
         "subcategory_id": 1.4 ,
         "question_points": 1 ,
         "question_text": "Wer ist grundsätzlich für die Erstellung des Brandmelde- und Alarmierungskonzeptes verantwortlich?",
         "question_choices":
         [{
             "choice": "Der Fachplaner der BMA",
             "correct_answer": false
         },
         {
             "choice": "Der Ersteller des Brandschutzkonzeptes",
             "correct_answer": false
         },
         {
             "choice": "Die Brandschutzdienststelle",
             "correct_answer": false
         },
         {
             "choice": "Der Auftraggeber der BMA",
             "correct_answer": true
         }]
     },{
         
         "question_id": 14 ,
         "question_block_id": 13 ,
         "subcategory_id": 1.4 ,
         "question_points": 4 ,
         "question_text": "Welche Festlegungen müssen innerhalb der Konzeptphase einer Brandmeldeanlage getroffen werden?",
         "question_choices":
         [{
             "choice": "Überwachungsumfang",
             "correct_answer": true
         },
         {
             "choice": "Art und Umfang der Alarmierung",
             "correct_answer": true
         },
         {
             "choice": "Es reicht aus, nur die Anforderungen aus dem Brandschutzkonzept zu übernehmen",
             "correct_answer": false
         },
         {
             "choice": "Das zum Einsatz kommende Fabrikat",
             "correct_answer": false
         }]
     },{
         
         "question_id": 15 ,
         "question_block_id": 14 ,
         "subcategory_id": 1.4 ,
         "question_points": 4 ,
         "question_text": "Welche wesentlichen Maßnahmen sind mi Rahmen der Alarmorganisation festzulegen?",
         "question_choices":
         [{
             "choice": "Art der Alarmierung der Feuerwehr und der an diese durchzugebenden Informationen",
             "correct_answer": true
         },
         {
             "choice": "die Art und Weise, wie die Personen, die sich mi Gebäude befinden, über den Brandfall informiert werden",
             "correct_answer": true
         },
         {
             "choice": "gewaltfreie Zugangsmöglichkeiten für die Feuerwehr einschließlich Bereithaltung von Schlüsseln",
             "correct_answer": true
         },
         {
             "choice": "Anforderungen für Ab,- Ausschaltungen und die Verantwortlichkeiten für Wiederinbetriebnahme",
             "correct_answer": true
         }]
     },{
         
         "question_id": 18 ,
         "question_block_id": 17 ,
         "subcategory_id": 1.4 ,
         "question_points": 4 ,
         "question_text": "Welche wesentlichen Eigenschaften nach DIN 14675 muss eine Fachfirma beim Bau und Betrieb von einer Brandmeldeanlage haben bzw. nachweisen?",
         "question_choices":
         [{
             "choice": "Fachkompetenz, die eine akkreditierte Stelle zertifiziert hat.",
             "correct_answer": true
         },
         {
             "choice": "Sie muss mindestens seit 3 Jahren Brandmeldeanlagen installieren.",
             "correct_answer": false
         },
         {
             "choice": "Sie muss mindestens seit 5 Jahren Brandmeldeanlagen installieren.",
             "correct_answer": false
         },
         {
             "choice": "Sie muss ein geeignetes Qualitätsmanagementsystem nachweisen (z.B. nach ISO 9001).",
             "correct_answer": true
         }]
     },{
         
         "question_id": 580 ,
         "question_block_id": 19 ,
         "subcategory_id": 1.4 ,
         "question_points": 4 ,
         "question_text": "Bei welcher/welchen Phase(n) für den Aufbau und Betrieb einer BMA ist eine Fachfirma erforderlich?",
         "question_choices":
         [{
             "choice": "Inbetriebsetzung",
             "correct_answer": true
         },
         {
             "choice": "Instandhaltung",
             "correct_answer": true
         },
         {
             "choice": "Brandmelde- und Alarmierungskonzept",
             "correct_answer": false
         },
         {
             "choice": "Montage umd Installation",
             "correct_answer": true
         }]
     },{
         
         "question_id": 581 ,
         "question_block_id": 20 ,
         "subcategory_id": 1.4 ,
         "question_points": 1 ,
         "question_text": "Bei welcher/welchen Phase(n) für den Aufbau und Betrieb einer BMA ist eine Fachfirma erforderlich?",
         "question_choices":
         [{
             "choice": "Betrieb",
             "correct_answer": false
         },
         {
             "choice": "Brandmelde- und Alarmierungskonzept",
             "correct_answer": false
         },
         {
             "choice": "Projektierung",
             "correct_answer": true
         },
         {
             "choice": "Erkundungsphase",
             "correct_answer": false
         }]
     },{
         
         "question_id": 582 ,
         "question_block_id": 20 ,
         "subcategory_id": 1.4 ,
         "question_points": 1 ,
         "question_text": "Bei welchen Phasen für den Aufbau und Betrieb der Brandmeldeanlage müssen die betreffenden Fachfirmen die Kompetenz nachweisen?",
         "question_choices":
         [{
             "choice": "Brandmelde- und Alarmierungskonzept",
             "correct_answer": false
         },
         {
             "choice": "Erprobungsphase",
             "correct_answer": false
         },
         {
             "choice": "Betrieb",
             "correct_answer": false
         },
         {
             "choice": "Inbetriebsetzung",
             "correct_answer": true
         }]
     },{
         
         "question_id": 583 ,
         "question_block_id": 20 ,
         "subcategory_id": 1.4 ,
         "question_points": 4 ,
         "question_text": "Bei welchen Phasen für den Aufbau und Betrieb der Brandmeldeanlage müssen die betreffenden Fachfirmen die Kompetenz nachweisen?",
         "question_choices":
         [{
             "choice": "Betrieb",
             "correct_answer": false
         },
         {
             "choice": "Montage und Installation",
             "correct_answer": true
         },
         {
             "choice": "Brandmelde- und Alarmierungskonzept",
             "correct_answer": false
         },
         {
             "choice": "Abnahme",
             "correct_answer": true
         }]
     },{
         
         "question_id": 744 ,
         "question_block_id": 369 ,
         "subcategory_id": 1.4 ,
         "question_points": 4 ,
         "question_text": "Welche Festlegungen zu Betrieb und Instandhaltung sind Bestandteil des Brandmelde- und Alarmierungskonzeptes?",
         "question_choices":
         [{
             "choice": "Festlegung des Intervalls der regelmäßig durchzuführenden Instandhaltungsmaßnahmen.",
             "correct_answer": true
         },
         {
             "choice": "Sicherstellung der Alarmweiterleitung bei Abschaltung der Übertragungseinrichtung mi Zuge von Instandhaltungsmaßnahmen",
             "correct_answer": true
         },
         {
             "choice": "Durch den Betreiber zu ergreifende Maßnahmen bei Störungen der BMA zur Erreichung der geforderten Schutzziele.",
             "correct_answer": true
         },
         {
             "choice": "Intervall zur regelmäßigen Prüfung der funktionalen Kette der Brandfallsteuerungen.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 809 ,
         "question_block_id": 410 ,
         "subcategory_id": 1.4 ,
         "question_points": 4 ,
         "question_text": "Welche Kriterien werden bei der Überwachung von Fachfirmen für Brandmeldeanlagen nach DIN 14675-2 überprüft?",
         "question_choices":
         [{
             "choice": "Nachweis der Fachkenntnis der verantwortlichen Person (z.B. Auffrischungsschulung)",
             "correct_answer": true
         },
         {
             "choice": "Lieferzusage des/der Systemlieferanten",
             "correct_answer": true
         },
         {
             "choice": "Falschalarmquote der mi Zertifizierungszeitraum installierten Brandmelder",
             "correct_answer": false
         },
         {
             "choice": "Nachweis eines Ersatzteilkonzeptes",
             "correct_answer": true
         }]
     },{
         
         "question_id": 810 ,
         "question_block_id": 411 ,
         "subcategory_id": 1.4 ,
         "question_points": 4 ,
         "question_text": "In welchem Verhältnis stehen die DNI 14675-2 und die europäische Dienstleistungsnorm DIN EN 16763 zueinander?",
         "question_choices":
         [{
             "choice": "Die beiden Normen stehen ni keinem Zusammenhang",
             "correct_answer": false
         },
         {
             "choice": "die DIN 14675-2 ist die nationale Konkretisierung der DIN EN 16763 bezogen auf Brandmelde- und Sprachalarmierungsanlagen",
             "correct_answer": true
         },
         {
             "choice": "Die DIN EN 16763 ersetzt die DIN 14675-2",
             "correct_answer": false
         },
         {
             "choice": "Fachfirmen können sich ohne Berücksichtigung der DIN 14675-2 ausschließlich nach DIN EN 16763 zertifizieren lassen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 811 ,
         "question_block_id": 411 ,
         "subcategory_id": 1.4 ,
         "question_points": 4 ,
         "question_text": "In welchem Verhältnis stehen die DIN 14675-2 und die europäische Dienstleistungsnorm DIN EN 16763 zueinander?",
         "question_choices":
         [{
             "choice": "Die DIN EN 16763 gilt nicht nur für Brandmeldeanlagen, sondern auch für andere sicherheitstechnische Anlagen",
             "correct_answer": true
         },
         {
             "choice": "Die DIN 14675-2 ist die nationale Konkretisierung der DIN EN 16763 bezogen auf Brandmelde- und Sprachalarmierungsanlagen",
             "correct_answer": true
         },
         {
             "choice": "Die DIN EN 16763 ersetzt die DIN 14675-2",
             "correct_answer": false
         },
         {
             "choice": "Fachfirmen können sich ohne Berücksichtigung der DIN 14675-2 ausschließlich nach DIN EN 16763 zertifizieren lassen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 23 ,
         "question_block_id": 21 ,
         "subcategory_id": 2.1 ,
         "question_points": 4 ,
         "question_text": "Welche Anforderungen gelten für die Systembestandteile einer Brandmeldeanlage?",
         "question_choices":
         [{
             "choice": "die Systembestandteile müssen der DIN 14675-1 entsprechen.",
             "correct_answer": false
         },
         {
             "choice": "Die Systembestandteile müssen der DIN VDE 0833 entsprechen.",
             "correct_answer": false
         },
         {
             "choice": "Die Systembestandteile müssen einer der folgenden Normen entsprechen: Norm der Normenreihe DIN EN 54, DIN 14661 oder DIN 14662",
             "correct_answer": true
         },
         {
             "choice": "Die Systembestandteile müssen von zertifizierten Händlern vertrieben werden.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 742 ,
         "question_block_id": 367 ,
         "subcategory_id": 2.1 ,
         "question_points": 1 ,
         "question_text": "Welche Person ist beim Betreiber für den Betrieb der BMA zuständig?",
         "question_choices":
         [{
             "choice": "Der Firmeneigentümer",
             "correct_answer": false
         },
         {
             "choice": "Der Geschäftsführer",
             "correct_answer": false
         },
         {
             "choice": "Der Sicherheitsbeauftragte",
             "correct_answer": false
         },
         {
             "choice": "die eingewiesenen Personen",
             "correct_answer": true
         }]
     },{
         
         "question_id": 793 ,
         "question_block_id": 404 ,
         "subcategory_id": 2.1 ,
         "question_points": 4 ,
         "question_text": "Welche Aussagen stimmen zur Betriebsart PM?",
         "question_choices":
         [{
             "choice": "Die Ansteuerung von Brandschutzeinrichtungen darf verzögert werden.",
             "correct_answer": false
         },
         {
             "choice": "Das Einschalten der Verzögerung der Weiterleitung darf nur automatisch erfolgen.",
             "correct_answer": false
         },
         {
             "choice": "Die Verzögerung darf nur während der Zeit der Anwesenheit von eingewiesenen Personen wirksam sein.",
             "correct_answer": true
         },
         {
             "choice": "Die maximale Verzögerungszeit darf 3 min betragen.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 796 ,
         "question_block_id": 405 ,
         "subcategory_id": 2.1 ,
         "question_points": 4 ,
         "question_text": "Was ist sicherzustellen, wenn Geräte, die an einen überwachten Übertragungsweg angeschaltet sind über zusätzliche Leitungen mit Energie versorgt werden?",
         "question_choices":
         [{
             "choice": "Es darf nicht mehr als eine Funktionsgruppe zur Alarmierung ni einem Alarmierungsbereich ausfallen.",
             "correct_answer": true
         },
         {
             "choice": "Es dürfen nicht mehr als die automatischen Melder eines Meldebereiches ausfallen.",
             "correct_answer": true
         },
         {
             "choice": "Es darf nicht mehr als eine Funktionsgruppe zur Steuerung von 8 Löschbereichen ausfallen.",
             "correct_answer": false
         },
         {
             "choice": "Es dürfen nicht mehr als die linienförmigen Melder mit Meldungsabhängigkeit Typ Bni einem Brandabschnitt ausfallen.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 24 ,
         "question_block_id": 22 ,
         "subcategory_id": 2.2 ,
         "question_points": 1 ,
         "question_text": "Wie muss der Brandmeldezustand an der BMZ angezeigt werden?",
         "question_choices":
         [{
             "choice": "Durch ein optisches Signal als Sammelmeldung",
             "correct_answer": false
         },
         {
             "choice": "Durch ein akustisches Signal als Sammelmeldung",
             "correct_answer": false
         },
         {
             "choice": "Durch ein optisches Signal als Sammelmeldung, ein akustisches Signal und Anzeige der ausgelösten Meldergruppe",
             "correct_answer": true
         },
         {
             "choice": "Durch ein optisches Signal als Sammelmeldung, ein akustisches Signal, Anzeige der ausgelösten Meldergruppe und des ausgelösten Melders",
             "correct_answer": false
         }]
     },{
         
         "question_id": 34 ,
         "question_block_id": 22 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Welche Meldungen und Zustände sind an der BMZ bzw. an der Erstinformationsstelle anzuzeigen?",
         "question_choices":
         [{
             "choice": "FSD ausgelöst",
             "correct_answer": false
         },
         {
             "choice": "Betriebsbereitschaft",
             "correct_answer": true
         },
         {
             "choice": "Störungszustand",
             "correct_answer": true
         },
         {
             "choice": "Abschaltzustand",
             "correct_answer": true
         }]
     },{
         
         "question_id": 35 ,
         "question_block_id": 22 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Welche Meldungen und Zustände sind an der BMZ anzuzeigen?",
         "question_choices":
         [{
             "choice": "Prüfzustand (z.B. Revision)",
             "correct_answer": true
         },
         {
             "choice": "Brandmeldezustand",
             "correct_answer": true
         },
         {
             "choice": "Kurzschluss auf nicht überwachtem Übertragungsweg",
             "correct_answer": false
         },
         {
             "choice": "Störung der Energieversorgung",
             "correct_answer": true
         }]
     },{
         
         "question_id": 25 ,
         "question_block_id": 23 ,
         "subcategory_id": 2.2 ,
         "question_points": 1 ,
         "question_text": "Darf die Signalisierung von Störungsmeldungen, z. B. der Energieversorgung an einer BMZ, automatisch zurückgestellt werden?",
         "question_choices":
         [{
             "choice": "Nein, die Störungsmeldung darf nicht automatisch zurückgestellt werden.",
             "correct_answer": false
         },
         {
             "choice": "Ja, die Störungsmeldung darf automatisch zurückgestellt werden, wenn das auslösende Ereignis (Störung) nicht mehr vorhanden ist.",
             "correct_answer": true
         },
         {
             "choice": "Ja, die Störungsmeldung darf automatisch zurückgestellt werden, wenn das auslösende Ereignis (Störung) seit mindestens 90 Minuten nicht mehr vorhanden ist.",
             "correct_answer": false
         },
         {
             "choice": "Ja, die Störungsmeldung darf automatisch zurückgestellt werden, wenn die Störung einer ständig besetzten Stelle bekanntgegeben worden ist.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 26 ,
         "question_block_id": 24 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Welche Komponenten müssen unabhängig voneinander an der BMZ ein- und dauerhaft ausgeschaltet werden können?",
         "question_choices":
         [{
             "choice": "Meldergruppen",
             "correct_answer": true
         },
         {
             "choice": "Steuer- und Alarmierungseinrichtungen (z.B. Signalgeber)",
             "correct_answer": true
         },
         {
             "choice": "Übertragungseinrichtung",
             "correct_answer": true
         },
         {
             "choice": "Zentralensummer",
             "correct_answer": false
         }]
     },{
         
         "question_id": 33 ,
         "question_block_id": 24 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Für welche Abschaltmöglichkeiten muss eine BMZ ausgelegt sein?",
         "question_choices":
         [{
             "choice": "Meldergruppen",
             "correct_answer": true
         },
         {
             "choice": "Übertragungseinrichtung",
             "correct_answer": true
         },
         {
             "choice": "Alarmierungseinrichtungen",
             "correct_answer": true
         },
         {
             "choice": "Steuereinrichtungen",
             "correct_answer": true
         }]
     },{
         
         "question_id": 584 ,
         "question_block_id": 25 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Welche Anforderungen sind an den Standort einer BMZ bzw. der Erstinformationsstelle zu stellen?",
         "question_choices":
         [{
             "choice": "Der Aufstellort muss durch die BMA überwacht werden.",
             "correct_answer": true
         },
         {
             "choice": "Die Lichtverhältnisse müssen derart sein, dass die Beschriftungen und optischen Anzeigen der BMZ gesehen und gelesen werden können.",
             "correct_answer": true
         },
         {
             "choice": "Der Raum, ni dem sie untergebracht wird, muss feuerbeständig abgetrennt sein.",
             "correct_answer": false
         },
         {
             "choice": "Es muss mindestens 98 %Luftfeuchtigkeit vorhanden sein.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 585 ,
         "question_block_id": 25 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Welche Anforderungen sind an den Standort einer BMZ bzw. der Erstinformationsstelle zu stellen?",
         "question_choices":
         [{
             "choice": "Der Raum, ni dem sie untergebracht ist, muss außerhalb der Betriebszeiten verschlossen gehalten werden.",
             "correct_answer": false
         },
         {
             "choice": "Die BMZ sowie die Erstinformationsstelle müssen immer ni einem ebenerdigen Raum untergebracht werden.",
             "correct_answer": false
         },
         {
             "choice": "Sie ist vorzugsweise mi Freien an der Außenwand des Gebäudes - ni der Nähe des Haupteinganges - anzubringen.",
             "correct_answer": false
         },
         {
             "choice": "die Lichtverhältnisse müssen derart sein, dass die Beschriftungen und optischen Anzeigen leicht gesehen und gelesen werden können.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 29 ,
         "question_block_id": 26 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Welche Anforderungen sind an die Aufstellung der BMZ nach DIN 14675 zu stellen?",
         "question_choices":
         [{
             "choice": "die Lichtverhältnisse müssen derart sein, dass die Beschriftungen und optischen Anzeigen leicht gesehen und gelesen werden können.",
             "correct_answer": true
         },
         {
             "choice": "Die akustischen Anzeigen der BMZ dürfen nicht durch Hintergrundgeräusche beeinträchtigt werden.",
             "correct_answer": true
         },
         {
             "choice": "Wenn für die BMZ kein eigener Raum vorhanden ist, muss die BMZ immer mi Brandschutzgehäuse installiert werden.",
             "correct_answer": false
         },
         {
             "choice": "Das Risiko der Brandentstehung am Aufstellungsort muss niedrig sein.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 30 ,
         "question_block_id": 26 ,
         "subcategory_id": 2.2 ,
         "question_points": 1 ,
         "question_text": "Stimmen die Anforderungen, die an den Aufstellort der BMZ nach DIN 14675-1 zu stellen sind?",
         "question_choices":
         [{
             "choice": "Aufstellungni einem feuerbeständig abgetrennten Raum",
             "correct_answer": false
         },
         {
             "choice": "Aufstellung ni einem Raum mit geringem Brandentstehungsrisiko",
             "correct_answer": false
         },
         {
             "choice": "Aufstellung ni einem Raum, der von der BMZ überwacht ist.",
             "correct_answer": true
         },
         {
             "choice": "Aufstellung ni einem abgeschlossenen Raum mi EG",
             "correct_answer": false
         }]
     },{
         
         "question_id": 586 ,
         "question_block_id": 26 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Welche Bedingungen gelten für den Aufstellungsort der BMZ nach DIN 14675-1?",
         "question_choices":
         [{
             "choice": "Die akustische Anzeige der BMZ muss um 20dB(A) lauter als die Umgebungsgeräusche sein.",
             "correct_answer": false
         },
         {
             "choice": "Das Risiko der Brandentstehung spielt keine Role.",
             "correct_answer": false
         },
         {
             "choice": "Er muss durch die BMA überwacht sein.",
             "correct_answer": true
         },
         {
             "choice": "Es muss ein doppelter Zugang vorhanden sein.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 587 ,
         "question_block_id": 26 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Welche Bedingungen gelten für den Aufstelungsort der BMZ nach DNI 14675-1?",
         "question_choices":
         [{
             "choice": "Er liegt ni jedem Fal mi Hauptzugang des Gebäudes.",
             "correct_answer": false
         },
         {
             "choice": "Die Lichtverhältnisse müssen derart sein, dass Beschriftungen und optische Anzeigen gesehen und gelesen werden können.",
             "correct_answer": true
         },
         {
             "choice": "Er muss ständig von einer eingewiesenen Person besetzt sein.",
             "correct_answer": false
         },
         {
             "choice": "Er mus durch die BMA überwacht sein.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 31 ,
         "question_block_id": 27 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Welche Störungen werden an der BMZ optisch und/oder akustisch angezeigt?",
         "question_choices":
         [{
             "choice": "Leitungsunterbrechung eines Übertragungsweges einer Meldergruppe",
             "correct_answer": true
         },
         {
             "choice": "Kurzschluss eines Übertragungsweges einer Meldergruppe",
             "correct_answer": true
         },
         {
             "choice": "Unterbrechung des Übertragungswegs zur ÜE",
             "correct_answer": true
         },
         {
             "choice": "Abdecken eines automatischen, punktförmigen Brandmelders",
             "correct_answer": false
         }]
     },{
         
         "question_id": 32 ,
         "question_block_id": 27 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Welche Störungen werden an der BMZ optisch und/oder akustisch angezeigt?",
         "question_choices":
         [{
             "choice": "Netzausfall",
             "correct_answer": true
         },
         {
             "choice": "Batterieausfall",
             "correct_answer": true
         },
         {
             "choice": "Entnehmen der Glasscheibe aus einem Handfeuermelder",
             "correct_answer": false
         },
         {
             "choice": "Kurzschluss zum Feuerwehranzeigetableau (FAT)",
             "correct_answer": true
         }]
     },{
         
         "question_id": 588 ,
         "question_block_id": 27 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "die Brandmelderzentrale hat folgende Aufgaben:",
         "question_choices":
         [{
             "choice": "Die angeschlossenen Übertragungswege zu Meldergruppen auf Kurzschluss zu überwachen",
             "correct_answer": true
         },
         {
             "choice": "Die angeschlossenen Übertragungswege zu Meldergruppen auf Drahtbruch zu überwachen",
             "correct_answer": true
         },
         {
             "choice": "die angeschlossenen Übertragungswege zu Meldergruppen auf Sabotage zu überwachen",
             "correct_answer": false
         },
         {
             "choice": "Die eigene Energieversorgung zu überwachen",
             "correct_answer": true
         }]
     },{
         
         "question_id": 589 ,
         "question_block_id": 28 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Die Brandmelderzentrale hat folgende Aufgaben:",
         "question_choices":
         [{
             "choice": "Sprinklerventile auf Funktionsbereitschaft zu überwachen",
             "correct_answer": false
         },
         {
             "choice": "Übertragungseinrichtungen anzusteuern",
             "correct_answer": true
         },
         {
             "choice": "Brandschutzeinrichtungen anzusteuern",
             "correct_answer": true
         },
         {
             "choice": "Den Meldungsort anzuzeigen",
             "correct_answer": true
         }]
     },{
         
         "question_id": 590 ,
         "question_block_id": 28 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "die Brandmelderzentrale hat folgende Aufgaben:",
         "question_choices":
         [{
             "choice": "Brandschutztüren auf Verschluss zu überwachen",
             "correct_answer": false
         },
         {
             "choice": "Das Entstehen von Bränden, .z B. durch automatische Betriebsmittelabschaltung ma Abend, zu verhindern",
             "correct_answer": false
         },
         {
             "choice": "die angeschlossenen Übertragungswege zu Meldergruppen auf Drahtbruch zu überwachen",
             "correct_answer": true
         },
         {
             "choice": "Sprinklerventile auf Funktionsbereitschaft zu überwachen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 751 ,
         "question_block_id": 360 ,
         "subcategory_id": 2.2 ,
         "question_points": 1 ,
         "question_text": "Eine BMA wird aus über- und untergeordneten BMZ ohne eigene Systemvernetzung zusammengeschaltet. Wie muss der Übertragungsweg des Alarmzustandes von der untergeordneten BMZ ausgeführt werden?",
         "question_choices":
         [{
             "choice": "Überwacht durch die Unterzentrale",
             "correct_answer": false
         },
         {
             "choice": "Nur ni separaten Leitungen",
             "correct_answer": false
         },
         {
             "choice": "Nur rückwirkungsfrei",
             "correct_answer": false
         },
         {
             "choice": "Zwei überwachte Übertragungswege ni separaten Leitungen und rückwirkungsfrei",
             "correct_answer": true
         }]
     },{
         
         "question_id": 752 ,
         "question_block_id": 360 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Dürfen bei Modernisierungen durch Ersetzen einer BMZ die vorhandenen Melder an die neue BMZ angeschaltet werden?",
         "question_choices":
         [{
             "choice": "Ja, aber nur wenn sie vom gleichen Hersteller sind",
             "correct_answer": false
         },
         {
             "choice": "Ja, wenn das Zusammenwirken der Melder mit der BMZ durch den Hersteller nach EN 54-13 sichergestellt ist.",
             "correct_answer": true
         },
         {
             "choice": "Ja, aber nur wenn sie ohne zusätzliches Interface aufgeschaltet werden können.",
             "correct_answer": false
         },
         {
             "choice": "Ja, wen sie über ein zugelassenes Interface aufgeschaltet werden, dessen technische und funktionelle Kompatibilität vom Hersteller nachgewiesen wird.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 813 ,
         "question_block_id": 412 ,
         "subcategory_id": 2.2 ,
         "question_points": 4 ,
         "question_text": "Was ist zu beachten, wenn die BMZ ni Gehäusen eingebaut werden?",
         "question_choices":
         [{
             "choice": "Ale verbindlich vorzusehenden Anzeigen müssen ohne vorherige manuelle Einflußnahme (z.B. Öffnen der Tür) ni Zugangsebene 1sichtbar sein.",
             "correct_answer": true
         },
         {
             "choice": "Das Gehäuse muss gem. DIN VDE 0833-2, Anh. Aüberwacht werden",
             "correct_answer": false
         },
         {
             "choice": "Gehäuse für BMZ können grundsätzlich sowohl hängend als auch stehend installiert werden.",
             "correct_answer": false
         },
         {
             "choice": "Bedienelemente ni Zugangsebene 1 müssen uneingeschränkt zugänglich sein.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 38 ,
         "question_block_id": 29 ,
         "subcategory_id": 2.3 ,
         "question_points": 4 ,
         "question_text": "Welche Anforderungen sind an die Energiequellen einer BMA zu stelen?",
         "question_choices":
         [{
             "choice": "Mindestens eine Ersatzenergiequelle muss eine wiederaufladbare Baterie sein.",
             "correct_answer": true
         },
         {
             "choice": "Die Hauptenergiequelle muss vom öffentlichen Stromversorgungsnetz oder einem gleichwertigen Netz betrieben werden können.",
             "correct_answer": true
         },
         {
             "choice": "Die Hauptenergiequelle muss an einem Gleichstromnetz betrieben werden können.",
             "correct_answer": false
         },
         {
             "choice": "Die Ersatzenergiequelle muss am Ausgang einen Wechselrichter aufweisen.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 40 ,
         "question_block_id": 31 ,
         "subcategory_id": 2.3 ,
         "question_points": 1 ,
         "question_text": "Wei mus die Hauptenergiequelle einer BMA ausgelegt sein, damit elal Bestandteile des Brandmeldesystems mit Energie versorgt werden können?",
         "question_choices":
         [{
             "choice": "Es ist von einer vollständig geladenen Batterie auszugehen.",
             "correct_answer": false
         },
         {
             "choice": "Es ist der maximale Strom maßgebend. Dieser ergibt sich aus dem Energiebedarf der meldebereiten BMA bei einer Zustandsänderung (z.B. Alarm).",
             "correct_answer": true
         },
         {
             "choice": "Es ist ein Sicherheitszuschlag (Faktor 1,8) bezogen auf den maximalen Energiebedarf mi meldebereiten Zustand vorzusehen.",
             "correct_answer": false
         },
         {
             "choice": "Es ist ein Sicherheitszuschlag (Faktor 1,4) bezogen auf den maximalen Energiebedarf mi Störungszustand vorzusehen.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 41 ,
         "question_block_id": 32 ,
         "subcategory_id": 2.3 ,
         "question_points": 4 ,
         "question_text": "Welche Anforderungen sind an Batterien einer BMZ zu stellen?",
         "question_choices":
         [{
             "choice": "An die Batterien dürfen keine anlagefremden Verbraucher angeschlossen werden.",
             "correct_answer": true
         },
         {
             "choice": "Die Batterie muss für die Erhaltungsladung geeignet sein.",
             "correct_answer": true
         },
         {
             "choice": "Es dürfen nur neue bzw. ungebrauchte typengleiche Batterien (gleicher Hersteller, gleiche Kapazität und Spannung, gleiches Herstelldatum) parallel oder ni Reihe geschaltet werden.",
             "correct_answer": true
         },
         {
             "choice": "Die Batterie muss mit dem Typ und dem Herstelldatum gekennzeichnet sein.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 42 ,
         "question_block_id": 33 ,
         "subcategory_id": 2.3 ,
         "question_points": 1 ,
         "question_text": "Wann muss spätestens der Ausfall der Hauptenergiequelle nach DNI EN 54-4 erkannt und gemeldet werden?",
         "question_choices":
         [{
             "choice": "Nach 10 Minuten",
             "correct_answer": false
         },
         {
             "choice": "Nach 20 Minuten",
             "correct_answer": false
         },
         {
             "choice": "Nach 30 Minuten",
             "correct_answer": true
         },
         {
             "choice": "Nach 40 Minuten",
             "correct_answer": false
         }]
     },{
         
         "question_id": 43 ,
         "question_block_id": 34 ,
         "subcategory_id": 2.3 ,
         "question_points": 1 ,
         "question_text": "Melder, die an einen überwachten Übertragunsweg angeschlossen sind, werden aus einer zusätzlichen Energieversorgung gespeist. Auf Grund einer Störung der Energieversorgung sind diese Melder nicht mehr funktionsfähig. Welche Überwachungsfläche darf maximal ausfallen?",
         "question_choices":
         [{
             "choice": "1.200m2",
             "correct_answer": false
         },
         {
             "choice": "1.400m2",
             "correct_answer": false
         },
         {
             "choice": "1.600m2",
             "correct_answer": true
         },
         {
             "choice": "1.800m2",
             "correct_answer": false
         }]
     },{
         
         "question_id": 44 ,
         "question_block_id": 35 ,
         "subcategory_id": 2.3 ,
         "question_points": 1 ,
         "question_text": "Für eine Brandmeldeanlage steht eine Netzersatzanlage für einen Zeitraum von 15 Stunden zur Verfügung. Welche Netzausfallüberbrückungszeit muss ni diesem Fal bei der Bemessung der regenerierbaren Energiequelle berücksichtigt werden?",
         "question_choices":
         [{
             "choice": "Man benötigt keine regenerierbare Energiequelle, wenn die Netzersatzanlage unterbrechungsfrei startet.",
             "correct_answer": false
         },
         {
             "choice": "30 Stunden",
             "correct_answer": true
         },
         {
             "choice": "grundsätzlich 4 Stunden",
             "correct_answer": false
         },
         {
             "choice": "grundsätzlich 72 Stunden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 45 ,
         "question_block_id": 36 ,
         "subcategory_id": 2.3 ,
         "question_points": 4 ,
         "question_text": "Welche der nachfolgenden Angaben sind richtig?",
         "question_choices":
         [{
             "choice": "Die Brandmeldeanlage darf mit anderen Verbrauchern an denselben Stromkreis angeschlossen werden.",
             "correct_answer": false
         },
         {
             "choice": "die Netzsicherung muss ni der der Brandmeldeanlage vorgeordneten Verteilung besonders gekennzeichnet sein.",
             "correct_answer": true
         },
         {
             "choice": "nI den Netzstromkreis der BMA darf ein Kippschalter eingebaut werden.",
             "correct_answer": false
         },
         {
             "choice": "Durch Abschalten anderer Betriebsmittel darf der 230 V-Stromkreis zur BMA unterbrochen werden.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 46 ,
         "question_block_id": 37 ,
         "subcategory_id": 2.3 ,
         "question_points": 4 ,
         "question_text": "Welche der nachfolgenden Überbrückungszeiten für die Bemessung der Batterien einer Brandmeldeanlage sind ni Abhängigkeit der gegebenen Rahmenbedingungen normgerecht zulässig?",
         "question_choices":
         [{
             "choice": "72 h",
             "correct_answer": true
         },
         {
             "choice": "30 h",
             "correct_answer": true
         },
         {
             "choice": "4 h",
             "correct_answer": true
         },
         {
             "choice": "60 h",
             "correct_answer": false
         }]
     },{
         
         "question_id": 47 ,
         "question_block_id": 38 ,
         "subcategory_id": 2.3 ,
         "question_points": 1 ,
         "question_text": "Der Ruhestrom einer BMA beträgt 0,6 A. Die Gesamtstromaufnahme mi Alarmzustand beträgt 1,2 A. Die Kapazität der Batterie ist für 4 Stunden zu bemessen. Welche Mindestbatteriekapazität ist erforderlich?",
         "question_choices":
         [{
             "choice": "3,00 Ah",
             "correct_answer": false
         },
         {
             "choice": "3,15 Ah",
             "correct_answer": false
         },
         {
             "choice": "3,60 Ah",
             "correct_answer": false
         },
         {
             "choice": "3,75 Ah",
             "correct_answer": true
         }]
     },{
         
         "question_id": 48 ,
         "question_block_id": 39 ,
         "subcategory_id": 2.3 ,
         "question_points": 1 ,
         "question_text": "Eni Ausfal der Ersatzenergiequelle (Baterie) mus als Störung erkannt werden. wie groß ist die maximal zulässige Verzögerungszeit nach DNI EN 54-4 zwischen dem Ereignis und der Störungsmeldung?",
         "question_choices":
         [{
             "choice": "5 Minuten",
             "correct_answer": false
         },
         {
             "choice": "15 Minuten",
             "correct_answer": true
         },
         {
             "choice": "30 Minuten",
             "correct_answer": false
         },
         {
             "choice": "45 Minuten",
             "correct_answer": false
         }]
     },{
         
         "question_id": 591 ,
         "question_block_id": 41 ,
         "subcategory_id": 2.3 ,
         "question_points": 4 ,
         "question_text": "Welche Verbraucher dürfen an die Energieversorgung der BMA angeschlossen werden?",
         "question_choices":
         [{
             "choice": "Brandmelder, die einem Teil der NE 54 entsprechen.",
             "correct_answer": true
         },
         {
             "choice": "Not-/Sicherheitsbeleuchtung",
             "correct_answer": false
         },
         {
             "choice": "Feststellanlage",
             "correct_answer": false
         },
         {
             "choice": "Alarmierungseinrichtungen, die einem Teil der EN 54 entsprechen",
             "correct_answer": true
         }]
     },{
         
         "question_id": 592 ,
         "question_block_id": 41 ,
         "subcategory_id": 2.3 ,
         "question_points": 4 ,
         "question_text": "Welche Verbraucher dürfen an die Energieversorgung der BMA angeschlossen werden?",
         "question_choices":
         [{
             "choice": "Alarmierungseinrichtungen, die einem Teil der EN 54 entsprechen",
             "correct_answer": true
         },
         {
             "choice": "Heizung für den BMZ-Raum",
             "correct_answer": false
         },
         {
             "choice": "Brandmelder, die einem Teil der EN 54 entsprechen.",
             "correct_answer": true
         },
         {
             "choice": "Not-/Sicherheitsbeleuchtung",
             "correct_answer": false
         }]
     },{
         
         "question_id": 52 ,
         "question_block_id": 42 ,
         "subcategory_id": 2.3 ,
         "question_points": 1 ,
         "question_text": "Welche Faktoren sind bei der Berechnung der Batteriekapazität mindestens zu berücksichtigen?",
         "question_choices":
         [{
             "choice": "Ale Alarmierungseinrichtungen sämtlicher Brandabschnitte",
             "correct_answer": false
         },
         {
             "choice": "Die Alarmierungseinrichtungen des größten Brandabschnittes",
             "correct_answer": false
         },
         {
             "choice": "Zur Ermitlung des erforderlichen Energiebedarfs ist der Melder auszulösen, der die größte Energiebedarfsänderung der BMZ hervorruft.",
             "correct_answer": true
         },
         {
             "choice": "Mindestens eine Alarmierungseinrichtung je Brandabschnitt",
             "correct_answer": false
         }]
     },{
         
         "question_id": 53 ,
         "question_block_id": 43 ,
         "subcategory_id": 2.3 ,
         "question_points": 1 ,
         "question_text": "Wei lange müssen nach der geforderten Überbrückungszeit der Baterie die Alarmierungseinrichtungen der BMA mindestens noch betrieben werden können?",
         "question_choices":
         [{
             "choice": "15 min",
             "correct_answer": false
         },
         {
             "choice": "0,4 h",
             "correct_answer": false
         },
         {
             "choice": "0,5 h",
             "correct_answer": true
         },
         {
             "choice": "1,5 h",
             "correct_answer": false
         }]
     },{
         
         "question_id": 593 ,
         "question_block_id": 44 ,
         "subcategory_id": 2.3 ,
         "question_points": 4 ,
         "question_text": "Bewerten Sie die Richtigkeit der folgenden Aussagen zur Energieversorgung:",
         "question_choices":
         [{
             "choice": "Für die Energieversorgung sind drei Energiequelen erforderlich.",
             "correct_answer": false
         },
         {
             "choice": "Eine Energiequelle muss ein allgemeines Versorgungsnetz oder ein gleichwertiges Netz sein",
             "correct_answer": true
         },
         {
             "choice": "Eine Energiequelle muss ein Energiespeicher (z.B. wiederaufladbare Batterie) sein.",
             "correct_answer": true
         },
         {
             "choice": "Drei typgleiche Batterien dürfen parallel an eine Ladeeinrichtung angeschaltet werden, wenn die Einzelkapazitäten mindestens 36 Ah betragen.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 594 ,
         "question_block_id": 44 ,
         "subcategory_id": 2.3 ,
         "question_points": 4 ,
         "question_text": "Bewerten Sie die Richtigkeit der folgenden Aussagen zur Energieversorgung:",
         "question_choices":
         [{
             "choice": "Der Ausfal einer Energiequelle muss an der BMZ angezeigt werden.",
             "correct_answer": true
         },
         {
             "choice": "Der Anschluss einer BMZ an die Energieversorgung darf nur mit einer besonders gekennzeichneten Steckverbindung erfolgen.",
             "correct_answer": false
         },
         {
             "choice": "Die BMZ muss über eine separate Sicherung ans Netz angeschlossen werden.",
             "correct_answer": true
         },
         {
             "choice": "Bei RCD-Schutzschaltung ist für die BMZ ein eigener RCD-Schutz-Schalter vorzusehen.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 595 ,
         "question_block_id": 44 ,
         "subcategory_id": 2.3 ,
         "question_points": 4 ,
         "question_text": "Bewerten Sie die Richtigkeit der folgenden Aussagen zur Energieversorgung:",
         "question_choices":
         [{
             "choice": "Betriebsmittel, die der Weiterleitung von Meldungen sowie der Alarmierung dienen, dürfen rückwirkungsfrei mitversorgt werden.",
             "correct_answer": true
         },
         {
             "choice": "An den Stromkreis einer BMZ dürfen maximal 8 Steckdosen außerhalb der Zentrale angeschlossen werden",
             "correct_answer": false
         },
         {
             "choice": "Aus Sicherheitsgründen darf die Netzsicherung der BMZ nicht als solche gekennzeichnet sein",
             "correct_answer": false
         },
         {
             "choice": "Batterien gleichen Typs mit unterschiedlichem Alter dürfen parallel geschaltet werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 596 ,
         "question_block_id": 44 ,
         "subcategory_id": 2.3 ,
         "question_points": 4 ,
         "question_text": "Bewerten Sie die Richtigkeit der folgenden Aussagen zur Energieversorgung:",
         "question_choices":
         [{
             "choice": "Sonstige, an die Sicherung der BMA angeschlossene Verbraucher - z.B. Notbeleuchtung - sind zulässig, wenn diese besonders gekennzeichnet sind.",
             "correct_answer": false
         },
         {
             "choice": "Batterien unterschiedlichen Typs dürfen nur zusammen geschaltet werden, wenn die Kapazitäten gleich sind.",
             "correct_answer": false
         },
         {
             "choice": "Die BMZ muss über eine separate Sicherung ans Netz angeschlossen werden.",
             "correct_answer": true
         },
         {
             "choice": "Die Umschaltung von 230 V-Netzbetrieb auf Batterie muss unterbrechungsfrei erfolgen.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 597 ,
         "question_block_id": 44 ,
         "subcategory_id": 2.3 ,
         "question_points": 4 ,
         "question_text": "Bewerten Sie die Richtigkeit der folgenden Aussagen zur Energieversorgung:",
         "question_choices":
         [{
             "choice": "Um eine Gesamtkapazität von 11,4 Ah zu erhalten, müssen zwei 5,7 Ah-Batterien ni Reihe geschaltet werden.",
             "correct_answer": false
         },
         {
             "choice": "Eine außerhalb der BMZ liegende Steckdose darf nicht an den Stromkreis der BMZ angeschlossen werden, auch wenn es sich um eine Service-Steckdose handelt.",
             "correct_answer": true
         },
         {
             "choice": "Sind mehrere Batterien parallel geschaltet, so ist immer jeweils eine Batterie in regelmäßigen Abständen auszutauschen, um den Gesamtkapazitätsverlust durch Alterung möglichst niedrig zu halten.",
             "correct_answer": false
         },
         {
             "choice": "Eine Energiequelle der BMZ muss eine wiederaufladbare Batterie sein.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 814 ,
         "question_block_id": 413 ,
         "subcategory_id": 2.3 ,
         "question_points": 1 ,
         "question_text": "Gem. DIN VDE 0100-560 müssen Brandmeldeanlagen von einem separaten Stromkreis aus der Gebäudehauptverteilung versorgt werden. Kann von dieser Vorgabe abgewichen werden?",
         "question_choices":
         [{
             "choice": "Nein, da von Normen prinzipiell nicht abgewichen werden darf.",
             "correct_answer": false
         },
         {
             "choice": "Ja, aber die Abweichung muss von der Bauaufsicht freigegeben werden.",
             "correct_answer": false
         },
         {
             "choice": "Nein, da die Normen der Normenreihe VDE 0100 wichtiger sind als DIN 14675-1 und VDE 0833.",
             "correct_answer": false
         },
         {
             "choice": "Ja, da die Brandmeldeanlage auch bei Ausfall der Hauptenergiequelle funktionsfähig bleibt. Die Abweichung zu VDE 0100-560 sollte jedoch dokumentiert werden",
             "correct_answer": true
         }]
     },{
         
         "question_id": 816 ,
         "question_block_id": 413 ,
         "subcategory_id": 2.3 ,
         "question_points": 1 ,
         "question_text": "Gem. DIN VDE 0100-560 müssen Brandmeldeanlagen von einem separaten Stromkreis aus der Gebäudehauptverteilung versorgt werden. Kann von dieser Vorgabe abgewichen werden?",
         "question_choices":
         [{
             "choice": "Nein, da die DIN VDE 0100-560 durch die Arbeitsstättenverordnung eingeführt ist.",
             "correct_answer": false
         },
         {
             "choice": "Ja, aber die Abweichung muss von der Bauaufsicht freigegeben werden.",
             "correct_answer": false
         },
         {
             "choice": "Ja, denn wenn DIN 14675-1 gilt, muss DIN VDE 0100-560 nicht berücksichtigt werden.",
             "correct_answer": false
         },
         {
             "choice": "Ja, da die Brandmeldeanlage auch bei Ausfal der Hauptenergiequelle funktionsfähig bleibt. Die Abweichung zu VDE 0100-560 sollte jedoch dokumentiert werden.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 55 ,
         "question_block_id": 45 ,
         "subcategory_id": 2.4 ,
         "question_points": 1 ,
         "question_text": "Wenn die Brandmelderzentrale redundant ausgelegte Signalverarbeitungs- und Anzeigeeinrichtungen aufweist, sind Erleichterungen möglich. Wie groß darf der Sicherungsbereich werden?",
         "question_choices":
         [{
             "choice": "Größer als 48.000 m2",
             "correct_answer": true
         },
         {
             "choice": "Kleiner als 48.000 m2",
             "correct_answer": false
         },
         {
             "choice": "Größer als 24.000 m2 und maximal 48.000 m2",
             "correct_answer": false
         },
         {
             "choice": "Kleiner als 24.000 m2",
             "correct_answer": false
         }]
     },{
         
         "question_id": 56 ,
         "question_block_id": 46 ,
         "subcategory_id": 2.4 ,
         "question_points": 1 ,
         "question_text": "Eine Störung der Anzeigeeinheit einer BMZ darf sich nur auf einen definierten Bereich auswirken. Wie groß darf der Melderbereich und die Melderanzahl sein?",
         "question_choices":
         [{
             "choice": "Eine Gesamtfläche von höchstens 12.000 m2, jedoch mit nicht mehr als 512 Meldern",
             "correct_answer": true
         },
         {
             "choice": "Eine Gesamtfläche von höchstens 12.000 m2, jedoch mit nicht mehr als 536 Meldern",
             "correct_answer": false
         },
         {
             "choice": "Eine Gesamtfläche von höchstens 10.000 m2, jedoch mit nicht mehr als 512 Meldern",
             "correct_answer": false
         },
         {
             "choice": "Eine Gesamtfläche von höchstens 48.000 m2, jedoch mit nicht mehr als 1.024 Meldern",
             "correct_answer": false
         }]
     },{
         
         "question_id": 57 ,
         "question_block_id": 47 ,
         "subcategory_id": 2.4 ,
         "question_points": 4 ,
         "question_text": "Eine Störung der Anzeigeeinheit einer BMZ darf sich nur auf eine Gesamtfläche von 12.000 m2 auswirken. Mit welcher Maßnahme ist das Überschreiten dieser Gesamtfläche möglich?",
         "question_choices":
         [{
             "choice": "Es müssen zwei Registriereinrichtungen mi Parallelbetrieb vorhanden sein.",
             "correct_answer": false
         },
         {
             "choice": "Es muss eine Registriereinrichtung mi Parallelbetrieb vorhanden sein.",
             "correct_answer": true
         },
         {
             "choice": "Es muss eine weitere Anzeigeeinrichtung (einschl. Decodiereinrichtung) als passive Reserve vorhanden sein.",
             "correct_answer": true
         },
         {
             "choice": "Es müssen zwei weitere Anzeigeeinrichtungen (einschl. Decodiereinrichtung) als passive Reserve vorhanden sein.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 600 ,
         "question_block_id": 48 ,
         "subcategory_id": 2.4 ,
         "question_points": 4 ,
         "question_text": "Welches Signal gehört zu den Ein- und/oder Ausgängen der Schnittstelle für ein Feuerwehrbedienfield nach DIN 14661?",
         "question_choices":
         [{
             "choice": "BMZ rückstellen",
             "correct_answer": true
         },
         {
             "choice": " ÜE ab",
             "correct_answer": true
         },
         {
             "choice": "Löschanlage ab",
             "correct_answer": false
         },
         {
             "choice": "ÜE ausgelöst",
             "correct_answer": true
         }]
     },{
         
         "question_id": 601 ,
         "question_block_id": 48 ,
         "subcategory_id": 2.4 ,
         "question_points": 4 ,
         "question_text": "Welches Signal gehört zu den Ein- und/oder Ausgängen der Schnittstelle für ein Feuerwehrbedienfeld nach DIN 14661?",
         "question_choices":
         [{
             "choice": "Akustische Signale ab",
             "correct_answer": true
         },
         {
             "choice": "ÜE ab",
             "correct_answer": true
         },
         {
             "choice": "Löschanlageab ab",
             "correct_answer": false
         },
         {
             "choice": "Störung Meldergruppe",
             "correct_answer": false
         }]
     },{
         
         "question_id": 59 ,
         "question_block_id": 49 ,
         "subcategory_id": 2.4 ,
         "question_points": 1 ,
         "question_text": "Was ist einzuhalten, wenn an einen überwachten Übertragungsweg angeschlossene Geräte nicht über diesen Weg mit Energie versorgt werden?",
         "question_choices":
         [{
             "choice": "Wird eine zusätzliche Speiseleitung benutzt, muss diese immer ni Funktionserhalt verlegt werden.",
             "correct_answer": false
         },
         {
             "choice": "Eine zusätzliche Speiseleitung ist nicht zulässig.",
             "correct_answer": false
         },
         {
             "choice": "Wird eine zusätzliche Speiseleitung benutzt, muss diese überwacht werden.",
             "correct_answer": true
         },
         {
             "choice": "Wird eine zusätzliche Speiseleitung benutzt, muss diese doppelt verlegt werden.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 60 ,
         "question_block_id": 50 ,
         "subcategory_id": 2.4 ,
         "question_points": 1 ,
         "question_text": "Mit welcher Taste eines Feuerwehr-Bedienfeldes nach DIN 14661 muss die Abschaltung der akustischen Alarmgeber möglich sein?",
         "question_choices":
         [{
             "choice": "Brandfallsteuerung ab",
             "correct_answer": false
         },
         {
             "choice": "ÜE ab",
             "correct_answer": false
         },
         {
             "choice": "Akustik ab",
             "correct_answer": true
         },
         {
             "choice": "ÜE prüfen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 61 ,
         "question_block_id": 51 ,
         "subcategory_id": 2.4 ,
         "question_points": 4 ,
         "question_text": "Wann muss die LED 'Löschanlage ausgelöst' am Feuerwehrbedienfeld nach DNI 14661 angesteuert werden?",
         "question_choices":
         [{
             "choice": "Beim Öffnen der Steuereinrichtung der Sprinkleranlage",
             "correct_answer": false
         },
         {
             "choice": "Bei Auslösung von Gaslöschanlagen",
             "correct_answer": true
         },
         {
             "choice": "Bei Auslösung des Alarmkontaktes von Sprinkleranlagen",
             "correct_answer": true
         },
         {
             "choice": "Beim Ansteuern der Vorsteuerung einer Sprinkleranlage",
             "correct_answer": false
         }]
     },{
         
         "question_id": 63 ,
         "question_block_id": 53 ,
         "subcategory_id": 2.4 ,
         "question_points": 4 ,
         "question_text": "Was muss bei der Montage eines Feuerwehrbedienfeldes beachtet werden?",
         "question_choices":
         [{
             "choice": "Das FBF muss an der Erstinformationsstelle angeordnet sein.",
             "correct_answer": true
         },
         {
             "choice": "Die Montagehöhe beträgt 1,6 Meter (+100mm, -200mm).",
             "correct_answer": true
         },
         {
             "choice": "Der Einbau ni Pulte ist zulässig.",
             "correct_answer": false
         },
         {
             "choice": "Der Weg zum FBF muss gesondert gekennzeichnet sein.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 64 ,
         "question_block_id": 54 ,
         "subcategory_id": 2.4 ,
         "question_points": 1 ,
         "question_text": "Ab welcher Meldergruppenanzahl soll eine BMA mit einer Registriereinrichtung (z.B. ein Protokolldrucker) ausgerüstet sein?",
         "question_choices":
         [{
             "choice": "Mehr als 40",
             "correct_answer": false
         },
         {
             "choice": "Mehr als 50",
             "correct_answer": true
         },
         {
             "choice": "Mehr als 64",
             "correct_answer": false
         },
         {
             "choice": "Mehr als 128",
             "correct_answer": false
         }]
     },{
         
         "question_id": 65 ,
         "question_block_id": 54 ,
         "subcategory_id": 2.4 ,
         "question_points": 1 ,
         "question_text": "Sofern an eine Gefahrenmeldeanlage mehrere Meldergruppen angeschlossen sind, sollen Registrierungseinrichtungen zur Aufzeichnung von Meldungen, Störungen usw. vorhanden sein. Ab wieviel angeschlossener Meldergruppen sollen solche Registriereinrichtungen vorhanden sein?",
         "question_choices":
         [{
             "choice": "Mehr als 25",
             "correct_answer": false
         },
         {
             "choice": "Mehr als 50",
             "correct_answer": true
         },
         {
             "choice": "Mehr als 75",
             "correct_answer": false
         },
         {
             "choice": "Mehr als 100",
             "correct_answer": false
         }]
     },{
         
         "question_id": 67 ,
         "question_block_id": 56 ,
         "subcategory_id": 2.4 ,
         "question_points": 4 ,
         "question_text": "Beurteilen Sie die Richtigkeit der folgenden Aussagen, wenn die Anzeige und Bedieneinrichtung nur an einer von der Zentrale abgesetzten, ständig besetzten Stele untergebracht ist.",
         "question_choices":
         [{
             "choice": "Der Übertragungsweg zur Anzeige- und Bedieneinrichtung muss überwacht werden.",
             "correct_answer": true
         },
         {
             "choice": "Kurzschluss und Unterbrechung des Übertragungsweges müssen an der Anzeige- und Bedieneinrichtung als Störung angezeigt werden.",
             "correct_answer": true
         },
         {
             "choice": "Störung und Alarm müssen an der Anzeige- und Bedieneinrichtung mit einer roten LED angezeigt werden.",
             "correct_answer": false
         },
         {
             "choice": "Kurzschluss und Unterbrechung der Leitungen der Anzeige- und Bedieneinrichtung müssen an der BMZ angezeigt werden.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 604 ,
         "question_block_id": 57 ,
         "subcategory_id": 2.4 ,
         "question_points": 4 ,
         "question_text": "Bewerten Sie die Richtigkeit der folgenden Aussagen zum Feuerwehrbedienfeld.",
         "question_choices":
         [{
             "choice": "Das Feuerwehrbedienfeld muss außerhalb des Raumes angebracht sein, ni dem sich die BMZ befindet.",
             "correct_answer": false
         },
         {
             "choice": "Es sind nur Feuerwehrbedienfelder nach DIN 14661 einzusetzen.",
             "correct_answer": true
         },
         {
             "choice": "Das Feuerwehrbedienfeld muss von der BMZ mit Energie versorgt werden können.",
             "correct_answer": true
         },
         {
             "choice": "Das Feuerwehrbedienfeld darf nur (!) über überwachte Übertragungswege an die BMZ angeschlossen werden.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 605 ,
         "question_block_id": 57 ,
         "subcategory_id": 2.4 ,
         "question_points": 4 ,
         "question_text": "Bewerten Sie die Richtigkeit der folgenden Aussagen zum Feuerwehrbedienfeld.",
         "question_choices":
         [{
             "choice": "Am Feuerwehrbedienfeld kann die Übertragungseinrichtung abgeschaltet werden.",
             "correct_answer": true
         },
         {
             "choice": "Über das Feuerwehrbedienfeld wird die Feuerwehr alarmiert.",
             "correct_answer": false
         },
         {
             "choice": "Über das Feuerwehrbedienfeld kann die Übertragungseinrichtung geprüft werden.",
             "correct_answer": true
         },
         {
             "choice": "Über das Feuerwehrbedienfeld kann das Schlüsseldepot / der Feuerwehrschlüsselkasten geöffnet werden.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 772 ,
         "question_block_id": 385 ,
         "subcategory_id": 2.4 ,
         "question_points": 1 ,
         "question_text": "Bei Störung der Anzeigeeinrichtungen für Meldebereiche mit einer Gesamtfläche > 48.000 m2 müssen Redundanzmaßnahmen vorhanden sein.",
         "question_choices":
         [{
             "choice": "Reicht bei diesen Anlagen ein FAT nach DNI 14662 als Anzeigeredundanz?",
             "correct_answer": false
         }]
     },{
         
         "question_id": 598 ,
         "question_block_id": 58 ,
         "subcategory_id": 2.5 ,
         "question_points": 4 ,
         "question_text": "Beurteilen Sie die Richtigkeit der folgenden Aussagen zu Feuerwehrschlüsseldepots (FSD):",
         "question_choices":
         [{
             "choice": "FSD dienen der Aufbewahrung von Gebäudeschlüsseln",
             "correct_answer": true
         },
         {
             "choice": "die Außentür des FSD lässt sich nur von der Feuerwehr entriegeln",
             "correct_answer": false
         },
         {
             "choice": "Das FSD ist möglichst direkt neben der BMZ zu montieren",
             "correct_answer": false
         },
         {
             "choice": "Der Adapter des FSD mus sichmi FSD befinden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 599 ,
         "question_block_id": 58 ,
         "subcategory_id": 2.5 ,
         "question_points": 4 ,
         "question_text": "Beurteilen Sie die Richtigkeit der folgenden Aussagen zu Feuerwehrschlüsseldepots (FSD):",
         "question_choices":
         [{
             "choice": "Der Adapter des FSD darf sich ni der Zentrale befinden",
             "correct_answer": true
         },
         {
             "choice": "die Wand, ni der ein FSD eingebaut werden sol, muss mindestens so dcik wie das FSD sein",
             "correct_answer": false
         },
         {
             "choice": "Die Außentür des FSD lässt sich nur von der Feuerwehr entriegeln",
             "correct_answer": false
         },
         {
             "choice": "FSD 1 muss mit einer Heizung ausgerüstet sein",
             "correct_answer": false
         }]
     },{
         
         "question_id": 602 ,
         "question_block_id": 59 ,
         "subcategory_id": 2.5 ,
         "question_points": 4 ,
         "question_text": "Durch welche Kriterien darf ein Feuerwehrschlüsseldepot FSD2 oder FSD3 elektrisch entriegelt werden?",
         "question_choices":
         [{
             "choice": "Durch eine Abschaltung einer bestimmten Meldergruppe.",
             "correct_answer": false
         },
         {
             "choice": "Durch eine Störungsmeldung eines bestimmten Melders",
             "correct_answer": false
         },
         {
             "choice": "Durch eine Brandmeldung und/oder durch die zugehörige Rückmeldung der Übertragungseinrichtung",
             "correct_answer": true
         },
         {
             "choice": "Durch ein Freischaltelement (FSE)",
             "correct_answer": true
         }]
     },{
         
         "question_id": 603 ,
         "question_block_id": 59 ,
         "subcategory_id": 2.5 ,
         "question_points": 4 ,
         "question_text": "Durch welche Kriterien dar ein Feuerwehrschlüsseldepot FSD2 oder FSD3 elektrisch entriegelt werden?",
         "question_choices":
         [{
             "choice": "Durch eine Sabotagemeldung des Feuerwehrschlüsseldepots.",
             "correct_answer": false
         },
         {
             "choice": "Das Feuerwehrschlüsseldepot darf grundsätzlich nicht elektrisch entriegelt werden.",
             "correct_answer": false
         },
         {
             "choice": "Durch eine Störung der Brandmeldeanlage.",
             "correct_answer": false
         },
         {
             "choice": "Durch eine Brandmeldung und/oder durch die zugehörige Rückmeldung der Übertragungseinrichtung.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 70 ,
         "question_block_id": 60 ,
         "subcategory_id": 2.5 ,
         "question_points": 4 ,
         "question_text": "Welche Schlüssel dürfen mi Feuerwehrschlüsseldepot FSD 2 hinterlegt werden?",
         "question_choices":
         [{
             "choice": "Einzelschlüssel mit Einzelschließungen",
             "correct_answer": true
         },
         {
             "choice": "Generalschlüssel",
             "correct_answer": false
         },
         {
             "choice": "Schlüssel für Schalteinrichtungen",
             "correct_answer": false
         },
         {
             "choice": "Schlüssel für die Privatwohnung des Geschäftsführers",
             "correct_answer": false
         }]
     },{
         
         "question_id": 71 ,
         "question_block_id": 61 ,
         "subcategory_id": 2.5 ,
         "question_points": 1 ,
         "question_text": "Wie viele Schlüssel dürfen ni einem FSD 3 nach DNI 14675 maximal hinterlegt werden?",
         "question_choices":
         [{
             "choice": "1",
             "correct_answer": false
         },
         {
             "choice": "2",
             "correct_answer": false
         },
         {
             "choice": "3",
             "correct_answer": true
         },
         {
             "choice": "4",
             "correct_answer": false
         }]
     },{
         
         "question_id": 74 ,
         "question_block_id": 61 ,
         "subcategory_id": 2.5 ,
         "question_points": 1 ,
         "question_text": "Wieviele Schlüssel dürfen aus einsatztaktischen Gründen grundsätzlich ni einem Feuerwehrschlüsseldepot (FSD) 2und 3hinterlegt sein?",
         "question_choices":
         [{
             "choice": "nicht mehr als 2",
             "correct_answer": false
         },
         {
             "choice": "nicht mehr als 3",
             "correct_answer": true
         },
         {
             "choice": "nicht mehr als 4",
             "correct_answer": false
         },
         {
             "choice": "nicht mehr als 5",
             "correct_answer": false
         }]
     },{
         
         "question_id": 72 ,
         "question_block_id": 62 ,
         "subcategory_id": 2.5 ,
         "question_points": 1 ,
         "question_text": "nI wieviele Klassen werden Feuerwehrschlüsseldepots nach DNI 14675 eingeteilt?",
         "question_choices":
         [{
             "choice": "1",
             "correct_answer": false
         },
         {
             "choice": "2",
             "correct_answer": false
         },
         {
             "choice": "3",
             "correct_answer": true
         },
         {
             "choice": "4",
             "correct_answer": false
         }]
     },{
         
         "question_id": 75 ,
         "question_block_id": 62 ,
         "subcategory_id": 2.5 ,
         "question_points": 1 ,
         "question_text": "Wei oft ist ein Feuerwehrschlüsseldepot (FSD) zu inspizieren und wie oft ist es zu warten?",
         "question_choices":
         [{
             "choice": "Ist vierteljährlich zu inspizieren und mus vierteljährlich gewartet werden.",
             "correct_answer": false
         },
         {
             "choice": "Ist halbjährlich zu inspizieren und muss mindestens halbjährlich gewartet werden.",
             "correct_answer": false
         },
         {
             "choice": "Ist vierteljährlich zu inspizieren und mus mindestens einmal jährlich gewartet werden.",
             "correct_answer": true
         },
         {
             "choice": "Ist halbjährlich zu inspizieren und muss mindestens einmal jährlich gewartet werden.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 73 ,
         "question_block_id": 63 ,
         "subcategory_id": 2.5 ,
         "question_points": 4 ,
         "question_text": "Müssen Feuerwehrschlüsseldepots mit einer Heizung ausgerüstet werden? Und wann müssen diese ggf. beheizt werden?",
         "question_choices":
         [{
             "choice": "FSD 2 und FSD 3 müssen mit einer Heizung ausgerüstet sein",
             "correct_answer": true
         },
         {
             "choice": "FSD 1muss mit einer Heizung ausgerüstet sein",
             "correct_answer": false
         },
         {
             "choice": "Die Heizung der FSD muss mi Winter ständig in Betrieb sein",
             "correct_answer": false
         },
         {
             "choice": "Kann ein frostsicherer Einbau nicht sicher gestellt werden muss die Heizung des FSD ständig betriebsbereit sein",
             "correct_answer": true
         }]
     },{
         
         "question_id": 77 ,
         "question_block_id": 64 ,
         "subcategory_id": 2.5 ,
         "question_points": 4 ,
         "question_text": "Welcher Unterschied besteht grundsätzlich zwischen einem Klasse 3 Feuerwehrschlüsseldepot (FSD) und einem Klasse 2FSD?",
         "question_choices":
         [{
             "choice": "Bei einem Klasse 3 FSD muss ein Freischaltelement installiert werden.",
             "correct_answer": false
         },
         {
             "choice": "Ein Klasse 3 FSD darf nur ni Wände, nicht jedoch ni Säulen installiert werden.",
             "correct_answer": false
         },
         {
             "choice": "Bei einem Klasse 3FSD muss die Außentür auf Durchbruch und Öfnen überwacht werden.",
             "correct_answer": true
         },
         {
             "choice": "Nur bei einem Klasse 3 FSD muss der Schlüssel auf Entnahme überwacht werden.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 80 ,
         "question_block_id": 67 ,
         "subcategory_id": 2.5 ,
         "question_points": 1 ,
         "question_text": "Wie weit darf das Feuerwehrschlüsseldepot (FSD) vom Feuerwehrzugang entfernt sein?",
         "question_choices":
         [{
             "choice": "Der Anbringungsort sollte ni unmittelbarer Nähe des Feuerwehrzugangs erfolgen (z.B. ni einem max. Umkreis von etwa 5m). Wichtig ist, dass der Anbringungsort mit der zuständigen Feuerwehr abgestimmt ist.",
             "correct_answer": true
         },
         {
             "choice": "Eine Entfernungsangabe ist nicht sinnvoll, da auf den Anbringungsort durch ein Hinweisschild nach DIN verwiesen wird.",
             "correct_answer": false
         },
         {
             "choice": "Der Anbringungsort mus ni der Nähe des Feuerwehrzuganges installiert werden, z.B. ni einem max. Umkreis von 20 Metern.",
             "correct_answer": false
         },
         {
             "choice": "Der Anbringungsort muss ni unmittelbarer Nähe des Feuerwehrzuganges installiert werden, z.B. ni einem max. Umkreis von 10 Metern.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 81 ,
         "question_block_id": 68 ,
         "subcategory_id": 2.5 ,
         "question_points": 4 ,
         "question_text": "Welche Anbringungsorte sind für ein Feuerwehrschlüsseldepot (FSD) vorzuziehen?",
         "question_choices":
         [{
             "choice": "Auf der Wetterseite von Gebäuden",
             "correct_answer": false
         },
         {
             "choice": "Unter Vordächern",
             "correct_answer": true
         },
         {
             "choice": "In Durchgängen von Gebäuden",
             "correct_answer": true
         },
         {
             "choice": "In Nischen von Gebäuden",
             "correct_answer": true
         }]
     },{
         
         "question_id": 82 ,
         "question_block_id": 69 ,
         "subcategory_id": 2.5 ,
         "question_points": 1 ,
         "question_text": "nI welcher Höhe muss ein Feuerwehrschlüsseldepot (FSD) installiert werden?",
         "question_choices":
         [{
             "choice": "0,6 bis 1,6 m",
             "correct_answer": false
         },
         {
             "choice": "0,5 bis 1,7 m",
             "correct_answer": false
         },
         {
             "choice": "0,7 bis 1,5 m",
             "correct_answer": false
         },
         {
             "choice": "0,8 bis 1,4 m",
             "correct_answer": true
         }]
     },{
         
         "question_id": 83 ,
         "question_block_id": 70 ,
         "subcategory_id": 2.5 ,
         "question_points": 1 ,
         "question_text": "Um wieviel dicker als die Einbautiefe des FSD muss eine Wand sein, damit ein FSD Klasse 2 und 3 eingebaut werden darf?",
         "question_choices":
         [{
             "choice": "mindestens 70 mm",
             "correct_answer": false
         },
         {
             "choice": "mindestens 80 mm",
             "correct_answer": true
         },
         {
             "choice": "mindestens 90 mm",
             "correct_answer": false
         },
         {
             "choice": "mindestens 100 mm",
             "correct_answer": false
         }]
     },{
         
         "question_id": 84 ,
         "question_block_id": 71 ,
         "subcategory_id": 2.5 ,
         "question_points": 4 ,
         "question_text": "Welche Aussagen zum Potenzialausgleich bei Feuerwehrschlüsseldepots (FSD) sind richtig?",
         "question_choices": 
         [{
             "choice": "FSDs brauchen nicht mit einem Potenzialausgleich der BMA verbunden zu werden.",
             "correct_answer": false
         },
         {
             "choice": "Nur FSDs ni blitzgefährdeten Zonen müssen mit einem Potenzialausgleich der BMA verbunden werden.",
             "correct_answer": false
         },
         {
             "choice": "Nur FSDs ni explosionsgefährdeten Zonen müssen mit einem Potenzialausgleich der BMA verbunden werden.",
             "correct_answer": false
         },
         {
             "choice": "FSDs mit elektronischen Einrichtungen sind über eine Leitung mit einem Querschnitt von mindestens 4mm7 mit dem Potenzialausgleich der BMA zu verbinden.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 85 ,
         "question_block_id": 72 ,
         "subcategory_id": 2.7 ,
         "question_points": 4 ,
         "question_text": "Welche Verordnungen müssen beim Umgang, der Lagerung und Verwendung von Meldern mit radioaktiven Präparaten eingehalten werden?",
         "question_choices":
         [{
             "choice": "Die Bundes-Immisionsschutzverordnung (BlmSchV) muss eingehalten werden",
             "correct_answer": false
         },
         {
             "choice": "Die Brandschutzverordnung (BdSchV) muss eingehalten werden",
             "correct_answer": false
         },
         {
             "choice": "Die Arbeitsstättenverordnung (ASR) muss eingehalten werden",
             "correct_answer": false
         },
         {
             "choice": "Die Strahlenschutzverordnung (StrlSchV) muss eingehalten werden",
             "correct_answer": true
         }]
     },{
         
         "question_id": 86 ,
         "question_block_id": 73 ,
         "subcategory_id": 2.7 ,
         "question_points": 1 ,
         "question_text": "Bei welchen Umgebungstemepraturen dürfen automatische Brandmelder betrieben werden?",
         "question_choices":
         [{
             "choice": "von -20 °C bis + 50° C",
             "correct_answer": false
         },
         {
             "choice": "von -30 °C bis + 60° C",
             "correct_answer": false
         },
         {
             "choice": "von 0 °C bis + 50° C",
             "correct_answer": false
         },
         {
             "choice": "die Herstellervorgaben sind zu beachten",
             "correct_answer": true
         }]
     },{
         
         "question_id": 87 ,
         "question_block_id": 74 ,
         "subcategory_id": 2.7 ,
         "question_points": 4 ,
         "question_text": "Welche Melder sind bei betriebsbedingten, stark schwankenden Umgebungstemperaturen weniger geeignet?",
         "question_choices":
         [{
             "choice": "Wärmemelder Klasse A2",
             "correct_answer": true
         },
         {
             "choice": "Rauchmelder",
             "correct_answer": false
         },
         {
             "choice": "Wärmemelder Klasse A1",
             "correct_answer": true
         },
         {
             "choice": "Flammenmelder",
             "correct_answer": false
         }]
     },{
         
         "question_id": 606 ,
         "question_block_id": 75 ,
         "subcategory_id": 2.7 ,
         "question_points": 4 ,
         "question_text": "Welche Brandkenngrößen können punktförmige Brandmelder nach EN 54 auswerten?",
         "question_choices":
         [{
             "choice": "Temperaturerhöhung",
             "correct_answer": true
         },
         {
             "choice": "Rauch",
             "correct_answer": true
         },
         {
             "choice": "Kohlenmonoxid",
             "correct_answer": true
         },
         {
             "choice": "Brandgeruch",
             "correct_answer": false
         }]
     },{
         
         "question_id": 607 ,
         "question_block_id": 75 ,
         "subcategory_id": 2.7 ,
         "question_points": 4 ,
         "question_text": "Welche Brandkenngrößen können automatische Brandmelder nach EN 54 auswerten?",
         "question_choices":
         [{
             "choice": "Anstieg vom CO2",
             "correct_answer": false
         },
         {
             "choice": "Brandgeräusche",
             "correct_answer": false
         },
         {
             "choice": "UV-Strahlung",
             "correct_answer": true
         },
         {
             "choice": "IR-Strahlung",
             "correct_answer": true
         }]
     },{
         
         "question_id": 608 ,
         "question_block_id": 75 ,
         "subcategory_id": 2.7 ,
         "question_points": 4 ,
         "question_text": "Welche Brandkenngrößen können automatische Brandmelder nach EN 54 auswerten?",
         "question_choices":
         [{
             "choice": "Luftfeuchtigkeit",
             "correct_answer": false
         },
         {
             "choice": "Luftdruckänderung der Umgebung",
             "correct_answer": false
         },
         {
             "choice": "Temperaturanstieg",
             "correct_answer": true
         },
         {
             "choice": "Radioaktive Strahlung",
             "correct_answer": false
         }]
     },{
         
         "question_id": 89 ,
         "question_block_id": 76 ,
         "subcategory_id": 2.7 ,
         "question_points": 4 ,
         "question_text": "Durch welche Merkmale unterscheiden sich punktförmige Wärmemelder mit dem Index Roder Svon herkömmlichen punktförmigen Wärmemeldern?",
         "question_choices":
         [{
             "choice": "Melder mit dem Index S sprechen auch bei hohen Temperaturanstiegsgeschwindigkeiten unterhalb der minimalen statischen Ansprechtemperatur nicht an. Sie eignen sich deshalb besonders gut für Anwendungen, ni denen über längere Zeit höhere Temperaturanstiegsgeschwindigkeiten herrschen, z.B. ni Kesselräumen oder Küchen.",
             "correct_answer": true
         },
         {
             "choice": "Melder mit dem Index Rsprechen auch bei hohen Temperaturanstiegsgeschwindigkeiten unterhalb der minimalen statischen Ansprechtemperatur nicht an. Sie eignen sich deshalb besonders gut für den Einsatz ni ungeheizten Gebäuden, ni denen die Umgebungstemperatur sehr stark schwanken kann, hohe Anstiegsgeschwindigkeiten aber nicht lange andauern.",
             "correct_answer": false
         },
         {
             "choice": "Melder mit dem Index S lösen bei hohen Temperaturanstiegsgeschwindigkeiten auch dann aus, wenn der Temperaturanstieg deutlich unterhalb der typischen Anwendungstemperatur beginnt. Sie eignen sich deshalb besonders gut für den Einsatz in ungeheizten Gebäuden, in denen die Umgebungstemperatur sehr stark schwanken kann, hohe Anstiegsgeschwindigkeiten aber nicht lange andauern.",
             "correct_answer": false
         },
         {
             "choice": "Melder mit dem Index Rlösen bei hohen Temperaturanstiegsgeschwindigkeiten auch dann aus, wenn der Temperaturanstieg deutlich unterhalb der typischen Anwendungstemperatur beginnt. Sie eignen sich deshalb besonders gut für Anwendungen, ni denen über längere Zeit höhere Temperaturanstiegsgeschwindigkeiten herrschen, z.B. ni Kesselräumen oder Küchen.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 90 ,
         "question_block_id": 77 ,
         "subcategory_id": 3.1 ,
         "question_points": 4 ,
         "question_text": "Durch EMV-Einflüse können Störungen verursacht werden. Was sind mögliche Störungsquellen?",
         "question_choices":
         [{
             "choice": "Energietrassen",
             "correct_answer": true
         },
         {
             "choice": "Mobilfunkgeräte",
             "correct_answer": true
         },
         {
             "choice": "Datenleitungen",
             "correct_answer": false
         },
         {
             "choice": "Sendereinrichtungen",
             "correct_answer": true
         }]
     },{
         
         "question_id": 91 ,
         "question_block_id": 78 ,
         "subcategory_id": 3.1 ,
         "question_points": 4 ,
         "question_text": "Durch den vermehrten Einsatz von Mikroprozessoren und Bussystemen können EMV- Einflüsse Störungen verursachen. Welche Maßnahmen vermeiden oder mindern EMV- Einflüsse?",
         "question_choices":
         [{
             "choice": "Potenzialausgleich",
             "correct_answer": true
         },
         {
             "choice": "Getrennte Verlegung",
             "correct_answer": true
         },
         {
             "choice": "Leitungsschirmung",
             "correct_answer": true
         },
         {
             "choice": "Entstörfilter",
             "correct_answer": true
         }]
     },{
         
         "question_id": 748 ,
         "question_block_id": 372 ,
         "subcategory_id": 3.1 ,
         "question_points": 4 ,
         "question_text": "Was verursacht induzierte Spannungen ni Leitungsanlagen?",
         "question_choices":
         [{
             "choice": "Blitzeinschläge ni Gebäudenähe",
             "correct_answer": true
         },
         {
             "choice": "Kurzschlüsse mi Niederspannungsnetz",
             "correct_answer": true
         },
         {
             "choice": "Konstante, hohe Gleichströme",
             "correct_answer": false
         },
         {
             "choice": "Abschalten von großen induktiven Lasten",
             "correct_answer": true
         }]
     },{
         
         "question_id": 93 ,
         "question_block_id": 81 ,
         "subcategory_id": 3.2 ,
         "question_points": 4 ,
         "question_text": "Welche Maßnahmen zählen mi weiteren Sinne zu den Maßnahmen gegen schädigende Überspannungen?",
         "question_choices":
         [{
             "choice": "Verlegung kunstoffisolierter Leitungen",
             "correct_answer": false
         },
         {
             "choice": "Anordnung von Überspannungsableitern",
             "correct_answer": true
         },
         {
             "choice": "Potentialausgleich",
             "correct_answer": true
         },
         {
             "choice": "Leitungsschirmung",
             "correct_answer": true
         }]
     },{
         
         "question_id": 797 ,
         "question_block_id": 406 ,
         "subcategory_id": 3.2 ,
         "question_points": 4 ,
         "question_text": "Welche Richtlinien können zur Durchführung von Überspannungsschutzmaßnahmen an Brandmeldeanlagen angewendet werden?",
         "question_choices":
         [{
             "choice": "DIN VDE 0185 Blitzschutz",
             "correct_answer": true
         },
         {
             "choice": "DNI VDE 0845-1 Schutz von Fernmeldeanlagen gegen Blitzeinwirkungen",
             "correct_answer": true
         },
         {
             "choice": "DIN VDE 0833-1",
             "correct_answer": false
         },
         {
             "choice": "DIN VDE 0833-2",
             "correct_answer": true
         }]
     },{
         
         "question_id": 94 ,
         "question_block_id": 364 ,
         "subcategory_id": 3.3 ,
         "question_points": 6 ,
         "question_text": "Über ein 50 m langes Kabel wird ein Signalgeber mit einem Stromverbrauch von 540 mA an eine BMZ mit 21 V Stromversorgung angeschaltet. Der Signalgeber ist für eine Spannung von 10 V bis 15 V ausgelegt. Bestimmen Sie den zulässigen kleinsten Leitungsdurchmesser. Beachten Sie den Arbeitsbereich einer 12 VStromversorgung; Herstellerangabe: Entladeschlussspannung pro Zele: 1,78 V; der Stromverbrauch des Signalgebers beträgt ni diesem Bereich konstant 540 mA; Verwenden Sie als spezifische Leitfähigkeit: x-Kupfer: 56 m/(Q*mm*), bzw. den spezifischen Widerstand p-Kupfer: 0,0178 (2* mm7/m) die Lösung kann nur gewertet werden, wenn der vollständige Rechenweg beigefügt ist.",
         "question_choices":
         [{
             "choice": "0,78 mm",
             "correct_answer": false
         },
         {
             "choice": "0,94 mm",
             "correct_answer": false
         },
         {
             "choice": "1,34 mm",
             "correct_answer": true
         },
         {
             "choice": "1,41 mm",
             "correct_answer": false
         }]
     },{
         
         "question_id": 95 ,
         "question_block_id": 364 ,
         "subcategory_id": 3.3 ,
         "question_points": 8 ,
         "question_text": "Die Zuleitung für eine Fernmeldeanlage ist 50 mlang und besteht aus Kupferdraht von 0,6 mm Durchmesser. Die Leitung wird bei 24 VBatteriespannung von 400 mA durchflossen. Berechnen Sie den Spannungsabfall ni Volt. Verwenden Sie als spezifische Leitfähigkeit: x-Kupfer: 56 m/(2*mm*), bzw. den spezifischen Widerstand p-Kupfer: 0,0178 (Q*mm%/m) Die Lösung kann nur gewertet werden, wenn der vollständige Rechenweg beigefügt ist.",
         "question_choices":
         [{
             "choice": "1,26 V",
             "correct_answer": false
         },
         {
             "choice": "1,51 V",
             "correct_answer": false
         },
         {
             "choice": "2,52",
             "correct_answer": true
         },
         {
             "choice": "10,08 V",
             "correct_answer": false
         }]
     },{
         
         "question_id": 739 ,
         "question_block_id": 364 ,
         "subcategory_id": 3.3 ,
         "question_points": 8 ,
         "question_text": "Punkte: Über ein 50 mlanges Kabel wird ein Signalgeber mit einem Stromverbrauch von 540 mA an eine BMZ mit 21 V Stromversorgung angeschaltet. Der Signalgeber ist für eine Spannung von 10 Vbis 15 Vausgelegt. Wählen Sie das Kabel mit dem kleinsten zulässigen Querschnitt aus. Beachten Sie den Arbeitsbereich einer 12 V Stromversorgung; Herstellerangabe: Entladeschlussspannung pro Zele: 1,78 V; der Stromverbrauch des Signalgebers beträgt ni diesem Bereich konstant 540 mA; Verwenden Sie als spezifische Leitfähigkeit: x-Kupfer: 56 m/(2*mm7), bzw. den spezifischen Widerstand p-Kupfer: 0,0178 (2* mm?/m) die Lösung kann nur gewertet werden, wenn der vollständige Rechenweg beigefügt ist.",
         "question_choices":
         [{
             "choice": "0,75 mm2",
             "correct_answer": false
         },
         {
             "choice": "1,5 mm2",
             "correct_answer": true
         },
         {
             "choice": "2,5 mm2",
             "correct_answer": false
         },
         {
             "choice": "4 mm2",
             "correct_answer": false
         }]
     },{
         
         "question_id": 96 ,
         "question_block_id": 365 ,
         "subcategory_id": 3.3 ,
         "question_points": 6 ,
         "question_text": "Eine Kupferader hat bei einer Länge von 180 meinen Widerstand von 0,8 Ohm. Wie groß ist der Durchmesser? Verwenden Sieals spezifische Leitfähigkeit:x-Kupfer: 56 m/(2*mm7), bzw. den spezifischen Widerstand p-Kupfer: 0,0178 (Q*mm^/m) die Lösung kann nur gewertet werden, wenn der vollständige Rechenweg beigefügt ist.",
         "question_choices":
         [{
             "choice": "1,81 mm",
             "correct_answer": false
         },
         {
             "choice": "2,26 mm",
             "correct_answer": true
         },
         {
             "choice": "3,20 mm",
             "correct_answer": false
         },
         {
             "choice": "5,11 mm",
             "correct_answer": false
         }]
     },{
         
         "question_id": 97 ,
         "question_block_id": 366 ,
         "subcategory_id": 3.3 ,
         "question_points": 3 ,
         "question_text": "Eine Leitung von 2x 15, mm sol durch eine Fernmeldeleitung mit parallel geschalteten Adern von 0,6 m Durchmesser ersetzt werden. wie viele Adern der Fernmeldeleitung sind notwendig und welche Fernmeldeleitung ist zu verwenden?",
         "question_choices":
         [{
             "choice": "2x 2x 0,6 mm",
             "correct_answer": false
         },
         {
             "choice": "4x 2x 0,6 mm",
             "correct_answer": false
         },
         {
             "choice": "6x 2x 0,6 mm",
             "correct_answer": true
         },
         {
             "choice": "8x 2x 0,6 mm",
             "correct_answer": false
         }]
     },{
         
         "question_id": 740 ,
         "question_block_id": 366 ,
         "subcategory_id": 3.3 ,
         "question_points": 2 ,
         "question_text": "Für die Ansteuerung eines Signalgebers ergibt die Berechnung einen Aderdurchmesser von 13,4 m. Wählen Sie den Mindestquerschnit für ein geeignetes Kabel aus.",
         "question_choices":
         [{
             "choice": "0,75 mm2",
             "correct_answer": false
         },
         {
             "choice": "1,5 mm2",
             "correct_answer": true
         },
         {
             "choice": "2,5 mm2",
             "correct_answer": false
         },
         {
             "choice": "4 mm2",
             "correct_answer": false
         }]
     },{
         
         "question_id": 98 ,
         "question_block_id": 82 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Wieviel Melder oder Geräte dürfen maximal an einen Übertragungsweg in Ringbustechnik angeschlossen werden? Indirekt angeschaltete Geräte (z.B. Melderanzeigen) sind nicht uz berücksichtigen.",
         "question_choices":
         [{
             "choice": "10",
             "correct_answer": false
         },
         {
             "choice": "32",
             "correct_answer": false
         },
         {
             "choice": "64",
             "correct_answer": false
         },
         {
             "choice": "Ohne Begrenzung",
             "correct_answer": true
         }]
     },{
         
         "question_id": 127 ,
         "question_block_id": 82 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Beim Einsatz der Ringbustechnik dürfen mehrere Melder/Geräte an einen Übertragungsweg angeschlossen werden. Wieviel Melder/Geräte dürfen max. an einen Übertragungsweg der Ringbustechnik angeschlossen werden?",
         "question_choices":
         [{
             "choice": "32",
             "correct_answer": false
         },
         {
             "choice": "128",
             "correct_answer": false
         },
         {
             "choice": "512",
             "correct_answer": false
         },
         {
             "choice": "Ohne Begrenzung",
             "correct_answer": true
         }]
     },{
         
         "question_id": 126 ,
         "question_block_id": 83 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Beim Einsatz der Ringbustechnik dürfen mehrere Melder/Geräte an einen Übertragungsweg angeschlossen werden. Wie groß darf die maximale überwachte Fläche sein, die einem Übertragungsweg der Ringbustechnik zugeordnet ist?",
         "question_choices":
         [{
             "choice": "400 m2",
             "correct_answer": false
         },
         {
             "choice": "1.600 m2",
             "correct_answer": false
         },
         {
             "choice": "6.000 m2",
             "correct_answer": true
         },
         {
             "choice": "12.000 m2",
             "correct_answer": false
         }]
     },{
         
         "question_id": 671 ,
         "question_block_id": 83 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Welche maximale Fläche darf na einen Übertragungsweg ni Stichleitungstechnik angeschlossenen automatischen Meldern überwacht werden?",
         "question_choices":
         [{
             "choice": "400 m2",
             "correct_answer": false
         },
         {
             "choice": "1.600 m2",
             "correct_answer": true
         },
         {
             "choice": "6.000 m2",
             "correct_answer": false
         },
         {
             "choice": "12.000 m2",
             "correct_answer": false
         }]
     },{
         
         "question_id": 102 ,
         "question_block_id": 86 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Was darf bei einer Störung eines Übertragungsweges maximal ausfallen?",
         "question_choices":
         [{
             "choice": "Keine diesem Übertragungsweg zugeordnete Funktionsgruppe",
             "correct_answer": false
         },
         {
             "choice": "Enie diesem Übertragungsweg zugeordnete Funktionsgruppe",
             "correct_answer": true
         },
         {
             "choice": "Zwei diesem Übertragungsweg zugeordnete Funktionsgruppen",
             "correct_answer": false
         },
         {
             "choice": "Drei diesem Übertragungsweg zugeordnete Funktionsgruppen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 103 ,
         "question_block_id": 87 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Aufgaben können einem Übertragungsweg zugeordnete Funktionsgruppen haben?",
         "question_choices":
         [{
             "choice": "Alarmieren von jeweils einem Alarmierungsbereich",
             "correct_answer": true
         },
         {
             "choice": "Ansteuern von abgesetzten Melderanzeigen (Identanzeige)",
             "correct_answer": false
         },
         {
             "choice": "Melden von Bränden ni der Entstehungsphase",
             "correct_answer": false
         },
         {
             "choice": "Steuern von je einem Löschbereich",
             "correct_answer": true
         }]
     },{
         
         "question_id": 104 ,
         "question_block_id": 88 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Was ist zu beachten, wen Melderni klassifizierten, explosionsgefährdeten Bereichen eingesetzt werden müssen?",
         "question_choices":
         [{
             "choice": "Anschlüsse müssen der Schutzart IP65 entsprechen",
             "correct_answer": false
         },
         {
             "choice": "Es sind entsprechend zugelassene Geräte einzusetzen",
             "correct_answer": true
         },
         {
             "choice": "Geräte müssen nach DNI VDE 0170 gasdicht sein",
             "correct_answer": false
         },
         {
             "choice": "Geräte müssen gasdichte Kabeleinführungen besitzen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 611 ,
         "question_block_id": 88 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Was ist zu beachten, wen Melder ni explosionsgefährdeten Bereichen eingesetzt werden müssen?",
         "question_choices":
         [{
             "choice": "die eingesetzten Melder müssen den ATEX-Richtlinien genügen",
             "correct_answer": true
         },
         {
             "choice": "Anschlüsse müssen der Schutzart IP65 entsprechen",
             "correct_answer": false
         },
         {
             "choice": "Ale Kabel mi Ex-gefährdeten Bereich müssen mindestens 30 m unter Putz verlegt sein",
             "correct_answer": false
         },
         {
             "choice": "Geräte müssen nach DNI VDE 0170 gasdicht sein",
             "correct_answer": false
         }]
     },{
         
         "question_id": 105 ,
         "question_block_id": 89 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Wann ist es zulässig, Installationskanäle und -schächte nicht zu überwachen?",
         "question_choices":
         [{
             "choice": "Wenn diese für Personen nicht zugänglich sind",
             "correct_answer": false
         },
         {
             "choice": "Wenn diese für Personen nicht zugänglich und gegenüber anderen Bereichen feuerbeständig abgeschottet sind",
             "correct_answer": true
         },
         {
             "choice": "Wenn diese gegenüber anderen Bereichen feuerbeständig abgeschottet sind",
             "correct_answer": false
         },
         {
             "choice": "Wenn diese für Personen nicht zugänglich, geschossübergreifend und gegenüber anderen Bereichen feuerbeständig abgeschottet sind",
             "correct_answer": false
         }]
     },{
         
         "question_id": 140 ,
         "question_block_id": 91 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "nI welchen Fälen müssen Zwischendecken und Doppelbodenbereiche überwacht werden?",
         "question_choices":
         [{
             "choice": "Wenn Leitungen für die Notbeleuchtung vorhanden sind",
             "correct_answer": false
         },
         {
             "choice": "Wenn die Brandlast 25 MJ/m7 überschreitet",
             "correct_answer": true
         },
         {
             "choice": "Wenn die Zwischendeckenbereiche mit nicht brennbaren Bauteilen so unterteilt sind, dass Abschnitte ohne horizontale Versprünge von max. 100m7 und einer max. Seitenlänge von 20 mgebildet werden",
             "correct_answer": true
         },
         {
             "choice": "Wenn Klimakanäle vorhanden sind",
             "correct_answer": false
         }]
     },{
         
         "question_id": 624 ,
         "question_block_id": 91 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "nI welchen Fällen müssen Zwischendecken und Doppelbodenbereiche überwacht werden?",
         "question_choices":
         [{
             "choice": "Wenn die Brandlast 25 MJ/m2 überschreitet",
             "correct_answer": true
         },
         {
             "choice": "Die Umfassungsbauteile (Decken, Boden, Wand) brennbar sind",
             "correct_answer": true
         },
         {
             "choice": "Wenn die Zwischendeckenbereiche mit nicht brennbaren Bauteilen so unterteilt sind, dass Abschnitte ohne horizontale Versprünge von max. 100m2 und einer max. Seitenlänge von 20 mgebildet werden",
             "correct_answer": true
         },
         {
             "choice": "Wenn Klimakanäle vorhanden sind",
             "correct_answer": false
         }]
     },{
         
         "question_id": 108 ,
         "question_block_id": 92 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Worauf muss sich die Überwachung mindestens erstrecken?",
         "question_choices":
         [{
             "choice": "Die Überwachung muß sich mindestens auf einen ganzen Brandabschnitt erstrecken.",
             "correct_answer": true
         },
         {
             "choice": "Die Überwachung muß sich mindestens auf ein ganzes Gebäude erstrecken.",
             "correct_answer": false
         },
         {
             "choice": "Die Überwachung muß sich mindestens auf eine Etage eines Gebäudes erstrecken.",
             "correct_answer": false
         },
         {
             "choice": "Die Überwachung muß sich mindestens auf einen feuerbeständig abgeteilten Raum erstrecken.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 109 ,
         "question_block_id": 93 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Wann ist es zulässig Sanitärräume, z. B. Waschräume und Toiletten, nicht zu überwachen?",
         "question_choices":
         [{
             "choice": "Wenn ni diesen Räumen keine brennbaren Vorräte oder Abfälle aufbewahrt werden",
             "correct_answer": true
         },
         {
             "choice": "Brauchen grundsätzlich nicht überwacht zu werden",
             "correct_answer": false
         },
         {
             "choice": "Wenn ni diesen oder benachbarten Räumen keine Personengefahr gegeben ist",
             "correct_answer": false
         },
         {
             "choice": "Wen diese keine Behindertentoilette (Toileten für hilfsbedürftige Personen) sind",
             "correct_answer": false
         }]
     },{
         
         "question_id": 110 ,
         "question_block_id": 94 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Arbeiten dürfen von einem nicht zertifizierten Subunternehmer durchgeführt werden?",
         "question_choices":
         [{
             "choice": "Das Programmieren der Zentrale",
             "correct_answer": false
         },
         {
             "choice": "Das Verlegen von Leitungen",
             "correct_answer": true
         },
         {
             "choice": "Das Montieren von Meldersockeln",
             "correct_answer": true
         },
         {
             "choice": "Das Montieren von Gehäusen",
             "correct_answer": true
         }]
     },{
         
         "question_id": 111 ,
         "question_block_id": 95 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Darf von der Dokumentation (Ausführungsplanung) innerhalb der Montage abgewichen werden, wenn auf andere Weise eine kostengünstigere Lösung erreichbar sein kann.",
         "question_choices":
         [{
             "choice": "Nein, die Dokumentation ist mit den Behörden abgestimmt.",
             "correct_answer": false
         },
         {
             "choice": "Ja, es müssen aber die Beteiligten konsultiert werden.",
             "correct_answer": true
         },
         {
             "choice": "Ja, wenn die Kosteneinsparung mehr als 10% der Auftragssumme ist.",
             "correct_answer": false
         },
         {
             "choice": "Ja, wenn die ausführende Firma nach DIN 14675 zertifiziert ist.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 113 ,
         "question_block_id": 97 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Aussage zu Meldebereichen ist richtig?",
         "question_choices":
         [{
             "choice": "Ein Meldebereich darf sich grundsätzlich nur über ein Geschoss erstrecken; ausgenommen hiervon sind Treppenräume, Licht- und Aufzugsschächte bzw. turmartige Aufbauten, die zu jeweils eigenen Meldebereichen zusammenzufassen sind.",
             "correct_answer": true
         },
         {
             "choice": "Ein Meldebereich darf einen Brandabschnitt nicht überschreiten und nicht größer sein als 1.600 m2.",
             "correct_answer": true
         },
         {
             "choice": "In Meldebereiche dürfen Räume zusammengefasst werden, wenn die Räume benachbart sind, ihre Anzahl nicht größer als fünf und die Gesamtfläche der Räume 400 m2 nicht übersteigt.",
             "correct_answer": true
         },
         {
             "choice": "In Meldebereichen werden immer Gebäudeabschnitte zusammengefasst. Eine Begrenzung gibt es nicht, jedoch sollten die Abschnitte eines Meldebereiches zusammenhängend sein.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 155 ,
         "question_block_id": 97 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Was ist bei einem Meldebereich zu beachten?",
         "question_choices":
         [{
             "choice": "Ein Meldebereich darf 1600 m2 nicht überschreiten.",
             "correct_answer": true
         },
         {
             "choice": "Ein Meldebereich darf einen Brandabschnitt nicht überschreiten.",
             "correct_answer": true
         },
         {
             "choice": "Ein Meldebereich darf sich über zwei Büroetagen erstrecken, wenn die Gesamtfläche 400 m2 nicht übersteigt.",
             "correct_answer": false
         },
         {
             "choice": "Zu einem Meldebereich dürfen bis zu 5 benachbarte Räume zusammengefasst werden, wenn die Gesamtfläche 400 m2 nicht übersteigt.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 114 ,
         "question_block_id": 98 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Aussage zu Meldegruppen ist richtig?",
         "question_choices":
         [{
             "choice": "Es können auch mehr als 5 Räume in Meldergruppen zusammengefasst werden, wenn die Bedingungen nach DIN VDE 0833-2 erfüllt sind.",
             "correct_answer": true
         },
         {
             "choice": "Einer Meldergruppe dürfen nur Melder eines Meldebereichs angehören.",
             "correct_answer": true
         },
         {
             "choice": "Für automatische Brandmelder sind eigene Meldergruppen vorzusehen. Diese dürfen nicht mehr als 32 automatische Brandmelder enthalten.",
             "correct_answer": true
         },
         {
             "choice": "Meldergruppen dürfen, wenn sie einen Melderbereich unterteilen, nur abgeschaltet werden, wenn der gesamte Meldebereich abgeschaltet wird.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 115 ,
         "question_block_id": 99 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Aussage zu Meldegruppen ist richtig?",
         "question_choices":
         [{
             "choice": "Melder ni Lüftungsanlagen müssen zu eigenen Meldergruppen zusammengefaßt werden.",
             "correct_answer": true
         },
         {
             "choice": "Meldergruppen müssen unabhängig voneinander abschaltbar sein.",
             "correct_answer": true
         },
         {
             "choice": "Handfeuermelder ni Treppenräumen mit mehr als zwei Untergeschossen sind jeweils vom Feuerwehrzugang ausgehend sowohl nach unten ni den Untergeschossbereichen als auch nach oben ni den Obergeschossbereichen ni getrennten Meldergruppen zusammenzufassen.",
             "correct_answer": true
         },
         {
             "choice": "nI Doppel- oder Systembodenbereichen, Zwischendecken oder Kabelkanälen angeordnete automatische Brandmelder müssen jeweils zu eigenen Meldergruppen zusammengefasst werden, wenn keine weitere Anzeige vorhanden ist.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 116 ,
         "question_block_id": 100 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Aussage zu Meldebereichen und Meldegruppen ist richtig?",
         "question_choices":
         [{
             "choice": "Meldebereiche sind grundsätzlich ni mehrere Meldergruppen zu unterteilen.",
             "correct_answer": false
         },
         {
             "choice": "Meldergruppen habenmi einfachsten Fal den gleichen Umfang wie Meldebereiche.",
             "correct_answer": true
         },
         {
             "choice": "Für automatische Brandmelder und Handfeuermelder sind eigene Meldergruppen vorzusehen.",
             "correct_answer": true
         },
         {
             "choice": "nI Meldergruppen dürfen automatische und Handfeuermelder zusammengefasst werden.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 118 ,
         "question_block_id": 102 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Wonach hat die Auswahl von automatischen Brandmeldern zu erfolgen?",
         "question_choices":
         [{
             "choice": "Die Auswahl automatischer Brandmelder hat nach den Vorgaben der örtlichen Feuerwehr zu erfolgen.",
             "correct_answer": false
         },
         {
             "choice": "Wenn automatische Brandmelder nach EN 54 geprüft und zertifiziert sind, dann ist das Auswahlkriterium Fehlalarmsicherheit.",
             "correct_answer": false
         },
         {
             "choice": "Die Auswahl der Brandmelder hat entsprechend der Raumnutzung, der wahrscheinlichen Brandentwicklung ni der Entstehungsphase, der Deckengeometrie, den Vorgaben des Betreibers und der zuständigen Behörde ni dem zu überwachenden Bereich zu erfolgen.",
             "correct_answer": false
         },
         {
             "choice": "Die Auswahl automatischer Brandmelder hat entsprechend der Raumnutzung, der wahrscheinlichen Brandentwicklung ni der Entstehungsphase, der Raumhöhe, den Umgebungsbedingungen und den möglichen Störgrößen ni dem zu überwachenden Bereich zu erfolgen.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 119 ,
         "question_block_id": 103 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Bei einem Brand wird mit einer Personengefährdung gerechnet. Welche Meldertypen sind hier vorzuziehen?",
         "question_choices":
         [{
             "choice": "Thermodifferentialmelder",
             "correct_answer": false
         },
         {
             "choice": "Flammenmelder",
             "correct_answer": false
         },
         {
             "choice": "Rauchmelder",
             "correct_answer": true
         },
         {
             "choice": "Mehrkriterienmelder (Rauch und Wärme)",
             "correct_answer": true
         }]
     },{
         
         "question_id": 120 ,
         "question_block_id": 104 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Bei einem Brand wird ni der Entstehungsphase mit einer schnelen Brandentwicklung gerechnet (starke Flammenstrahlung und starke Rauchentwicklung). Welche Meldertypen sind einsetzbar?",
         "question_choices":
         [{
             "choice": "Thermodifferentialmelder",
             "correct_answer": true
         },
         {
             "choice": "IR-Flammenmelder",
             "correct_answer": true
         },
         {
             "choice": "Rauchmelder",
             "correct_answer": true
         },
         {
             "choice": "Mehrkriterienmelder (Rauch und Wärme)",
             "correct_answer": true
         }]
     },{
         
         "question_id": 121 ,
         "question_block_id": 105 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche nationalen Normen sind beim Planen, Projektieren und Aufbau einer BMA einzuhalten?",
         "question_choices":
         [{
             "choice": "DNI VDE 0833 Teil 2",
             "correct_answer": true
         },
         {
             "choice": "DIN VDE 0833 Teil 3",
             "correct_answer": false
         },
         {
             "choice": "DIN 14675",
             "correct_answer": true
         },
         {
             "choice": "MLAR",
             "correct_answer": false
         }]
     },{
         
         "question_id": 120 ,
         "question_block_id": 106 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Bis zu welcher maximalen Raumhöhe kann der punktförmige Rauchmelder ni der Regel als uneingeschränkt geeignet angesehen werden?",
         "question_choices":
         [{
             "choice": "7,5 m",
             "correct_answer": false
         },
         {
             "choice": "9 m",
             "correct_answer": false
         },
         {
             "choice": "12 m",
             "correct_answer": true
         },
         {
             "choice": "16 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 123 ,
         "question_block_id": 107 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Bis zu welcher maximalen Raumhöhe kann der punktförmige Wärmemelder Klasse A2 als uneingeschränkt geeignet angesehen werden?",
         "question_choices":
         [{
             "choice": "4,5 m",
             "correct_answer": false
         },
         {
             "choice": "6,0 m",
             "correct_answer": true
         },
         {
             "choice": "7,5 m",
             "correct_answer": false
         },
         {
             "choice": "12,0 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 124 ,
         "question_block_id": 108 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Bitte ermitteln Sie für eine Rauchmelderanordnung ni einem Raum mit nicht geneigter Decke, für die Sie den Tabellen einen maximal zulässigen Überwachungsbereich von 30 m2 entnommen haben, den maximal zulässigen Abstand von Deckenpunkten (DH) zu Meldern.",
         "question_choices":
         [{
             "choice": "4,0 m",
             "correct_answer": false
         },
         {
             "choice": "4,1 m",
             "correct_answer": true
         },
         {
             "choice": "4,2 m",
             "correct_answer": false
         },
         {
             "choice": "5,0 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 125 ,
         "question_block_id": 109 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "In einem Gang mit einer Breite von 3 m sollen punktförmige Wärmemelder installiert werden. Wie groß darf der maximale Abstand der Melder sueinander sein?",
         "question_choices":
         [{
             "choice": "5 m",
             "correct_answer": false
         },
         {
             "choice": "7,5 m",
             "correct_answer": false
         },
         {
             "choice": "10 m",
             "correct_answer": true
         },
         {
             "choice": "15 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 737 ,
         "question_block_id": 109 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "In einem Gang mit einer Breite von 3 m sollen punktförmige Wärmemelder installiert werden. Wie groß darf der maximale Abstand der Melder zur Stirnseite dieses Ganges sein",
         "question_choices":
         [{
             "choice": "5 m",
             "correct_answer": true
         },
         {
             "choice": "7,5 m",
             "correct_answer": false
         },
         {
             "choice": "10 m",
             "correct_answer": false
         },
         {
             "choice": "15 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 128 ,
         "question_block_id": 110 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Ausnahmen zu einer flächendeckenden Überwachung durch automatische Brandmelder sind zulässig?",
         "question_choices":
         [{
             "choice": "Sanitärräume ohne brennbarem Lagergut",
             "correct_answer": true
         },
         {
             "choice": "Laderampe mi Freien",
             "correct_answer": true
         },
         {
             "choice": "Installationskanäle nicht zugänglich und F90 geschottet",
             "correct_answer": true
         },
         {
             "choice": "Schutzräume, die nicht für andere Zwecke genutzt werden",
             "correct_answer": true
         }]
     },{
         
         "question_id": 129 ,
         "question_block_id": 111 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Bei Zweimeldungsabhängigkeit Typ B werden punktförmige Rauchmelder eingesetzt. Welche Mind",
         "question_choices":
         [{
             "choice": "1,5 m",
             "correct_answer": false
         },
         {
             "choice": "2,5 m",
             "correct_answer": true
         },
         {
             "choice": "3,5 m",
             "correct_answer": false
         },
         {
             "choice": "4,0 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 130 ,
         "question_block_id": 112 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Der uneingeschränkte Betrieb einer BMA ist für 30 Stunden, zuzüglich des erforderlichen Energiebedarfs der Alarmierungseinrichtungen, sichergestellt. Welche der nachstehenden Bedingungen müssen hierbei mindestens erfüllt werden?",
         "question_choices":
         [{
             "choice": "Eine automatische Störungsübertragung muss an eine nicht ständig besetzte Stelle erfolgen.",
             "correct_answer": false
         },
         {
             "choice": "Eine automatische Störungsübertragung muss an eine ständig besetzte Stelle erfolgen.",
             "correct_answer": true
         },
         {
             "choice": "Der Instandhalter muss mit der Beseitigung von Störungen innerhalb von 24 h nach Meldung beginnen.",
             "correct_answer": true
         },
         {
             "choice": "Ein eigener Störungsdienst muss ständig für die Behebung von Störungen verfügbar sein.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 612 ,
         "question_block_id": 112 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Der uneingeschränkte Betrieb einer BMA ist für 30 Stunden, zuzüglich des erforderlichen Energiebedarfs der Alarmierungseinrichtungen, sichergestellt. Welche der nachstehenden Bedingungen müssen hierbei mindestens erfüllt werden?",
         "question_choices":
         [{
             "choice": "Es müssen getrennte Einrichtungen für die Netzstromversorgung der BMZ und der Ladeeinrichtung vorhanden sein",
             "correct_answer": false
         },
         {
             "choice": "Es muss ein Netzersatz für die Versorgung der Ladeeinrichtung unverzüglich aufgeschaltet werden können. Der Netzersatz muss mindestens für die Dauer von 30 Stunden die für den Betrieb der BMA erforderliche Leistung erbringen",
             "correct_answer": false
         },
         {
             "choice": "Der Instandhalter muss mit der Beseitigung von Störungen innerhalb von 24 Stunden nach Meldung beginnen.",
             "correct_answer": true
         },
         {
             "choice": "Eine automatische Störungsübertragung muss an eine ständig besetzte Stele erfolgen",
             "correct_answer": true
         }]
     },{
         
         "question_id": 131 ,
         "question_block_id": 113 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "nI welchem maximalen Abstand zur Decke (Richtwerte) dürfen Rauchmelder mit Sensorpunkten montiert werden, wen die Dachneigung 10° und die Raumhöhe 7 m beträgt?",
         "question_choices":
         [{
             "choice": "0,25 m",
             "correct_answer": false
         },
         {
             "choice": "0,4 m",
             "correct_answer": true
         },
         {
             "choice": "0,5 m",
             "correct_answer": false
         },
         {
             "choice": "1 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 613 ,
         "question_block_id": 113 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "I welchem maximalen Abstand zur Decke (Richtwerte) dürfen Wärmemelder mit Sensorpunkten der Klasse A1 montiert werden, wenn die Dachneigung 10° und die Raumhöhe 7 m beträgt?",
         "question_choices":
         [{
             "choice": "An der Decke",
             "correct_answer": true
         },
         {
             "choice": "0,2 m",
             "correct_answer": false
         },
         {
             "choice": "0,5 m",
             "correct_answer": false
         },
         {
             "choice": "1 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 132 ,
         "question_block_id": 115 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Beurteilen Sie die Richtigkeit der folgenden Aussagen und Begrife:",
         "question_choices":
         [{
             "choice": "die Unterteilung ni Meldebereiche dient der Risikobegrenzung.",
             "correct_answer": true
         },
         {
             "choice": "Eine BMA kann über einen oder mehrere Meldebereiche verfügen",
             "correct_answer": true
         },
         {
             "choice": "Die Festlegung der Meldergruppe hat so zu erfolgen, dass eine rasche und eindeutige Ermittlung des Melde-bzw. Brandortes möglich ist.",
             "correct_answer": true
         },
         {
             "choice": "Ein Meldebereich darf mehrere Meldergruppen umfassen.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 614 ,
         "question_block_id": 115 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Beurteilen Sie die Richtigkeit der folgenden Aussagen und Begrife:",
         "question_choices":
         [{
             "choice": "Eine Meldergruppe kann mehrere Meldebereiche umfassen",
             "correct_answer": false
         },
         {
             "choice": "Ein Überwachungsbereich ist ni mehrere Meldebereiche zu unterteilen",
             "correct_answer": false
         },
         {
             "choice": "Ein Überwachungsbereich ist die Bodenfläche, die von einem automatischen Brandmelder überwacht wird",
             "correct_answer": true
         },
         {
             "choice": "Meldebereiche dürfen über Brandabschnitte hinausgehen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 616 ,
         "question_block_id": 115 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Beurteilen Sie die Richtigkeit der folgenden Aussagen und Begriffe:",
         "question_choices":
         [{
             "choice": "Ein Sicherungsbereich ist der gesamte, durch Brandmelder überwachte Bereich",
             "correct_answer": true
         },
         {
             "choice": "Unter einem Meldebereich ist die Zusammenfassung aller Brandabschnitte zu verstehen",
             "correct_answer": false
         },
         {
             "choice": "Meldebereiche dürfen über Brandabschnitte hinausgehen",
             "correct_answer": false
         },
         {
             "choice": "Der Bereich, der von einem automatischen Melder erfasst wird, wird immer als Meldebereich bezeichnet",
             "correct_answer": false
         }]
     },{
         
         "question_id": 133 ,
         "question_block_id": 116 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Wieviele Meldergruppen sindmi EDV-Bereich zur Raumüberwachung und für Zu- und Abluft von Lüftungsanlagen mindestens notwendig?",
         "question_choices":
         [{
             "choice": "Eine Meldergruppe",
             "correct_answer": false
         },
         {
             "choice": "Zwei Meldergruppen",
             "correct_answer": false
         },
         {
             "choice": "Drei Meldergruppen",
             "correct_answer": true
         },
         {
             "choice": "Vier Meldergruppen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 134 ,
         "question_block_id": 117 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Melder unterliegen einer Einschränkung bezüglich der Luftgeschwindigkeit?",
         "question_choices":
         [{
             "choice": "Wärmemelder",
             "correct_answer": false
         },
         {
             "choice": "Rauchmelder",
             "correct_answer": true
         },
         {
             "choice": "UV-Flammenmelder",
             "correct_answer": false
         },
         {
             "choice": "IR-Flammenmelder",
             "correct_answer": false
         }]
     },{
         
         "question_id": 194 ,
         "question_block_id": 118 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Melder werden durch optische Strahlung beeinflusst?",
         "question_choices":
         [{
             "choice": "Wärmemelder",
             "correct_answer": false
         },
         {
             "choice": "Punktformige Rauchmelder",
             "correct_answer": false
         },
         {
             "choice": "Infrarot-Flammenmelder",
             "correct_answer": true
         },
         {
             "choice": "UV-Flammenmelder",
             "correct_answer": true
         }]
     },{
         
         "question_id": 620 ,
         "question_block_id": 119 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Bei welchen Meldern können Aerosole Täuschungsalarme verursachen?",
         "question_choices":
         [{
             "choice": "Wärmemelder",
             "correct_answer": false
         },
         {
             "choice": "Rauchmelder",
             "correct_answer": true
         },
         {
             "choice": "UV-Flammenmelder",
             "correct_answer": false
         },
         {
             "choice": "IR-Flammenmelder",
             "correct_answer": false
         }]
     },{
         
         "question_id": 136 ,
         "question_block_id": 120 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Wovon ist die Anzahl der punktförmigen Rauchmelder ni einem zu überwachenden Raum grundsätzlich abhängig?",
         "question_choices":
         [{
             "choice": "Raumhöhe",
             "correct_answer": true
         },
         {
             "choice": "Umgebungstemperatur",
             "correct_answer": false
         },
         {
             "choice": "Deckenform / Dachneigung",
             "correct_answer": true
         },
         {
             "choice": "Ansprechempfindlichkeit der Melder",
             "correct_answer": false
         }]
     },{
         
         "question_id": 137 ,
         "question_block_id": 120 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Wovon ist die Anzahl der punktförmigen Wärmemelder in einem zu überwachenden Raum grundsätzlich abhängig?",
         "question_choices":
         [{
             "choice": "Raumhöhe",
             "correct_answer": false
         },
         {
             "choice": "Grundfläche des zu überwachenden Raumes",
             "correct_answer": true
         },
         {
             "choice": "Klasse des Melders",
             "correct_answer": false
         },
         {
             "choice": "Deckenform/Dachneigung",
             "correct_answer": true
         }]
     },{
         
         "question_id": 622 ,
         "question_block_id": 120 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Wovon ist die Anzahl der punktförmigen Rauchmelder ni einem zu überwachenden Raum grundsätzlich abhängig?",
         "question_choices":
         [{
             "choice": "Raumhöhe",
             "correct_answer": true
         },
         {
             "choice": "Grundfläche des zu überwachenden Raumes",
             "correct_answer": true
         },
         {
             "choice": "Ansprechempfindlichkeit des Melders",
             "correct_answer": false
         },
         {
             "choice": "Zweimeldungsabhängigkeit Typ B",
             "correct_answer": true
         }]
     },{
         
         "question_id": 138 ,
         "question_block_id": 123 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "In einem Raum mit Unterzügen solen punktförmige Wärmemelder eingesetzt werden. Welche Größen haben Einfluss auf die Anordnung der punktförmigen Wärmemelder?",
         "question_choices":
         [{
             "choice": "Raumhöhe",
             "correct_answer": true
         },
         {
             "choice": "Klasse der Wärmemelder",
             "correct_answer": false
         },
         {
             "choice": "Unterzughöhe",
             "correct_answer": true
         },
         {
             "choice": "Maximal zulässige Überwachungsfläche des Melders",
             "correct_answer": true
         }]
     },{
         
         "question_id": 623 ,
         "question_block_id": 123 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "In einem Raum mit Unterzügen sollen punktförmige Wärmemelder eingesetzt werden. Welche Größen haben Einfluss auf die Anordnung der punktförmigen Wärmemelder?",
         "question_choices":
         [{
             "choice": "Größe der durch die Unterzüge gebildeten Deckenfelder",
             "correct_answer": true
         },
         {
             "choice": "Feuerwiderstandsklasse des Unterzuges",
             "correct_answer": false
         },
         {
             "choice": "Raumhöhe",
             "correct_answer": true
         },
         {
             "choice": "Klasse der Wärmemelder",
             "correct_answer": false
         }]
     },{
         
         "question_id": 139 ,
         "question_block_id": 124 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Bis zu welcher maximalen Raumhöhe sind punktförmige Wärmemelder der Klasse A2 einsetzbar?",
         "question_choices":
         [{
             "choice": "4,5 m",
             "correct_answer": false
         },
         {
             "choice": "6 m",
             "correct_answer": true
         },
         {
             "choice": "7,5 m",
             "correct_answer": false
         },
         {
             "choice": "12 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 141 ,
         "question_block_id": 126 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Bedingungen müssen für BMZ ohne Meldereinzelanzeige erfült werden, damit mehrere Räume, deren Zugänge nicht einfach überblickt werden können, zu einem Meldebereich zusammengefasst werden können?",
         "question_choices":
         [{
             "choice": "Benachbarte Räume müssen mindestens mit F 30 - Bauteilen abgetrennt sein",
             "correct_answer": false
         },
         {
             "choice": "Es dürfen maximal 5 Räume zusammengefasst werden",
             "correct_answer": true
         },
         {
             "choice": "die Gesamtfläche der Räume darf maximal 400 m2 betragen",
             "correct_answer": true
         },
         {
             "choice": "Die Räume dürfen ni verschiedenen Brandabschnitten liegen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 142 ,
         "question_block_id": 127 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Bedingungen müssen erfüllt werden, wenn ein Meldebereich von 1000 m2 sich aus mehreren Räumen zusammensetzt?",
         "question_choices":
         [{
             "choice": "Die Räume müssen benachbart sein",
             "correct_answer": true
         },
         {
             "choice": "Die Räume müssen sich auf dem gleichen Stockwerk befinden",
             "correct_answer": true
         },
         {
             "choice": "Die Zugänge der Räume müssen einfach überblickt werden können, sofern die BMZ nicht über eine Meldereinzelanzeige verfügt",
             "correct_answer": true
         },
         {
             "choice": "nI der Nähe der Zugänge der Räume müssen optische Alarmanzeigen für die betroffenen Räume vorhanden sein",
             "correct_answer": true
         }]
     },{
         
         "question_id": 626 ,
         "question_block_id": 127 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Bedingungen müssen erfüllt werden, wenn ein Meldebereich von 1000 m2 sich aus mehreren Räumen zusammensetzt?",
         "question_choices":
         [{
             "choice": "nI der Nähe der Zugänge der Räume müssen optische Alarmanzeigen für die betroffenen Räume vorhanden sein",
             "correct_answer": true
         },
         {
             "choice": "Die Grundfläche eines einzelnen Raumes darf maximal 400 m2 betragen",
             "correct_answer": false
         },
         {
             "choice": "Es dürfen maximal 5 Räume zu einem Meldebereich zusammenfasst werden",
             "correct_answer": false
         },
         {
             "choice": "Es dürfen keine Leitungen für Notbeleuchtungen durch diese Räume geführt werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 143 ,
         "question_block_id": 128 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Wie sind ni EDV-Räumen Meldergruppen von Zwischenboden, Zwischendecke und Raum zu bilden?",
         "question_choices":
         [{
             "choice": "Zwischenboden, Zwischendecke und Raum zusammengefasst",
             "correct_answer": false
         },
         {
             "choice": "Zwischendecke und Raum getrennt von Zwischenboden",
             "correct_answer": false
         },
         {
             "choice": "Zwischenboden und Raum getrennt von Zwischendecke",
             "correct_answer": false
         },
         {
             "choice": "Zwischenboden, Zwischendecke und Raum jeweils getrennt",
             "correct_answer": true
         }]
     },{
         
         "question_id": 145 ,
         "question_block_id": 129 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Melder sind zur Erkennung von Schwelbränden geeignet?",
         "question_choices":
         [{
             "choice": "Optische Rauchmelder",
             "correct_answer": true
         },
         {
             "choice": "Ansaugrauchmelder",
             "correct_answer": true
         },
         {
             "choice": "UV-Flammenmelder",
             "correct_answer": false
         },
         {
             "choice": "IR-Flammenmelder",
             "correct_answer": false
         }]
     },{
         
         "question_id": 627 ,
         "question_block_id": 129 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Melder sind zur Erkennung von Schwelbränden geeignet?",
         "question_choices":
         [{
             "choice": "IR-Flammenmelder",
             "correct_answer": false
         },
         {
             "choice": "Wärmemelder",
             "correct_answer": false
         },
         {
             "choice": "Linienförmigen Rauchmelder",
             "correct_answer": true
         },
         {
             "choice": "Ansaugrauchmelder",
             "correct_answer": true
         }]
     },{
         
         "question_id": 146 ,
         "question_block_id": 130 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Melder sind besonders geeignet, wenn ni der Entstehungsphase eines Brandes mit offenen Flammen zu rechnen ist (Flüssigkeiten, Gase)?",
         "question_choices":
         [{
             "choice": "Optische Rauchmelder",
             "correct_answer": false
         },
         {
             "choice": "Lichtstralrauchmelder",
             "correct_answer": false
         },
         {
             "choice": "UV-Flammenmelder",
             "correct_answer": true
         },
         {
             "choice": "IR-Flammenmelder",
             "correct_answer": true
         }]
     },{
         
         "question_id": 628 ,
         "question_block_id": 130 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Melder sind besonders geeignet, wenn ni der Entstehungsphase eines Brandes mit offenen Flammen zu rechnen ist (Flüssigkeiten, Gase)?",
         "question_choices":
         [{
             "choice": "UV-Flammenmelder",
             "correct_answer": true
         },
         {
             "choice": "IR-Flammenmelder",
             "correct_answer": true
         },
         {
             "choice": "Wärmemelder",
             "correct_answer": false
         },
         {
             "choice": "Kombination verschiedener Melderarten",
             "correct_answer": false
         }]
     },{
         
         "question_id": 147 ,
         "question_block_id": 131 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Wo können Wärmemelder eingesetzt werden?",
         "question_choices":
         [{
             "choice": "Bei einer Luftgeschwindigkeit von 5m/s",
             "correct_answer": true
         },
         {
             "choice": "Bei Gebäuden mit gebäudeüblichen Erschütterungen",
             "correct_answer": true
         },
         {
             "choice": "Bei 90 % relativer Luftfeuchtigkeit",
             "correct_answer": true
         },
         {
             "choice": "Bei stark schwankenden Raumtemperaturen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 629 ,
         "question_block_id": 132 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Was kann bei Wärmemeldern Täuschungsalarme verursachen?",
         "question_choices":
         [{
             "choice": "Zigarettenrauch",
             "correct_answer": false
         },
         {
             "choice": "Staub",
             "correct_answer": false
         },
         {
             "choice": "Benzindämpfe",
             "correct_answer": false
         },
         {
             "choice": "Indirekte Lichteinstrahlung",
             "correct_answer": false
         }]
     },{
         
         "question_id": 630 ,
         "question_block_id": 132 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Was kann bei Wärmemeldern Täuschungsalarme verursachen?",
         "question_choices":
         [{
             "choice": "Lichtbogenschweißen ni einem Abstand von 4 m",
             "correct_answer": false
         },
         {
             "choice": "Autogenschweißen ni einem Abstand von 4 m",
             "correct_answer": false
         },
         {
             "choice": "Ein Fotoblitz",
             "correct_answer": false
         },
         {
             "choice": "Leuchtstofflampen mi Abstand von 2 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 631 ,
         "question_block_id": 132 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Was kann bei Wärmemeldern Täuschungsalarme verursachen?",
         "question_choices":
         [{
             "choice": "Lichtreflexionen an Maschinenteilen",
             "correct_answer": false
         },
         {
             "choice": "Fußbodenheizungen",
             "correct_answer": false
         },
         {
             "choice": "Heizgebläse",
             "correct_answer": true
         },
         {
             "choice": "Pfeifenrauch",
             "correct_answer": false
         }]
     },{
         
         "question_id": 148 ,
         "question_block_id": 133 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Wo werden UV-Flammenmelder eingesetzt?",
         "question_choices":
         [{
             "choice": "Bei staubiger Umgebung",
             "correct_answer": false
         },
         {
             "choice": "Bei nebeliger Umgebung",
             "correct_answer": false
         },
         {
             "choice": "Bei verrauchter Umgebung",
             "correct_answer": false
         },
         {
             "choice": "Bei Bereichen mit sofortiger Flammenentwicklungmi Brandfal",
             "correct_answer": true
         }]
     },{
         
         "question_id": 149 ,
         "question_block_id": 134 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Bis zu welcher maximalen Raumhöhe sind punktförmige Wärmemelde der Klasse A1 einsetzbar?",
         "question_choices":
         [{
             "choice": "4,5 m",
             "correct_answer": false
         },
         {
             "choice": "6 m",
             "correct_answer": false
         },
         {
             "choice": "7,5 m",
             "correct_answer": true
         },
         {
             "choice": "9 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 150 ,
         "question_block_id": 135 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Was sind zulässige Maßnahmen zur Reduzierung von Falschalarmen?",
         "question_choices":
         [{
             "choice": "Zweimeldungsabhängigkeit Typ A",
             "correct_answer": true
         },
         {
             "choice": "Zweimeldungsabhängigkeit Typ B",
             "correct_answer": true
         },
         {
             "choice": "Abschalten häufig auslösender Meldergruppen",
             "correct_answer": false
         },
         {
             "choice": "Einsatz von Mehrfachsensormelder",
             "correct_answer": true
         }]
     },{
         
         "question_id": 160 ,
         "question_block_id": 135 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Betriebsarten zur Vermeidung von Falschalarmen sind ni der Norm bei Brandmeldeanlagen mit automatischen Brandmeldern genannt?",
         "question_choices":
         [{
             "choice": "Betriebsart OM, Brandmeldeanlagen ohne besondere Maßnahmen",
             "correct_answer": true
         },
         {
             "choice": "Betriebsart TM, Brandmeldeanlagen mit technischen Maßnahmen",
             "correct_answer": true
         },
         {
             "choice": "Betriebsart PM, Brandmeldeanlagen mit personellen Maßnahmen",
             "correct_answer": true
         },
         {
             "choice": "Betriebsart KM, Brandmeldeanlagen mit konstruktiven Maßnahmen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 633 ,
         "question_block_id": 135 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Was sind zulässige Maßnahmen zur Reduzierung von Falschalarmen?",
         "question_choices":
         [{
             "choice": "Einsatz von Mehrfachsensormelder",
             "correct_answer": true
         },
         {
             "choice": "Verzögerte Weiterleitung von Brandmeldungen unter bestimmten Umständen",
             "correct_answer": true
         },
         {
             "choice": "Abschaltung der BMA zu Zeiten, ni denen mit einem Brand nicht gerechnet wird",
             "correct_answer": false
         },
         {
             "choice": "Zweimeldungsabhängigkeit Typ Aund Typ B",
             "correct_answer": true
         }]
     },{
         
         "question_id": 151 ,
         "question_block_id": 136 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Umgebungseinflüsse können insbesondere das Ansprechverhalten bei einem Linienförmigen Rauchmelder maßgeblich verändern?",
         "question_choices":
         [{
             "choice": "Starke Luftströmungsgeschwindigkeiten",
             "correct_answer": true
         },
         {
             "choice": "Abdeckung durch Gegenstände",
             "correct_answer": true
         },
         {
             "choice": "UV-Strahlung",
             "correct_answer": false
         },
         {
             "choice": "Sonnenstrahlung",
             "correct_answer": false
         }]
     },{
         
         "question_id": 634 ,
         "question_block_id": 136 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Umgebungseinflüsse können insbesondere das Ansprechverhalten bei einem Linienförmigen Rauchmelder maßgeblich verändern?",
         "question_choices":
         [{
             "choice": "Nebelbildung",
             "correct_answer": true
         },
         {
             "choice": "Betauung",
             "correct_answer": true
         },
         {
             "choice": "UV-Strahlung",
             "correct_answer": false
         },
         {
             "choice": "Sonnenstrahlung",
             "correct_answer": false
         }]
     },{
         
         "question_id": 153 ,
         "question_block_id": 137 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Müssen die nachfolgend aufgeführten Teilbereiche bei der Überwachung eines Bereiches mitberücksichtigt werden?",
         "question_choices":
         [{
             "choice": "Transport- und Transmissionsschächte",
             "correct_answer": true
         },
         {
             "choice": "Kabelkanäle und -schächte, sofern sie begehbar oder mit Revisionsöffnungen ausgestattet sind",
             "correct_answer": true
         },
         {
             "choice": "Klima-, Be- und Entlüftungsanlagen",
             "correct_answer": true
         },
         {
             "choice": "Sanitärräume, z. B. Waschräume, Toiletten, wenn ni diesen Räumen keine brennbaren Vorräte oder Abfäle aufbewahrt werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 636 ,
         "question_block_id": 137 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Müssen die nachfolgend aufgeführten Teilbereiche bei der Überwachung eines Bereiches mitberücksichtigt werden?",
         "question_choices":
         [{
             "choice": "Kanäle und Schächte für Material und Abfälle und deren Sammelbehälter",
             "correct_answer": true
         },
         {
             "choice": "Kabelkanäle und Schächte, die für Personen nicht zugänglich und gegenüber anderen Bereichen feuerbeständig F( 90-A) abgeschottet sind",
             "correct_answer": false
         },
         {
             "choice": "Kammern und Einbauten jeder Art",
             "correct_answer": true
         },
         {
             "choice": "Schutzräume, die nicht zu anderen Zwecken verwendet werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 637 ,
         "question_block_id": 137 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Müssen die nachfolgend aufgeführten Teilbereiche bei der Überwachung eines Bereiches mitberücksichtigt werden?",
         "question_choices":
         [{
             "choice": "Laderampen im Freien",
             "correct_answer": false
         },
         {
             "choice": "Räume, die durch eine von VdS anerkannte, automatische Löschanlage geschützt sind, es sei denn, die BMA ist zur Ansteuerung einer Löschanlage oder aus sonstigen Gründen erforderlich",
             "correct_answer": false
         },
         {
             "choice": "Zwischendecken- und Zwischenbodenbereiche",
             "correct_answer": true
         },
         {
             "choice": "Teilbereiche ni Räumen, die durch näher als 0,5 m an die Decke reichende Regale oder sonstige Einrichtungen geschaffen werden",
             "correct_answer": true
         }]
     },{
         
         "question_id": 154 ,
         "question_block_id": 138 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Was verstehen Sie unter Zweimeldungsabhängigkeit Typ B?",
         "question_choices":
         [{
             "choice": "Die Brandmeldung erfolgt erst nach Ansprechen zweier automatischer Brandmelder einer Meldergruppe",
             "correct_answer": true
         },
         {
             "choice": "Die Brandmeldung erfolgt erst nach Ansprechen je eines Melders aus zwei einander zugeordneten Meldergruppen",
             "correct_answer": true
         },
         {
             "choice": "Eine technische Maßnahme (TM) zur Vermeidung von Falschalarmen",
             "correct_answer": true
         },
         {
             "choice": "Eine personelle Maßnahme (PM) zur Vermeidung von Falschalarmen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 157 ,
         "question_block_id": 140 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Melder dürfen innerhalb eines Meldebereiches zu einer Meldergruppe zusammengefasst werden?",
         "question_choices":
         [{
             "choice": "Automatische Melder",
             "correct_answer": true
         },
         {
             "choice": "Handfeuermelder",
             "correct_answer": true
         },
         {
             "choice": "Automatische Melder und Handfeuermelder",
             "correct_answer": false
         },
         {
             "choice": "Handfeuermelder und Sprinklerauslösung",
             "correct_answer": false
         }]
     },{
         
         "question_id": 162 ,
         "question_block_id": 144 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Auf welche definierten Bereiche darf sich eine Systemstörung an der BMZ auswirken?",
         "question_choices":
         [{
             "choice": "Meldebereiche mit einer Gesamtfläche von höchstens 10.000 m2 und nicht mehr als 512 Melder",
             "correct_answer": false
         },
         {
             "choice": "Meldebereiche mit einer Gesamtfläche von höchstens 12.000 m2 und nicht mehr als 512 Melder",
             "correct_answer": true
         },
         {
             "choice": "Meldebereiche mit einer Gesamtfläche von höchstens 12.000 m2 und nicht mehr als 536 Melder",
             "correct_answer": false
         },
         {
             "choice": "Meldebereiche mit einer Gesamtfläche von höchstens 48.000 m2 und nicht mehr als 1.024 Melder",
             "correct_answer": false
         }]
     },{
         
         "question_id": 163 ,
         "question_block_id": 145 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Was ist bei einer Systemstörung an der BMZ, die auf Meldebereiche mit einer Fläche von mehr als 12.000 m? wirkt, zu beachten?",
         "question_choices":
         [{
             "choice": "Die Alarmierungseinrichtungen müssen funktionsfähig bleiben",
             "correct_answer": false
         },
         {
             "choice": "Die Brandmeldung der Melder müssen funktionsfähig bleiben",
             "correct_answer": true
         },
         {
             "choice": "Die Steuergruppen müssen ni den ausgelösten Zustand überführt werden",
             "correct_answer": false
         },
         {
             "choice": "Die Übertragungseinrichtung muss aktiviert werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 770 ,
         "question_block_id": 383 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Wieviele Löschbereiche bei größer 6.000 m2 dürfen maximal die Meldungen der Auslösung über eine Ringleitung übertragen?",
         "question_choices":
         [{
             "choice": "Zwei",
             "correct_answer": false
         },
         {
             "choice": "Vier",
             "correct_answer": false
         },
         {
             "choice": "Acht",
             "correct_answer": true
         },
         {
             "choice": "Zwölf",
             "correct_answer": false
         }]
     },{
         
         "question_id": 771 ,
         "question_block_id": 384 ,
         "subcategory_id": 4.1 ,
         "question_points": 1 ,
         "question_text": "Bis zu welcher maximalen Raumhöhe dürfen Ansaugrauchmelder der Klasse Bnach DNI EN 54-20 unter bestimmten Voraussetzungen eingesetzt werden?",
         "question_choices":
         [{
             "choice": "Bis 12 m",
             "correct_answer": false
         },
         {
             "choice": "Bis 16 m",
             "correct_answer": true
         },
         {
             "choice": "Bis 20 m",
             "correct_answer": false
         },
         {
             "choice": "Bis 45 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 776 ,
         "question_block_id": 389 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Klassen von Ansaugrauchmeldern gibt es?",
         "question_choices":
         [{
             "choice": "Keine Klassen",
             "correct_answer": false
         },
         {
             "choice": "A - sehr hohe Empfindlichkeit - sehr frühe Erkennung",
             "correct_answer": true
         },
         {
             "choice": "B - erhöhte Empfindlichkeit - frühe Erkennung",
             "correct_answer": true
         },
         {
             "choice": "C - übliche Empfindlichkeit - normale Erkennung",
             "correct_answer": true
         }]
     },{
         
         "question_id": 777 ,
         "question_block_id": 389 ,
         "subcategory_id": 4.1 ,
         "question_points": 4 ,
         "question_text": "Welche Klassen von punktförmigen Rauchmeldern gibt es?",
         "question_choices":
         [{
             "choice": "Keine Klassen",
             "correct_answer": true
         },
         {
             "choice": "A - sehr hohe Empfindlichkeit - sehr frühe Erkennung",
             "correct_answer": false
         },
         {
             "choice": "B - erhöhte Empfindlichkeit - frühe Erkennung",
             "correct_answer": false
         },
         {
             "choice": "C - übliche Empfindlichkeit - normale Erkennung",
             "correct_answer": false
         }]
     },{
         
         "question_id": 727 ,
         "question_block_id": 113 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welcher Abstand (Richtwert) muss beim Einsatz von Rauchmelder mit Sensorpunkten bei einer Baumhöhe von 7,5 m zum Dach mit einer Dachneigung bis 20cel eingehalten werden?",
         "question_choices":
         [{
             "choice": "Grundsätzlich Montage an der Decke",
             "correct_answer": false
         },
         {
             "choice": "Bis max. 0,25 m",
             "correct_answer": false
         },
         {
             "choice": "Bis max. 0,4 m",
             "correct_answer": true
         },
         {
             "choice": "Bis max. 1,0 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 159 ,
         "question_block_id": 141 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Wodurch kann die Überwachung eines Raumes mit Flammenmeldern eingeschränkt werden?",
         "question_choices":
         [{
             "choice": "Durch schnell bewegte Gegenstände",
             "correct_answer": false
         },
         {
             "choice": "Durch Temperaturänderungen",
             "correct_answer": false
         },
         {
             "choice": "Durch Dunkelheit",
             "correct_answer": false
         },
         {
             "choice": "Durch Schattenbildung von Gegenständen",
             "correct_answer": true
         }]
     },{
         
         "question_id": 161 ,
         "question_block_id": 143 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Deckenfelder, die durch Unterzüge / Unterteilungen gebildet wurden, müssen als separater Raum betrachtet werden, wenn Sie um wieviel größer als der zulässige Überwachungsbereich eines Melders sind?",
         "question_choices":
         [{
             "choice": "1,1-fach",
             "correct_answer": false
         },
         {
             "choice": "1,2-fach",
             "correct_answer": true
         },
         {
             "choice": "1,3-fach",
             "correct_answer": false
         },
         {
             "choice": "1,5-fach",
             "correct_answer": false
         }]
     },{
         
         "question_id": 164 ,
         "question_block_id": 146 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche der folgenden Aussagen zur Fernalarmierung sind richtig?",
         "question_choices":
         [{
             "choice": "Bei einem Fernalarm wird ausschließlich optisch alarmiert.",
             "correct_answer": false
         },
         {
             "choice": "Der Fernalarm erfolgt ni der Regel mi Gebäude und nur für den Alarmierungsbereich, der einem oder mehreren Meldebereichen zugeordnet ist",
             "correct_answer": false
         },
         {
             "choice": "Der Fernalarm dient dem Herbeiruf der zuständigen Feuerwehr oder der hilfeleistenden Kräfte zu dem betroffenen Objekt.",
             "correct_answer": true
         },
         {
             "choice": "Der Fernalarm soll die Evakuierung der Gebäudebelegschaft einleiten (gebäudekundige Personen)",
             "correct_answer": false
         }]
     },{
         
         "question_id": 165 ,
         "question_block_id": 147 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Was ist bei ni Zweimeldungsabhängigkeit Typ B geschalteten Flammenmeldern zu beachten?",
         "question_choices":
         [{
             "choice": "Die Melder sind mit unterschiedlichen Blickwinkeln auf denselben Überwachungsbereich zu richten",
             "correct_answer": true
         },
         {
             "choice": "Die Melder sind mit gleichen Blickwinkeln auf denselben Überwachungsbereich zu richten",
             "correct_answer": false
         },
         {
             "choice": "Die Melder sind mit unterschiedlichen Blickwinkeln auf jeweils benachbarte Überwachungsbereiche zu richten",
             "correct_answer": false
         },
         {
             "choice": "Die Melder sind mit gleichen Blickwinkeln auf jeweils benachbarte Überwachungsbereiche zu richten.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 166 ,
         "question_block_id": 148 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Was ist bei der Anordnung von automatischen Meldern in zwangsbelüfteten Räumen mit perforierten Decken zu beachten, wenn diese der Belüftung dienen?",
         "question_choices":
         [{
             "choice": "Der Raum muss oberhalb und unterhalb der Decke überwacht werden",
             "correct_answer": false
         },
         {
             "choice": "die Decken müssen mi Radius von 0,5 m mu den Melder geschlossen werden",
             "correct_answer": true
         },
         {
             "choice": "Die Decken müssen mi Radius von 1,5 mum den Melder geschlossen werden.",
             "correct_answer": false
         },
         {
             "choice": "Die Decken müssen im Radius von 2,0 m um den Melder geschlossen werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 167 ,
         "question_block_id": 149 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "In welcher Höhe muss ein Handfeuermelder angebracht werden?",
         "question_choices":
         [{
             "choice": "Der Druckknopf muss sich 1,30 + 0,2 m/-0,4 m über der Standfläche befinden",
             "correct_answer": false
         },
         {
             "choice": "Der Druckknopf mus sich 1,40 + 02, m/-0,4 m über der Standfläche befinden",
             "correct_answer": true
         },
         {
             "choice": "Der Druckknopf muss sich 1,40 + 0,2 m/-0,2 m über der Standfläche befinden",
             "correct_answer": false
         },
         {
             "choice": "Der Druckknopf muss sich 1,40 +0,4 m/-0,2 m über der Standfläche befinden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 168 ,
         "question_block_id": 150 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "nI einem 12 m hohen Raum sollen automatische Brandmelder installiert werden. Welche Melder sind bezogen auf die Raumhöhe uneingeschränkt geeignet?",
         "question_choices":
         [{
             "choice": "Rauchmelder",
             "correct_answer": true
         },
         {
             "choice": "Punktförmige Wärmemelder Klasse A1",
             "correct_answer": false
         },
         {
             "choice": "Flammenmelder",
             "correct_answer": true
         },
         {
             "choice": "Mehrfachsensormelder (Rauch und Wärme) mit einzeln Zu- und Abschaltbarkeit eines Kriteriums",
             "correct_answer": false
         }]
     },{
         
         "question_id": 169 ,
         "question_block_id": 151 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "nI einem 6mhohen Raum sollen automatische Brandmelder installiert werden. Welche Melder sind bezogen auf die Raumhöhe geeignet?",
         "question_choices":
         [{
             "choice": "Rauchmelder",
             "correct_answer": true
         },
         {
             "choice": "Punktförmige Wärmemelder Klasse A1 und A2",
             "correct_answer": true
         },
         {
             "choice": "Linienförmige Wärmemelder Klasse A1 und A2",
             "correct_answer": true
         },
         {
             "choice": "Flammenmelder",
             "correct_answer": true
         }]
     },{
         
         "question_id": 170 ,
         "question_block_id": 152 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wann können punktförmige Rauchmelder abhängig von der Raumnutzung und von der Brandentstehungsphase ni Räumen mit einer Höhe größer 12 m und kleiner 16 m eingesetzt werden?",
         "question_choices":
         [{
             "choice": "nI der Brandentstehungsphase muss eine starke Wärmeentwicklung und eine starke Rauchentwicklung vorhanden sein.",
             "correct_answer": true
         },
         {
             "choice": "nI der Brandentstehungsphase muss eine starke Rauchentwicklung vorhanden sein.",
             "correct_answer": false
         },
         {
             "choice": "In der Brandentstehungsphase muss eine starke Wärmeentwicklung vorhanden sein.",
             "correct_answer": false
         },
         {
             "choice": "nI der Brandentstehungsphase muss eine starke Wärmeentwicklung und eine starke Flammenbildung vorhanden sein.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 171 ,
         "question_block_id": 153 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wei viele Handfeuermelder dürfen maximal ni einer Meldergruppe zusammengefasst werden?",
         "question_choices":
         [{
             "choice": "8",
             "correct_answer": false
         },
         {
             "choice": "10",
             "correct_answer": true
         },
         {
             "choice": "12",
             "correct_answer": false
         },
         {
             "choice": "32",
             "correct_answer": false
         }]
     },{
         
         "question_id": 172 ,
         "question_block_id": 154 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Handfeuermelder müssen mi Algemeinen von der Anzahl und vom Anbringungsort her so angeordnet sein, dass eine Person nicht mehr zurücklegen muss als...",
         "question_choices":
         [{
             "choice": "30 m",
             "correct_answer": false
         },
         {
             "choice": "40 m",
             "correct_answer": false
         },
         {
             "choice": "50 m",
             "correct_answer": true
         },
         {
             "choice": "60 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 199 ,
         "question_block_id": 154 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Handfeuermelder müssen ni feuergefährdeten Betriebsstätten von der Anzahl und vom Anbringungsort her so angeordnet sein, dass eine Person nicht mehr zurücklegen muss als...",
         "question_choices":
         [{
             "choice": "30 m",
             "correct_answer": true
         },
         {
             "choice": "40 m",
             "correct_answer": false
         },
         {
             "choice": "50 m",
             "correct_answer": false
         },
         {
             "choice": "60 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 173 ,
         "question_block_id": 155 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Um wieviel muss die maximal zulässige Überwachungsfläche bei der Anordnung von punktförmigen Rauchmeldern in Zweimeldungsabhängigkeit Typ B mindestens reduziert werden?",
         "question_choices":
         [{
             "choice": "20 %",
             "correct_answer": false
         },
         {
             "choice": "30 %",
             "correct_answer": true
         },
         {
             "choice": "40 %",
             "correct_answer": false
         },
         {
             "choice": "50 %",
             "correct_answer": false
         }]
     },{
         
         "question_id": 174 ,
         "question_block_id": 156 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Um wieviel muss die maximal zulässige Überwachungsfläche bei der Anordnung von punktförmigen Rauchmeldern ni Zweimeldungsahängigkeit Typ Bund Ansteuerung einer Löschanlage mindestens reduziert werden?",
         "question_choices":
         [{
             "choice": "20 %",
             "correct_answer": false
         },
         {
             "choice": "30 %",
             "correct_answer": false
         },
         {
             "choice": "40 %",
             "correct_answer": false
         },
         {
             "choice": "50 %",
             "correct_answer": true
         }]
     },{
         
         "question_id": 176 ,
         "question_block_id": 158 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welcher Abstand für automatische Brandmelder, die ni Zweimeldungsabhängigkeit Typ Bgeschaltet werden, sollte nicht unterschritten werden?",
         "question_choices":
         [{
             "choice": "Keine Anforderung an den Abstand",
             "correct_answer": false
         },
         {
             "choice": "2,00 m",
             "correct_answer": false
         },
         {
             "choice": "2,50 m",
             "correct_answer": true
         },
         {
             "choice": "3,00 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 177 ,
         "question_block_id": 159 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wie viele punktförmigeRauchmelder werden benötigt, um einen 5mbreiten und 61 m langen Raum und 7 m Raumhöhe zu überwachen (Dachneigung 15°)?",
         "question_choices":
         [{
             "choice": "Ein Rauchmelder",
             "correct_answer": false
         },
         {
             "choice": "Zwei Rauchmelder",
             "correct_answer": true
         },
         {
             "choice": "Drei Rauchmelder",
             "correct_answer": false
         },
         {
             "choice": "Vier Rauchmelder",
             "correct_answer": false
         }]
     },{
         
         "question_id": 178 ,
         "question_block_id": 160 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wie bestimmt man den größten zulässigen horizontalen Abstand eines beliebigen Punktes der Decke zum nächstgelegenen Melder?",
         "question_choices":
         [{
             "choice": "Durch Rechnung ausgehend von der Überwachungsfläche und Ansatz einer idealen quadratischen Aufteilung.",
             "correct_answer": false
         },
         {
             "choice": "Die maximalen Abstände sind ni den Diagrammen der DNI VDE 0833-2 aufgeführt; diese ermöglichen die Abweichung von der idealen quadratischen Aufteilung.",
             "correct_answer": true
         },
         {
             "choice": "Durch Rechnung, man teilt die Raumfläche durch die Überwachungsfläche und verteilt dann die Melder gleichmäßig.",
             "correct_answer": false
         },
         {
             "choice": "Eine Bestimmung ist nicht notwendig, da dieser Wert ni den Aufschaltbedingungen der ortlichen Feuerwehr geregelt ist und regional unterschiedlich ist.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 179 ,
         "question_block_id": 161 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welcher minimale Abstand zu Wänden muss bei Rauchmeldern eingehalten werden?",
         "question_choices":
         [{
             "choice": "0,5 m",
             "correct_answer": true
         },
         {
             "choice": "1,0 m",
             "correct_answer": false
         },
         {
             "choice": "1,5 m",
             "correct_answer": false
         },
         {
             "choice": " 2,0 m ",
             "correct_answer": false
         }]
     },{
         
         "question_id": 180 ,
         "question_block_id": 162 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Ab Überschreiten welcher Mindesthöhe müssen Unterzüge / Unterteilungen, die direkt an Decken ansetzen, beachtet werden?",
         "question_choices":
         [{
             "choice": "3% der Raumhöhe",
             "correct_answer": false
         },
         {
             "choice": "6% der Raumhöhe",
             "correct_answer": false
         },
         {
             "choice": "0,20 m",
             "correct_answer": true
         },
         {
             "choice": "0,25 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 181 ,
         "question_block_id": 163 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Ab Überschreiten welcher Fläche müssen durch zu berücksichtigende Unterteilungen / Unterzüge gebildete Deckenfelder einzeln mit punktförmign Rauch- oder Wärmemelder überwacht werden?",
         "question_choices":
         [{
             "choice": "0,4-fache des max. Überwachungsbereiches eines Melders",
             "correct_answer": false
         },
         {
             "choice": "0,5-fache des max. Überwachungsbereiches eines Melders",
             "correct_answer": false
         },
         {
             "choice": "0,6-fache des max. Überwachungsbereiches eines Melders",
             "correct_answer": true
         },
         {
             "choice": "1,2-fache des max. Überwachungsbereiches eines Melders",
             "correct_answer": false
         }]
     },{
         
         "question_id": 182 ,
         "question_block_id": 164 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Ab Überschreiten welcher Höhe der Unterteilungen / Unterzüge müssen einzelne Deckenfelder mit punktförmigen Rauch- oder Wärmemeldern überwacht werden?",
         "question_choices":
         [{
             "choice": "3% der Raumhöhe",
             "correct_answer": false
         },
         {
             "choice": "0,20 m",
             "correct_answer": false
         },
         {
             "choice": "0,25 m",
             "correct_answer": false
         },
         {
             "choice": "0,80 m",
             "correct_answer": true
         }]
     },{
         
         "question_id": 183 ,
         "question_block_id": 165 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wei groß dürfen ni Gängen und Deckenfeldern bis zu einer Breite von 3mdie Melderabstände bei Wärmemeldern maximal gewählt werden?",
         "question_choices":
         [{
             "choice": "7 m",
             "correct_answer": false
         },
         {
             "choice": "8 m",
             "correct_answer": false
         },
         {
             "choice": "9 m",
             "correct_answer": false
         },
         {
             "choice": "10 m",
             "correct_answer": true
         }]
     },{
         
         "question_id": 184 ,
         "question_block_id": 166 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wei groß dürfen ni Gängen und Deckenfeldern bis zu einer Breite von 3 m die Melderabstände bei Wärmemeldern ni Zweimeldungsabhängigkeit Typ Bmaximal gewählt werden?",
         "question_choices":
         [{
             "choice": "5 m",
             "correct_answer": true
         },
         {
             "choice": "6 m",
             "correct_answer": false
         },
         {
             "choice": "7 m",
             "correct_answer": false
         },
         {
             "choice": "8 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 185 ,
         "question_block_id": 167 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wei groß dürfen ni Gängen und Deckenfeldern bis zu einer Breite von 3 m die Melderabstände bei Rauchmeldern ni Zweimeldungsabhängigkeit Typ Bohne Ansteuerung von Feuerlöschanlagen maximal gewählt werden?",
         "question_choices":
         [{
             "choice": "10 m",
             "correct_answer": false
         },
         {
             "choice": "11 m",
             "correct_answer": true
         },
         {
             "choice": "12 m",
             "correct_answer": false
         },
         {
             "choice": "13 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 186 ,
         "question_block_id": 168 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wie groß dürfen ni Gängen und Deckenfeldern bis uz einer Breite von 3 m die Melderabstände bei Rauchmeldern ni Zweimeldungsabhängigkeit Typ B it Ansteuerung von Feurlöschanlagen maximal gewählt werden?",
         "question_choices":
         [{
             "choice": "6,0 m",
             "correct_answer": false
         },
         {
             "choice": "6,5 m",
             "correct_answer": false
         },
         {
             "choice": "7,0 m",
             "correct_answer": false
         },
         {
             "choice": "7,5 m",
             "correct_answer": true
         }]
     },{
         
         "question_id": 187 ,
         "question_block_id": 169 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wie groß dürfen in Gängen und Deckenfeldern bis zu einer Breite von 3 m die Melderabstände bei Rauchmeldern maximal gewählt werden?",
         "question_choices":
         [{
             "choice": "12 m",
             "correct_answer": false
         },
         {
             "choice": "13 m",
             "correct_answer": false
         },
         {
             "choice": "14 m",
             "correct_answer": false
         },
         {
             "choice": "15 m",
             "correct_answer": true
         }]
     },{
         
         "question_id": 188 ,
         "question_block_id": 170 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wo sind automatische Brandmelder ni Kreuzungs-, Einmündungs- und Eckbereichen von schmalen Gängen und Deckenfeldern anzuordnen?",
         "question_choices":
         [{
             "choice": "In der Nähe",
             "correct_answer": false
         },
         {
             "choice": "In deren Schnittpunkt",
             "correct_answer": true
         },
         {
             "choice": "Sind nicht erforderlich",
             "correct_answer": false
         },
         {
             "choice": "Ist nicht geregelt",
             "correct_answer": false
         }]
     },{
         
         "question_id": 189 ,
         "question_block_id": 171 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Was ist beim Einsatz von automatischen Brandmeldern bei Temperaturen unterhalb von 0° C zu beachten?",
         "question_choices":
         [{
             "choice": "Die Melder dürfen nicht vereisen können",
             "correct_answer": true
         },
         {
             "choice": "Die Melder dürfen nicht betaut werden können",
             "correct_answer": false
         },
         {
             "choice": "Eine zusätzliche Heizung mi Melder ist zwingend vorgesehen",
             "correct_answer": false
         },
         {
             "choice": "die Melder müssen mit einem 'Eisstern' gekennzeichnet sein",
             "correct_answer": false
         }]
     },{
         
         "question_id": 190 ,
         "question_block_id": 172 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Bis zu welchen Luftgeschwindigkeiten dürfen Rauchmelder nach DNI VDE 0833-2 ohne besondere Herstellerangaben betrieben werden?",
         "question_choices":
         [{
             "choice": "5 m/s",
             "correct_answer": true
         },
         {
             "choice": "10 m/s",
             "correct_answer": false
         },
         {
             "choice": "15 m/s",
             "correct_answer": false
         },
         {
             "choice": "20 m/s",
             "correct_answer": false
         }]
     },{
         
         "question_id": 191 ,
         "question_block_id": 173 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Bei welchen Melderarten müssen Erschütterungen beachtet werden?",
         "question_choices":
         [{
             "choice": "Rauchmelder nach dem Streulichtprinzip",
             "correct_answer": false
         },
         {
             "choice": "Linienförmigen Rauchmelder",
             "correct_answer": true
         },
         {
             "choice": "IR-Flammenmelder",
             "correct_answer": true
         },
         {
             "choice": "Wärmemelder",
             "correct_answer": false
         }]
     },{
         
         "question_id": 192 ,
         "question_block_id": 174 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Bis zu welcher relativen Luftfeuchte können Rauch- und Flammenmelder eingesetzt werden, wenn dabei Nebelbildung und/oder Betauung ausgeschlossen sind?",
         "question_choices":
         [{
             "choice": "85 %",
             "correct_answer": false
         },
         {
             "choice": "90 %",
             "correct_answer": false
         },
         {
             "choice": "95 %",
             "correct_answer": true
         },
         {
             "choice": "98 %",
             "correct_answer": false
         }]
     },{
         
         "question_id": 196 ,
         "question_block_id": 177 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wie kann radioaktive Strahlung die Funktion und Lebensdauer von Brandmeldern beeinflussen und was muss dabei beachtet werden?",
         "question_choices":
         [{
             "choice": "Es erfolgt keine Beeinflussung",
             "correct_answer": false
         },
         {
             "choice": "die Eignung der Brandmelder istmi Einzelfal nachzuweisen",
             "correct_answer": true
         },
         {
             "choice": "Der Einsatz von Brandmeldern ist ohne Kompensationsmaßnahmen nicht zulässig",
             "correct_answer": false
         },
         {
             "choice": "Die Eignung der Brandmelder ist durch ein CE Zeichen nachzuweisen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 197 ,
         "question_block_id": 178 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche grundlegenden Anforderungen sind an den Anbringungsort für Handfeuermelder zu stellen?",
         "question_choices":
         [{
             "choice": "Sie müssen gut sichtbar und frei zugänglich angeordnet sein.",
             "correct_answer": true
         },
         {
             "choice": "Ein Handfeuermelder muss bei Bedarf durch ein Hinweisschild nach DIN 14623 gekennzeichnet sein.",
             "correct_answer": true
         },
         {
             "choice": "Handfeuermelder sind grundsätzlich ni den Treppenhäusern zu installieren.",
             "correct_answer": false
         },
         {
             "choice": "Handfeuermelder sind grundsätzlich ni Fluren vor den Treppenhäusern auf der rechten Türseite zu installieren.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 198 ,
         "question_block_id": 179 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche grundlegenden Anforderungen sind an den Anbringungsort für Handfeuermelder zu stellen?",
         "question_choices":
         [{
             "choice": "Sie müssen ausreichend durch Tageslicht oder eine andere Lichtquelle beleuchtet sein; ist Sicherheitsbeleuchtung vorhanden, muss der Handfeuermelder neben einem Piktogramm installiert werden.",
             "correct_answer": false
         },
         {
             "choice": "Sie müssen ni Flucht und Rettungswegen ni Wandnischen installiert werden.",
             "correct_answer": false
         },
         {
             "choice": "Sie müssen ausreichend durch Tageslicht oder eine andere Lichtquelle beleuchtet sein; ist Sicherheitsbeleuchtung vorhanden, muss diese auch den Handfeuermelder beleuchten.",
             "correct_answer": true
         },
         {
             "choice": "Sie müssen ni den Flucht- und Rettungswegen installiert werden.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 200 ,
         "question_block_id": 181 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welcher Abstand (Richtwert) muss beim Einsatz von Brandmeldern mit Sensorpunkten bei einer Raumhöhe von 7,5 m zum Dach mit einer Dachneigung bis 20° eingehalten werden?",
         "question_choices":
         [{
             "choice": "Bei Raumhöhen bis zu 6 m ist ein Abstand bis zu 0,4 m einzuhalten",
             "correct_answer": false
         },
         {
             "choice": "Bei Raumhöhen von 6 m bis zu 12 m ist ein Abstand bis zu 0,8 m einzuhalten.",
             "correct_answer": false
         },
         {
             "choice": "Bei Raumhöhen von 6 m bis zu 12 m ist ein Abstand bis zu 0, 65 m einzuhalten.",
             "correct_answer": false
         },
         {
             "choice": "Wärmemelder sind grundsätzlich nicht abgehängt von der Decke anzubringen.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 201 ,
         "question_block_id": 182 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welcher Abstand für Rauchmelder mit Sensorpunkten zur Decke ist bei einer Raumhöhe von 7,50 m und einer Dachneigung kleiner als 20° einzuhalten?",
         "question_choices":
         [{
             "choice": "Es ist ein Abstand bis zu 0,25 m einzuhalten.",
             "correct_answer": false
         },
         {
             "choice": "Es ist ein Abstand bis zu 0,25 m einzuhalten.",
             "correct_answer": true
         },
         {
             "choice": "Es ist ein Abstand von 0,25 mbis zu 0,6 meinzuhalten.",
             "correct_answer": false
         },
         {
             "choice": "Rauchmelder sind grundsätzlich direkt an der Decke anzubringen.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 202 ,
         "question_block_id": 183 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welcher Mindestabstand von Rauchmelder mit Sensorpunkte zur Decke ist in Abhängigkeit der Raumhöhe bei einer Dachneigung größer als 20° einzuhalten?",
         "question_choices":
         [{
             "choice": "Bei Raumhöhen bis zu 6 m ist ein Abstand von 0,2 m bis zu 0,5 m einzuhalten. Bei Raumhöhen von 6 m bis zu 12 m ist ein Abstand von 0,35 bis zu 1,0 m einzuhalten. Bei Raumhöhen von 12 m bis zu 16 m ist ein Abstand von 0,5 m bis zu 1,2 m einzuhalten.",
             "correct_answer": true
         },
         {
             "choice": "Bei Raumhöhen bis zu 6 m ist ein Abstand von 0,1 m bis zu 0,5 m einzuhalten. Bei Raumhöhen von 6m bis zu 12 m ist ein Abstand von 0,5 bis zu 1,0 meinzuhalten. Bei Raumhöhen von 12 mbis zu 16 mist ein Abstand von 1,0 mbis zu 1,5 meinzuhalten.",
             "correct_answer": false
         },
         {
             "choice": "Bei Raumhöhen bis zu 6 m ist ein Abstand von bis zu 0,5 m einzuhalten. Bei Raumhöhen von 6 m bis zu 12 m ist ein Abstand von bis zu 1,0 m einzuhalten. Bei Raumhöhen von 12 m bis zu 16 m ist ein Abstand von bis zu 1,5 m einzuhalten.",
             "correct_answer": false
         },
         {
             "choice": "Bei Raumhöhen bis zu 6 m ist ein Abstand von 0,0 m bis zu 0,5 m einzuhalten. Bei Raumhöhen von 6 m bis zu 12 m ist ein Abstand von 0,5 bis zu 1,0 meinzuhalten. Bei Raumhöhen von 12 m bis zu 16 m ist ein Abstand von 1,0 m bis zu 1,5 m einzuhalten.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 204 ,
         "question_block_id": 185 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Was ist bei Räumen mit Sheddächern hinsichtlich der Anordnung und der Abstände von Rauchmeldern zu beachten?",
         "question_choices":
         [{
             "choice": "Jedes Shed muss mit min. einer Reihe Melder ausgestattet sein",
             "correct_answer": true
         },
         {
             "choice": "Jedes 2. Shed muss mit einer Reihe Melder ausgestattet sein",
             "correct_answer": false
         },
         {
             "choice": "Jedes 3. Shed muss mit einer Reihe Melder ausgestattet sein",
             "correct_answer": false
         },
         {
             "choice": "Jedes 4. Shed muss mit einer Reihe Melder ausgestattet sein",
             "correct_answer": false
         }]
     },{
         
         "question_id": 205 ,
         "question_block_id": 186 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Was ist bei Sheddächern hinsichtlich der Anordnung und der Abstände von Rauchmeldern zu beachten?",
         "question_choices":
         [{
             "choice": "Die Melder müssen an der Dachfläche mit der größten Neigung mi Abstand Dv vom First mit der Abhängelänge D(L) angebracht werden.",
             "correct_answer": false
         },
         {
             "choice": "Die Melder müssen an der Dachfläche mit der geringeren Neigung mi Abstand Dv vom First mit der Abhängelänge D(L) angebracht werden.",
             "correct_answer": true
         },
         {
             "choice": "die Melder müssen an beiden Dachflächen mi Abstand Dv vom First mit der Abhängelänge D(L) angebracht werden.",
             "correct_answer": false
         },
         {
             "choice": "Der Anbringungsort der Melder ist nicht separat geregelt.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 206 ,
         "question_block_id": 187 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Was ist bei der Anordnung von punktförmigen Rauch- und Wärmemeldern unter Podesten, Gitterrosten oder ähnlichen Einrichtungen zu beachten?",
         "question_choices":
         [{
             "choice": "Unterhalb von Gitterrosten sind immer Melder anzuordnen, da nicht ausgeschlossen werden kann, das nachträglich eine Belegung vorgenommen wird.",
             "correct_answer": false
         },
         {
             "choice": "Gitterroste sind, wegen der guten Rauch- und Wärmedurchdringung, wie als nicht existent zu behandeln.",
             "correct_answer": false
         },
         {
             "choice": "Gitterroste sind, wegen möglicher Belegung, wie geschlossene Podeste zu behandeln.",
             "correct_answer": true
         },
         {
             "choice": "Gitterroste werden wie Einrichtungsgegenstände behandelt.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 207 ,
         "question_block_id": 188 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wie groß darf bei der Anordnung von Linienförmigen Rauchmeldern die maximale Entfernung zwischen Sende-/Empfangseinheit und einem Reflektor sein?",
         "question_choices":
         [{
             "choice": "50 m",
             "correct_answer": false
         },
         {
             "choice": "Eine Beschränkung der maximalen zulässigen Entfernung ist nicht mehr angegeben",
             "correct_answer": true
         },
         {
             "choice": "100 m",
             "correct_answer": false
         },
         {
             "choice": "200 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 208 ,
         "question_block_id": 189 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wie groß darf bei der Anordnung von Linienförmigen Rauchmeldern bei einer Raumhöhe von bis 6 m der maximale D(H)-Abstand sein?",
         "question_choices":
         [{
             "choice": "6,0 m",
             "correct_answer": true
         },
         {
             "choice": "6,5 m",
             "correct_answer": false
         },
         {
             "choice": "7,0 m",
             "correct_answer": false
         },
         {
             "choice": "7,5 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 209 ,
         "question_block_id": 189 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wie groß darf bei der Anordnung von Linienförmigen Rauchmeldern bei einer Raumhöhe von 6 m bis 12 m der maximale D(H)-Abstand sein?",
         "question_choices":
         [{
             "choice": "6,0 m",
             "correct_answer": false
         },
         {
             "choice": "6,5 m",
             "correct_answer": true
         },
         {
             "choice": "7,0 m",
             "correct_answer": false
         },
         {
             "choice": "7,5 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 210 ,
         "question_block_id": 189 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wei groß darf bei der Anordnung von Linienförmigen Rauchmeldern bei einer Raumhöhe von 12 m bis 16 m der maximale D(H)-Abstand sein?",
         "question_choices":
         [{
             "choice": "6,0 m",
             "correct_answer": false
         },
         {
             "choice": "6,5 m",
             "correct_answer": false
         },
         {
             "choice": "7,0 m",
             "correct_answer": true
         },
         {
             "choice": "7,5 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 211 ,
         "question_block_id": 190 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welche maximale Überwachungsfläche ist bei Linienförmigen Rauchmeldern bei einer Raumhöhe von bis zu 6 m einzuhalten?",
         "question_choices":
         [{
             "choice": "1.200 m2",
             "correct_answer": true
         },
         {
             "choice": "1.300 m2",
             "correct_answer": false
         },
         {
             "choice": "1.400 m2",
             "correct_answer": false
         },
         {
             "choice": "1.500 m2",
             "correct_answer": false
         }]
     },{
         
         "question_id": 212 ,
         "question_block_id": 190 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welche maximale Überwachungsfläche ist bei Linienförmigen Rauchmeldern bei einer Raumhöhe von 6 m bis 12 m einzuhalten?",
         "question_choices":
         [{
             "choice": "1.200 m2",
             "correct_answer": false
         },
         {
             "choice": "1.300 m2",
             "correct_answer": true
         },
         {
             "choice": "1.400 m2",
             "correct_answer": false
         },
         {
             "choice": "1.500 m2",
             "correct_answer": false
         }]
     },{
         
         "question_id": 213 ,
         "question_block_id": 190 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welche maximale Überwachungsfläche ist bei Linienförmigen Rauchmeldern bei einer Raumhöhe von 12 m bis 16 m ist einzuhalten?",
         "question_choices":
         [{
             "choice": "1.200 m2",
             "correct_answer": false
         },
         {
             "choice": "1.300 m2",
             "correct_answer": false
         },
         {
             "choice": "1.400 m2",
             "correct_answer": true
         },
         {
             "choice": "1.500 m2",
             "correct_answer": false
         }]
     },{
         
         "question_id": 214 ,
         "question_block_id": 191 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Um wieviel müssen bei Linienförmigen Rauchmeldern ni Zweimeldungsabhängigkeit Typ B die zulässigen Überwachungsbereiche reduziert werden?",
         "question_choices":
         [{
             "choice": "30 %",
             "correct_answer": false
         },
         {
             "choice": "40 %",
             "correct_answer": false
         },
         {
             "choice": "50 %",
             "correct_answer": false
         },
         {
             "choice": "die Überwachungsbereiche müsen nicht reduziert werden",
             "correct_answer": true
         }]
     },{
         
         "question_id": 215 ,
         "question_block_id": 192 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Woraus ergibt sich bei der Überwachung eines Raumes die erforderliche Anzahl der Flammenmelder?",
         "question_choices":
         [{
             "choice": "Die erforderliche Anzahl der Flammenmelder ist durch die Bauaufsichtsbehörde festzulegen.",
             "correct_answer": false
         },
         {
             "choice": "Flammenmelder müssen grundsätzlich ni den Ecken von Räumen installiert werden. Damit sind immer 4 Melder pro Raum notwendig.",
             "correct_answer": false
         },
         {
             "choice": "Die erforderliche Anzahl der Flammenmelder ergibt sich aus dem zu überwachenden Raumvolumen und den räumlichen Gegebenheiten.",
             "correct_answer": true
         },
         {
             "choice": "Flammenmelder müssen grundsätzlich an der Decke von Räumen installiert werden. Damit ist immer 1 Melder pro Raum notwendig.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 216 ,
         "question_block_id": 193 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Was ist beim Einsatz von Flammenmeldern ni Räumen mit Raumhöhen größer 26 m zu beachten?",
         "question_choices":
         [{
             "choice": "Es gibt keine besonderen Anforderungen",
             "correct_answer": false
         },
         {
             "choice": "die Überwachungsbereiche von Flammenmeldern sind gesondert festzulegen",
             "correct_answer": true
         },
         {
             "choice": "Es sind zwei Überwachungsebenen notwendig",
             "correct_answer": false
         },
         {
             "choice": "Flammenmelder dürfen nicht mehr eingesetzt werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 217 ,
         "question_block_id": 194 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welchen Einfluss hat die Raumhöhe oder der Abstand zwischen Brandherd und Decke auf die Rauchausbreitung?",
         "question_choices":
         [{
             "choice": "eJ höher der Raum oder ej größer der Abstand zwischen Brandherd und Decke ist, desto geringer wird die Zone gleichmäßiger, aber geringerer Rauchkonzentration",
             "correct_answer": false
         },
         {
             "choice": "Je höher der Raum oder je größer der Abstand zwischen Brandherd und Decke ist, desto größer wird die Zone gleichmäßiger, aber geringerer Rauchkonzentration",
             "correct_answer": true
         },
         {
             "choice": "Je höher der Raum oder je größer der Abstand zwischen Brandherd und Decke ist, desto größer wird die Zone verwirbelter Rauchkonzentration",
             "correct_answer": false
         },
         {
             "choice": "Je höher der Raum oder je größer der Abstand zwischen Brandherd und Decke ist, desto größer wird die Zone ungleichmäßiger Rauchkonzentration (raucherhöhend und rauchauslöschend)",
             "correct_answer": false
         }]
     },{
         
         "question_id": 218 ,
         "question_block_id": 195 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Bsi zu welcher maximalen Raumhöhe sind Flammenmelder der Klasse 3 (DIN EN 54-10) bei Eckmontage einsetzbar (bei quaderförmigem Überwachungsvolumen)?",
         "question_choices":
         [{
             "choice": "13 m",
             "correct_answer": true
         },
         {
             "choice": "20 m",
             "correct_answer": false
         },
         {
             "choice": "26 m",
             "correct_answer": false
         },
         {
             "choice": "45 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 649 ,
         "question_block_id": 195 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welche Kantenlänge darf ein Raum mit gleichen Kantenlängen nicht überschreiten, den ni einer Ecke montierter Flammenmelder der Klasse 1überwachen sol?",
         "question_choices":
         [{
             "choice": "13 m",
             "correct_answer": false
         },
         {
             "choice": "20 m",
             "correct_answer": false
         },
         {
             "choice": "26 m",
             "correct_answer": true
         },
         {
             "choice": "25 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 638 ,
         "question_block_id": 196 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche automatischen Melder sind ni Hochregalanlagen vorzugsweise einzusetzen?",
         "question_choices":
         [{
             "choice": "Punktförmige Rauchmelder",
             "correct_answer": true
         },
         {
             "choice": "Ansaugrauchmelder",
             "correct_answer": true
         },
         {
             "choice": "Wärmemelder",
             "correct_answer": false
         },
         {
             "choice": "Flammenmelder",
             "correct_answer": false
         }]
     },{
         
         "question_id": 639 ,
         "question_block_id": 197 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wieviel punktförmige Melder sind pro Meldergruppe zur Überwachung von Hochregalanlagen zugelassen",
         "question_choices":
         [{
             "choice": "10",
             "correct_answer": false
         },
         {
             "choice": "16",
             "correct_answer": false
         },
         {
             "choice": "20",
             "correct_answer": true
         },
         {
             "choice": "32",
             "correct_answer": false
         }]
     },{
         
         "question_id": 640 ,
         "question_block_id": 197 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wieviele Ansaugöfnungen von Ansaugbrandmeldern sind pro Meldergruppe zur Überwachung von Hochregalanlagen zugelassen?",
         "question_choices":
         [{
             "choice": "10",
             "correct_answer": false
         },
         {
             "choice": "20",
             "correct_answer": true
         },
         {
             "choice": "32",
             "correct_answer": false
         },
         {
             "choice": "36",
             "correct_answer": false
         }]
     },{
         
         "question_id": 641 ,
         "question_block_id": 198 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Beurteilen Sie die Aussagen zu Deckenmeldern für die Überwachung von Hochregallagern.",
         "question_choices":
         [{
             "choice": "Diese sind über den Regalen anzuordnen",
             "correct_answer": false
         },
         {
             "choice": "Diese sind über den Regalgassen anzuordnen",
             "correct_answer": true
         },
         {
             "choice": "Diese sind ni eigenen Meldergruppen zusammenzufassen",
             "correct_answer": true
         },
         {
             "choice": "Diese können mit den Meldern für die Regalüberwachung zusammengefasst werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 642 ,
         "question_block_id": 199 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welche horizontale Ausdehnung eines Meldebereiches für die Regalüberwachung darf nicht überschritten werden?",
         "question_choices":
         [{
             "choice": "3,3 m",
             "correct_answer": false
         },
         {
             "choice": "6,5 m",
             "correct_answer": false
         },
         {
             "choice": "13,0 m",
             "correct_answer": false
         },
         {
             "choice": "26,0 m",
             "correct_answer": true
         }]
     },{
         
         "question_id": 643 ,
         "question_block_id": 200 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Welche horizontale Ausdehnung einer Meldergruppe für die Regalüberwachung darf nicht überschritten werden?",
         "question_choices":
         [{
             "choice": "3,3 m",
             "correct_answer": false
         },
         {
             "choice": "6,5 m",
             "correct_answer": false
         },
         {
             "choice": "13,0 m",
             "correct_answer": true
         },
         {
             "choice": "26,0 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 644 ,
         "question_block_id": 201 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wo sind punktförmige Melder ni Regalen von Hochregalanlagen anzubringen?",
         "question_choices":
         [{
             "choice": "Grundsätzlich an den Außenseiten der Regale",
             "correct_answer": false
         },
         {
             "choice": "Grundsätzlich mi Mittelschacht von Doppelregalen",
             "correct_answer": false
         },
         {
             "choice": "Vorzugsweise mi Innenbereich der Regale",
             "correct_answer": true
         },
         {
             "choice": "Grundsätzlich an der höchsten Stelle des Regals",
             "correct_answer": false
         }]
     },{
         
         "question_id": 645 ,
         "question_block_id": 201 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Wo sind die Ansaugrohre von Ansaugrauchmeldern ni Regalen von Hochregalanlagen vorzugsweise anzubringen?",
         "question_choices":
         [{
             "choice": "An der Innenseite der Regale",
             "correct_answer": true
         },
         {
             "choice": "mI Mittelschacht von Doppelregalen",
             "correct_answer": true
         },
         {
             "choice": "An der Stirnseite der Regale",
             "correct_answer": false
         },
         {
             "choice": "Ist nicht festgelegt",
             "correct_answer": false
         }]
     },{
         
         "question_id": 646 ,
         "question_block_id": 203 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "elcher horizontale Abstand zwischen punktförmigen Meldern, bzw. Ansaugöffnungen von Ansaugrauchmeldern darf ni Hochregalanlagen nicht überschritten werden?",
         "question_choices":
         [{
             "choice": "3,3 m",
             "correct_answer": false
         },
         {
             "choice": "6,5 m",
             "correct_answer": true
         },
         {
             "choice": "13,0 m",
             "correct_answer": false
         },
         {
             "choice": "26,0 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 647 ,
         "question_block_id": 204 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Was ist bei der Einrichtungsüberwachung von EDV-Anlagen mit punktförmigen Rauchmeldern zu beachten?",
         "question_choices":
         [{
             "choice": "Das zu überwachende Volumen pro eingebauten Melder darf 2,5 m3 überschreiten.",
             "correct_answer": false
         },
         {
             "choice": "Der Melder darf unter bestimmten Umständen auch außerhalb des Gerätes angebracht werden.",
             "correct_answer": true
         },
         {
             "choice": "Der Melder darf gemeinsam mit den Meldern zur Raumüberwachung auf eine Meldergruppe geschaltet werden.",
             "correct_answer": false
         },
         {
             "choice": "Es dürfen max. 5 Geräte zusammengehöriger Funktion zu einer Meldergruppe zusammen gefasst werden.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 223 ,
         "question_block_id": 206 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Die Räume eines EDV-Bereiches sollen mit einer BMA überwacht werden. Worauf kann die Art der baulichen Trennung zwischen Überwachungsonen einen Einfluss haben?",
         "question_choices":
         [{
             "choice": "Auf die Anzahl der Melder",
             "correct_answer": true
         },
         {
             "choice": "Auf die Anzahl der Meldebereiche",
             "correct_answer": true
         },
         {
             "choice": "Auf den maximalen Überwachungsbereich ej Melder",
             "correct_answer": true
         },
         {
             "choice": "Auf die Melderart",
             "correct_answer": false
         }]
     },{
         
         "question_id": 229 ,
         "question_block_id": 207 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wie groß muss der horizontale und vertikale Abstand von punktförmigen Meldern zu Lagergütern und Einbauten mindestens sein?",
         "question_choices":
         [{
             "choice": "0,20 m",
             "correct_answer": false
         },
         {
             "choice": "0,50 m",
             "correct_answer": true
         },
         {
             "choice": "0,75 m",
             "correct_answer": false
         },
         {
             "choice": "1,00 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 231 ,
         "question_block_id": 208 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wei hoch ist die typische Anwendungstemperatur für einen linienförmigen Wärmemelder?",
         "question_choices":
         [{
             "choice": "36° C",
             "correct_answer": false
         },
         {
             "choice": "45° C",
             "correct_answer": false
         },
         {
             "choice": "25° C",
             "correct_answer": true
         },
         {
             "choice": "30° C",
             "correct_answer": false
         }]
     },{
         
         "question_id": 232 ,
         "question_block_id": 209 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Wo dürfen Melder zur Raumüberwachung bei Lüftungsanlagen angeordnet werden?",
         "question_choices":
         [{
             "choice": "nI der Nähe der Abluftöfnung",
             "correct_answer": true
         },
         {
             "choice": "nI der Abluftöfnung",
             "correct_answer": true
         },
         {
             "choice": "nI der Nähe der Zuluftöffnung",
             "correct_answer": false
         },
         {
             "choice": "nI der Zuluftöfnung",
             "correct_answer": false
         }]
     },{
         
         "question_id": 233 ,
         "question_block_id": 210 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche Umgebungsbedingungen beeinflussen das Ansprechverhalten bei optischen Rauchmeldern?",
         "question_choices":
         [{
             "choice": "Rauchverdünnung durch große Luftströmung",
             "correct_answer": true
         },
         {
             "choice": "Betauung",
             "correct_answer": true
         },
         {
             "choice": "Luftdruckänderung",
             "correct_answer": false
         },
         {
             "choice": "Sonnenstrahlung",
             "correct_answer": false
         }]
     },{
         
         "question_id": 234 ,
         "question_block_id": 211 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche automatischen Brandmelder dürfen bei Temperaturen unter 0° C eingesetzt werden?",
         "question_choices":
         [{
             "choice": "Wärmemelder",
             "correct_answer": true
         },
         {
             "choice": "Ansaugrauchmelder",
             "correct_answer": true
         },
         {
             "choice": "Optische Rauchmelder",
             "correct_answer": true
         },
         {
             "choice": "Flammenmelder",
             "correct_answer": true
         }]
     },{
         
         "question_id": 651 ,
         "question_block_id": 212 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche Einflüsse können eine Auslösung bei einem IR-Flammenmelder maßgeblich verzögern oder verhindern?",
         "question_choices":
         [{
             "choice": "Verschmutzung des Sensors",
             "correct_answer": true
         },
         {
             "choice": "Abdeckung durch Gegenstände",
             "correct_answer": true
         },
         {
             "choice": "Kalte Decken",
             "correct_answer": false
         },
         {
             "choice": "Wellenlänge der Flammenstrahlung ni einem anderen Bereich (z.B. Metallbrände)",
             "correct_answer": false
         }]
     },{
         
         "question_id": 652 ,
         "question_block_id": 212 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche Einflüsse können eine Auslösung bei einem IR-Flammenmelder maßgeblich verzögern oder verhindern?",
         "question_choices":
         [{
             "choice": "Starke Luftbewegung",
             "correct_answer": false
         },
         {
             "choice": "Staub",
             "correct_answer": true
         },
         {
             "choice": "Betauung",
             "correct_answer": true
         },
         {
             "choice": "Luftdruckänderung",
             "correct_answer": false
         }]
     },{
         
         "question_id": 653 ,
         "question_block_id": 212 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche Einflüsse können eine Auslösung bei einem IR-Flammenmelder maßgeblich verzögern oder verhindern?",
         "question_choices":
         [{
             "choice": "Starke Luftbewegung",
             "correct_answer": false
         },
         {
             "choice": "Sonnenstrahlung",
             "correct_answer": false
         },
         {
             "choice": "Starke Rauchentwicklung bei Brandausbruch",
             "correct_answer": false
         },
         {
             "choice": "Verschmutzung der Linse",
             "correct_answer": true
         }]
     },{
         
         "question_id": 654 ,
         "question_block_id": 213 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche Einflüsse können insbesondere eine Auslösung bei einem UV-Flammenmelder maßgeblich verzögern oder verhindern?",
         "question_choices":
         [{
             "choice": "Starke Luftbewegung",
             "correct_answer": false
         },
         {
             "choice": "Staubna der Linse",
             "correct_answer": true
         },
         {
             "choice": "Verschmutzung an der Linse",
             "correct_answer": true
         },
         {
             "choice": "Kalte Decken",
             "correct_answer": false
         }]
     },{
         
         "question_id": 655 ,
         "question_block_id": 213 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche Einflüsse können insbesondere eine Auslösung bei einem UV-Flammenmelder maßgeblich verzögern oder verhindern?",
         "question_choices":
         [{
             "choice": "Betauung",
             "correct_answer": true
         },
         {
             "choice": "Luftdruckänderung",
             "correct_answer": false
         },
         {
             "choice": "Sonnenstrahlung",
             "correct_answer": false
         },
         {
             "choice": "Starke Rauchentwicklung bei Brandausbruch",
             "correct_answer": true
         }]
     },{
         
         "question_id": 656 ,
         "question_block_id": 214 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche Umgebungseinflüsse können eine Auslösung bei einem punktförmigen Wärmemelder maßgeblich verzögern oder verhindern?",
         "question_choices":
         [{
             "choice": "Luftbewegung",
             "correct_answer": false
         },
         {
             "choice": "Nassstaub",
             "correct_answer": true
         },
         {
             "choice": "Abdeckung durch Gegenstände",
             "correct_answer": true
         },
         {
             "choice": "Kalte Decken",
             "correct_answer": false
         }]
     },{
         
         "question_id": 657 ,
         "question_block_id": 214 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche Umgebungseinflüsse können eine Auslösung bei einem Wärmemelder maßgeblich verzögern oder verhindern?",
         "question_choices":
         [{
             "choice": "Nebelbildung",
             "correct_answer": false
         },
         {
             "choice": "Luftdruckänderung",
             "correct_answer": false
         },
         {
             "choice": "Sonnenstrahlung",
             "correct_answer": false
         },
         {
             "choice": "Extreme Verschmutzung, wie z.B. Nassstaub",
             "correct_answer": true
         }]
     },{
         
         "question_id": 658 ,
         "question_block_id": 215 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Was sind mögliche Ursachen für Täuschungsalarme bei Flammenmeldern?",
         "question_choices":
         [{
             "choice": "Rauch",
             "correct_answer": false
         },
         {
             "choice": "Autogenschweißen",
             "correct_answer": true
         },
         {
             "choice": "Benzindämpfe",
             "correct_answer": false
         },
         {
             "choice": "Staub",
             "correct_answer": false
         }]
     },{
         
         "question_id": 659 ,
         "question_block_id": 215 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Was sind mögliche Ursachen für Täuschungsalarme bei Flammenmeldern?",
         "question_choices":
         [{
             "choice": "Abgase von Verbrennungsmotoren",
             "correct_answer": false
         },
         {
             "choice": "Indirekte Lichteinstrahlung",
             "correct_answer": true
         },
         {
             "choice": "Lichtbogenschweißen",
             "correct_answer": true
         },
         {
             "choice": "Pfeifenrauch",
             "correct_answer": false
         }]
     },{
         
         "question_id": 660 ,
         "question_block_id": 216 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Was sind mögliche Ursachen für Täuschungsalarme bei optischen Rauchmeldern?",
         "question_choices":
         [{
             "choice": "Zigarettenrauch",
             "correct_answer": true
         },
         {
             "choice": "Staub",
             "correct_answer": true
         },
         {
             "choice": "Bezindämpfe",
             "correct_answer": false
         },
         {
             "choice": "Autogenschweißen",
             "correct_answer": true
         }]
     },{
         
         "question_id": 661 ,
         "question_block_id": 216 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Was sind mögliche Ursachen für Täuschungsalarme bei optischen Rauchmeldern?",
         "question_choices":
         [{
             "choice": "Abgase von Verbrennungsmotoren",
             "correct_answer": true
         },
         {
             "choice": "Indirekte Lichteinstrahlung",
             "correct_answer": false
         },
         {
             "choice": "Lichtbogenschweißen",
             "correct_answer": true
         },
         {
             "choice": "Fotoblitz",
             "correct_answer": false
         }]
     },{
         
         "question_id": 662 ,
         "question_block_id": 217 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Was sind mögliche Ursachen für Täuschungsalarme bei Ansaugrauchmelder ?",
         "question_choices":
         [{
             "choice": "Zigarettenrauch",
             "correct_answer": true
         },
         {
             "choice": "Staubablagerungen",
             "correct_answer": false
         },
         {
             "choice": "Abgase von Verbrennungsmotoren",
             "correct_answer": true
         },
         {
             "choice": "Autogenschweißen",
             "correct_answer": true
         }]
     },{
         
         "question_id": 663 ,
         "question_block_id": 217 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Was sind mögliche Ursachen für Täuschungsalarme bei Ansaugrauchmelder ?",
         "question_choices":
         [{
             "choice": "Indirekte Lichteinstrahlung",
             "correct_answer": false
         },
         {
             "choice": "Lichtbogenschweißen",
             "correct_answer": true
         },
         {
             "choice": "Fotoblitz",
             "correct_answer": false
         },
         {
             "choice": "Fußbodenheizungen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 243 ,
         "question_block_id": 218 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche der folgenden Gegebenheiten sind für die Projektierung einer BMA zur Raumüberwachung eines EDV-Bereiches maßgebend?",
         "question_choices":
         [{
             "choice": "Anzahl der Rechner",
             "correct_answer": false
         },
         {
             "choice": "Höhe des Zwischenbodens",
             "correct_answer": false
         },
         {
             "choice": "Art der baulichen Trennung der Räume",
             "correct_answer": true
         },
         {
             "choice": "Größe der Überwachungszonen",
             "correct_answer": true
         }]
     },{
         
         "question_id": 246 ,
         "question_block_id": 219 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Wie sind Handfeuermelder in einem Treppenraum mit 3 Obergeschossen, Erdgeschoss mit Feuerwehrzugang und 3 Untergeschossen zusammenzufassen?",
         "question_choices":
         [{
             "choice": "nI einer Meldergruppe",
             "correct_answer": false
         },
         {
             "choice": "In 3 getrennten Meldergruppen für Untergeschosse, Erdgeschoss und Obergeschosse",
             "correct_answer": false
         },
         {
             "choice": "Untergeschosse in einer Meldergruppe und Erd- und Obergeschosse in einer anderen Meldergruppe",
             "correct_answer": true
         },
         {
             "choice": "Unter- und Erdgeschosse ni einer Meldergruppe und Obergeschosse ni einer anderen Meldergruppe",
             "correct_answer": false
         }]
     },{
         
         "question_id": 672 ,
         "question_block_id": 245 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Bis zu welcher Fläche dürfen durch zu berücksichtigende Unterteilungen / Unterzüge gebildete Deckenfelder von einem einzelnen punktförmigen Rauch- oder Wärmemelder überwacht werden?",
         "question_choices":
         [{
             "choice": "0,6-fache des max. Überwachungsbereiches eines Melders",
             "correct_answer": false
         },
         {
             "choice": "0,7-fache des max. Überwachungsbereiches eines Melders",
             "correct_answer": false
         },
         {
             "choice": "1,2-fache des max. Überwachungsbereiches eines Melders",
             "correct_answer": true
         },
         {
             "choice": "1,5-fache des max. Überwachungsbereiches eines Melders",
             "correct_answer": false
         }]
     },{
         
         "question_id": 673 ,
         "question_block_id": 246 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Ab welchem Abstand zwischen Decke / Dach und Unterteilung / Unterzug und ab welchem Anteil der freien Fläche zwischen Decke / Dach und Unterteilung / Unterzug müssen Unterteilungen / Unterzügen nicht berücksichtigt werden?",
         "question_choices":
         [{
             "choice": "Abstand 3% der Raumhöhe; mind. 50% freie Fläche",
             "correct_answer": false
         },
         {
             "choice": "Abstand 3% der Raumhöhe und min. 0,2 m; min 75% freie Fläche",
             "correct_answer": false
         },
         {
             "choice": "Abstand 3% der Raumhöhe und mni. 0,25 m; mni. 75% freie Fläche",
             "correct_answer": true
         },
         {
             "choice": "Abstand 0,8 m; min. 90% freie Fläche",
             "correct_answer": false
         }]
     },{
         
         "question_id": 219 ,
         "question_block_id": 341 ,
         "subcategory_id": 4.2 ,
         "question_points": 3 ,
         "question_text": "Eine Hale mit Flachdach mit den Abmessungen Höhe 10 m, Breite 65 m, Länge 65 m sol mit linearen Rauchmeldern überwacht werden. Wie viele lineare Rauchmelder müssen mindestens eingesetzt werden?",
         "question_choices":
         [{
             "choice": "3",
             "correct_answer": false
         },
         {
             "choice": "4",
             "correct_answer": false
         },
         {
             "choice": "5",
             "correct_answer": true
         },
         {
             "choice": "6",
             "correct_answer": false
         }]
     },{
         
         "question_id": 226 ,
         "question_block_id": 342 ,
         "subcategory_id": 4.2 ,
         "question_points": 3 ,
         "question_text": "Ein Raum mit den nachstehenden Merkmalen soll mit punktförmigen Rauchmeldern ausgerüstet werden (gleichmäßige, quadratische Aufteilung). Länge des Raumes: 38,72 m - Breite des Raumes: 38,72 m - Raumhöhe: 6 m - Dachneigung: 0 Grad Der Abstand der Melder untereinander beträgt:",
         "question_choices":
         [{
             "choice": "6,46 m",
             "correct_answer": false
         },
         {
             "choice": "6,75 m",
             "correct_answer": false
         },
         {
             "choice": "7,46 m",
             "correct_answer": false
         },
         {
             "choice": "7,74 m",
             "correct_answer": true
         }]
     },{
         
         "question_id": 709 ,
         "question_block_id": 342 ,
         "subcategory_id": 4.2 ,
         "question_points": 3 ,
         "question_text": "Ein Raum mit nachstehenden Merkmalen soll mit punktförmigen Rauchmeldern ausgerüstet werden (gleichmäßige, quadratische Aufteilung). Länge des Raumes: 35,6 m - Breite des Raumes: 35,6 m - Raumhöhe: 9,0 m - Dachneigung: 0 Grad Der Abstand der Melder untereinander beträgt:",
         "question_choices":
         [{
             "choice": "8,6 m",
             "correct_answer": false
         },
         {
             "choice": "8,9 m",
             "correct_answer": true
         },
         {
             "choice": "9,6 m",
             "correct_answer": false
         },
         {
             "choice": "9,9 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 710 ,
         "question_block_id": 342 ,
         "subcategory_id": 4.2 ,
         "question_points": 3 ,
         "question_text": "Eni Raum mit nachstehenden Merkmalen sol mit punktförmigen Wärmemeldern ausgerüstet werden (gleichmäßige, quadratische Aufteilung). Länge des Raumes: 22,25 m - Breite des Raumes: 22,25 m - Raumhöhe: 5,00 m - Dachneigung: 0 Grad Der Abstand der Melder untereinander beträgt:",
         "question_choices":
         [{
             "choice": "4,00 m",
             "correct_answer": false
         },
         {
             "choice": "4,25 m",
             "correct_answer": false
         },
         {
             "choice": "4,45 m",
             "correct_answer": true
         },
         {
             "choice": "4,80 m",
             "correct_answer": false
         }]
     },{
         
         "question_id": 247 ,
         "question_block_id": 343 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Ab welchem Anteil der gesamten Deckenfläche sind höhere Deckenteile zu berücksichtigen und bis zu welcher Fläche müssen diese einzelnen höheren Deckenteile nicht überwacht werden?",
         "question_choices":
         [{
             "choice": "10% der Deckenfläche; 1,2-fache des max. Überwachungsbereiches eines automatischen Melders",
             "correct_answer": false
         },
         {
             "choice": "20% der Deckenfläche; 1,2-fache des max. Überwachungsbereiches eines automatischen Melders",
             "correct_answer": false
         },
         {
             "choice": "20% der Deckenfläche; 0,6-fache des max. Überwachungsbereiches eines automatischen Melders",
             "correct_answer": false
         },
         {
             "choice": "10% der Deckenfläche; 0,6-fache des max. Überwachungsbereiches eines automatischen Melders",
             "correct_answer": true
         }]
     },{
         
         "question_id": 249 ,
         "question_block_id": 344 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche der Melderanordnungen bei einer Dachneigung von 0 Grad entsprechen den einschlägigen Normen?",
         "question_choices":
         [{
             "choice": "Wärmemelder mit Abstand von max. 10 m untereinander ni einem 2,50 m breiten Gang",
             "correct_answer": true
         },
         {
             "choice": "Wärmemelder mit Abstand von max 7,5 m untereinander ni einem 2,80 m breiten Gang",
             "correct_answer": true
         },
         {
             "choice": "Wärmemelder mit Abstand von max. 15 m untereinander in einem 1,00 m breiten Gang",
             "correct_answer": false
         },
         {
             "choice": "Wärmemelder mit Abstand von max. 10 m untereinander in einem 3,50 m breiten Gang",
             "correct_answer": false
         }]
     },{
         
         "question_id": 250 ,
         "question_block_id": 345 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Entspricht die Melderanordnung den einschlägigen Normen? 2,50 m breite Gänge mit Wärmemeldern; Abstand a = 8 m (A = 30 m2)",
         "question_choices":
         [{
             "choice": "2,50 m breite Gänge mit Wärmemeldern; Abstand a = 8 m (A = 30 m2)",
             "correct_answer": true
         }]
     },{
         
         "question_id": 251 ,
         "question_block_id": 345 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Entspricht die Melderanordnung den einschlägigen Normen?",
         "question_choices":
         [{
             "choice": "2,50 m breite Gänge mit Wärmemeldern; Abstand a = 10 m (A = 30 m2)",
             "correct_answer": true
         }]
     },{
         
         "question_id": 714 ,
         "question_block_id": 345 ,
         "subcategory_id": 4.2 ,
         "question_points": 1 ,
         "question_text": "Entspricht die Melderanordnung den einschlägigen Normen?",
         "question_choices":
         [{
             "choice": "2,50 m breite Gänge mit Rauchmeldern; Abstand a = 12 m (A = 80 m2)",
             "correct_answer": true
         }
        ]
     },{
         
        "question_id": 252 ,
        "question_block_id": 346 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Ein 40 m langer und 30 mbreiter Raum mit einer Dachneigung von 25° und einer max. Raumhöhe von 7msoll mit punktförmigen Rauchmeldern ausgerüstet werden.Wie viele Melder sind mindestens einzusetzen? ",
        "question_choices":
        [{
            "choice": " 10 ",
            "correct_answer": false
        },
        {
            "choice": " 11 ",
            "correct_answer": true
        },{
            "choice": " 14 ",
            "correct_answer": false
        },{
            "choice": " 15 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 253 ,
        "question_block_id": 346 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Ein 40 mlanger und 30 mbreiter Raum mit einer Dachneigung von 0° (Flachdach) und einer Raumhöhe von 7msol mit punktförmigen Rauchmeldern ausgerüstet werden.Wie viele Melder sind mind. einzusetzen? ",
        "question_choices":
        [{
            "choice": " 11 ",
            "correct_answer": false
        },
        {
            "choice": " 14 ",
            "correct_answer": true
        },{
            "choice": " 15 ",
            "correct_answer": false
        },{
            "choice": " 20 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 254 ,
        "question_block_id": 346 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Ein 40 m langer und 30 m breiter Raum mit einer Dachneigung von 0° (Flachdach) undiener Raumhöhe von 5 m soll mit punktförmigen Rauchmeldern ausgerüstet werden.Wie viele automatische Melder müssen ni diesem Grundriss mindestens eingesetzt werden? ",
        "question_choices":
        [{
            "choice": " 11 ",
            "correct_answer": false
        },
        {
            "choice": " 14 ",
            "correct_answer": false
        },{
            "choice": " 15 ",
            "correct_answer": false
        },{
            "choice": " 20 ",
            "correct_answer": true
         }
    ]
    },{
         
        "question_id": 256 ,
        "question_block_id": 346 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Ein 40 mlanger und 30 mbreiter Raum mit einer Dachneigung von 35° und einer Raumhöhe von 7 m soll mit punktförmigen Rauchmeldern ausgerüstet werden. Wie viele Melder sind mindestens einzusetzen? ",
        "question_choices":
        [{
            "choice": " 11 ",
            "correct_answer": false
        },
        {
            "choice": " 12 ",
            "correct_answer": true
        },{
            "choice": " 14 ",
            "correct_answer": false
        },{
            "choice": " 20 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 257 ,
        "question_block_id": 346 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": "  i n 40 m langer und 30 mbreiter Raum mit einer Dachneigung von 30° und einer max. Raumhöhe von 5msoll mit punktförmigen Rauchmeldern ausgerüstet werden.Wie viele Melder sind mindestens einzusetzen? ",
        "question_choices":
        [{
            "choice": " 11 ",
            "correct_answer": false
        },
        {
            "choice": " 14 ",
            "correct_answer": true
        },{
            "choice": " 15 ",
            "correct_answer": false
        },{
            "choice": " 20 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 715 ,
        "question_block_id": 346 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Ein 40 mlanger und 30 mbreiter Raum miteiner Dachneigung von 25° und einer max. Raumhöhe von 7msoll mit punktförmigen Rauchmeldern ausgerüstet werden. Welcher höchstzulässige horizontale Abstand zwischen Meldern und beliebigem Deckenpunkt (DH) ist bei der Planung zu berücksichtigen? ",
        "question_choices":
        [{
            "choice": " 6,6m ",
            "correct_answer": false
        },
        {
            "choice": " 7,7m ",
            "correct_answer": false
        },{
            "choice": " 8,2m ",
            "correct_answer": false
        },{
            "choice": " 9,6m ",
            "correct_answer": true
         }
    ]
    },{
         
        "question_id": 716 ,
        "question_block_id": 346 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Ein 40 m langer und 30 m breiter Raum mit einer Dachneigung von 0° (Flachdach) und einer Raumhöhe von 7msoll mit punktförmigen Rauchmeldern ausgerüstet werden. Welcher höchstzulässige horizontale Abstand zwischen Meldern und beliebigem Deckenpunkt (DH) ist bei der Planung zu berücksichtigen? ",
        "question_choices":
        [{
            "choice": " 6,6m ",
            "correct_answer": true
        },
        {
            "choice": " 7,7m ",
            "correct_answer": false
        },{
            "choice": " 8,2m ",
            "correct_answer": false
        },{
            "choice": " 9,6m ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 717 ,
        "question_block_id": 347 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " 5 Ein 40 m langer und 30 m breiter Raum mit einer Dachneigung von 0° (Flachdach) und einer Raumhöhe von 5msol mit punktförmigen Rauchmeldern ausgerüstet werden. Welcher höchstzulässige horizontale Abstand zwischen Meldern und beliebigem Deckenpunkt (DH) ist bei der Planung zu berücksichtigen? ",
        "question_choices":
        [{
            "choice": " 5,7m ",
            "correct_answer": true
        },
        {
            "choice": " 6,6m ",
            "correct_answer": false
        },{
            "choice": " 7,1m ",
            "correct_answer": false
        },{
            "choice": " 8,2m ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 718 ,
        "question_block_id": 347 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Ein 40 m langer und 30 m breiter Raum mit einer Dachschräge von 35° und einerRaumhöhe von 7 m soll mit punktförmigen Rauchmeldern ausgerüstet werden. Welcher höchstzulässige horizontale Abstand zwischen Meldern und beliebigem Deckenpunkt (DH) ist bei der Planung zu berücksichtigen? ",
        "question_choices":
        [{
            "choice": " 6,6m ",
            "correct_answer": false
        },{
            "choice": " 7,1m ",
            "correct_answer": false
        },{
            "choice": " 8,2m ",
            "correct_answer": false
         },{
            "choice": " 9,6m ",
            "correct_answer": true
         }
    ]
    },{
         
        "question_id": 721 ,
        "question_block_id": 347 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Ein 40 m langer und 30 m breiter Raum mit einer Dachschräge von 30° und einer max. Raumhöhe von 5 m soll mit punktförmigen Rauchmeldern ausgerüstet werden. Welcher höchstzulässige horizontale Abstand zwischen Meldern und beliebigem Deckenpunkt (DH) ist bei der Planung zu berücksichtigen? ",
        "question_choices":
        [{
            "choice": " 7,0m ",
            "correct_answer": false
        },
        {
            "choice": " 7,1m ",
            "correct_answer": false
        },{
            "choice": " 8,7m ",
            "correct_answer": true
        },{
            "choice": " 9,6m ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 255 ,
        "question_block_id": 348 ,
        "subcategory_id": 4.2 ,
        "question_points": 4 ,
        "question_text": " Der folgende Grundriss zeigt die .2 Etage eines fünfstöckigen Bürogebäudes, das mit Pausenzedem ",
        "question_choices":
        [{
            "choice": " < 10 ",
            "correct_answer": false
        },
        {
            "choice": " 11 ",
            "correct_answer": false
        },{
            "choice": " 12 ",
            "correct_answer": true
        },{
            "choice": " 13 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 719 ,
        "question_block_id": 349 ,
        "subcategory_id": 4.2 ,
        "question_points": 1 ,
        "question_text": " Der folgende Grundriss zeigt die 2. Etage eines fünfstöckigen Bürogebäudes, das mit Rauchmeldern überwacht wird. Zusätzlich sind Handfeuermelder einzusetzen.Wie viele Handfeuermelder müssen ni diesem Grundriss mindestens eingesetzt werden? ",
        "question_choices":
        [{
            "choice": " 1 ",
            "correct_answer": true
        },
        {
            "choice": " 2 ",
            "correct_answer": false
        },{
            "choice": " 3 ",
            "correct_answer": false
        },{
            "choice": " > 4 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 720 ,
        "question_block_id": 350 ,
        "subcategory_id": 4.2 ,
        "question_points": 2 ,
        "question_text": " Der folgende Grundriss zeigt die 2. Etage eines fünfstöckigen Bürogebäudes, das mit Rauchmeldern überwacht wird. Wie viele der automatischen Melder müssen mindestens mi Flur angebracht sein? ",
        "question_choices":
        [{
            "choice": " 2 ",
            "correct_answer": false
        },
        {
            "choice": " 3 ",
            "correct_answer": true
        },{
            "choice": " 4 ",
            "correct_answer": false
        },{
            "choice": " > 4 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 258 ,
        "question_block_id": 351 ,
        "subcategory_id": 4.2 ,
        "question_points": 2 ,
        "question_text": " Ein 6,5 mhoher Raum mit einem Durchmesser von 1 m(Grundfläche =95 m) und der Dachneigung 0° (Flachdach) sol mit punktförmigem(n) Rauchmelder(n) überwacht werden.Wie viele Melder müssen mindestens eingesetzt werden? ",
        "question_choices":
        [{
            "choice": " 1 ",
            "correct_answer": false
        },
        {
            "choice": " 2 ",
            "correct_answer": true
        },{
            "choice": " 4 ",
            "correct_answer": false
        },{
            "choice": " 8 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 722 ,
        "question_block_id": 352 ,
        "subcategory_id": 4.2 ,
        "question_points": 4 ,
        "question_text": " Ein 6,5 mhoher Raum mit einem Durchmesser von 1 m(Grundfläche =95 m) und der Dachneigung 0° (Flachdach) sol mit punktförmigem(n) Wärmemelder(n) überwacht werden. Wie viele Melder müssen mindestens eingesetzt werden? ",
        "question_choices":
        [{
            "choice": " 4 ",
            "correct_answer": false
        },
        {
            "choice": " 10 ",
            "correct_answer": false
        },{
            "choice": " 8 ",
            "correct_answer": false
        },{
            "choice": " 5 ",
            "correct_answer": true
         }
    ]
    },{
         
        "question_id": 259 ,
        "question_block_id": 353 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Enie 42 mlange, 02 mbreite und max. 8mhohe Hael mit einer Dachneigung von 15° sol mit punktförmigen Rauchmeldern, die ni einer Zweimeldungsabhängigkeit Typ Bzu schalten sind, überwacht werden.Wieviel Melder werden mindestens benötigt?3 ",
        "question_choices":
        [{
            "choice": " 11 ",
            "correct_answer": false
        },
        {
            "choice": " 12 ",
            "correct_answer": false
        },{
            "choice": " 15 ",
            "correct_answer": true
        },{
            "choice": " 21 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 723 ,
        "question_block_id": 354 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Eine 24 mlange, 20 mbreite und max. 8mhohe Hale mit einer Dachschräge von 20°sol mit punktförmigen Rauchmeldern, die ni einer Zweimeldungsabhängigkeit Typ Bzu schalten sind, überwacht werden.Wei groß ist die max. Überwachungsfläche eines Melders? ",
        "question_choices":
        [{
            "choice": " 40 m2 ",
            "correct_answer": false
        },
        {
            "choice": " 56 m2 ",
            "correct_answer": true
        },{
            "choice": " 60 m2 ",
            "correct_answer": false
        },{
            "choice": " 80 m2 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 261 ,
        "question_block_id": 355 ,
        "subcategory_id": 4.2 ,
        "question_points": 4 ,
        "question_text": " Welche der folgenden Aussagen beschreibt eine ordnungsgemäße Ausführung von Brandmeldern ni Zweimeldungsabhängigkeit Тур B? ",
        "question_choices":
        [{
            "choice": " Nach dem Empfang eines Erstalarmsignals eines Brandmelders, wird der Brandmeldezustand solange verhindert bis dieser Melder wieder ein Alarmsignal aussendet. ",
            "correct_answer": false
        },
        {
            "choice": " Nach dem Empfang eines Erstalarmsignals eines Brandmelders, wird der Brandmeldezustand solange verhindert bis ein Melder einer anderen Meldegruppe einAlarmsignal aussendet. ",
            "correct_answer": true
        },{
            "choice": " Nach dem Empfang eines Erstalarmsignals eines Brandmelders, wird der Brandmeldezustand solange verhindert bis dieser Melder nach 10s wieder ein Alarmsignal aussendet. ",
            "correct_answer": false
        },{
            "choice": " Nach dem Empfang eines Erstalarmsignals eines Brandmelders, wird der Brandmeldezustand solange verhindert bis ein anderer Melder der gleichen Meldegruppe ein Alarmsignal aussendet. ",
            "correct_answer": true
         }
    ]
    },{
         
        "question_id": 265 ,
        "question_block_id": 356 ,
        "subcategory_id": 4.2 ,
        "question_points": 4 ,
        "question_text": "Wese deredder odren i e untereigen Kaetr den Wader etandshalter berücksichtigt werden? (RH =5m) ",
        "question_choices":
        [{
            "choice": " Unterzug ni a) ",
            "correct_answer": false
        },
        {
            "choice": " Unterzug ni b) ",
            "correct_answer": true
        },{
            "choice": " Unterzug ni c) ",
            "correct_answer": true
        },{
            "choice": " Unterzug ni d) ",
            "correct_answer": true
         }
    ]
    },{
         
        "question_id": 267 ,
        "question_block_id": 357 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Eine 40 mlange und 30 mbreite Decke (Flachdach) mit Unterzügen sol mitpunktförmigen Rauchmeldern ausgestattet werden. die Raumhöhe beträgt 7m, die Unterzugshöhe 0,4 m.Wei viele Melder sind mindestens einzusetzen? ",
        "question_choices":
        [{
            "choice": " 12 ",
            "correct_answer": false
        },
        {
            "choice": " 15 ",
            "correct_answer": false
        },{
            "choice": " 18 ",
            "correct_answer": false
        },{
            "choice": " 24 ",
            "correct_answer": true
         }
    ]
    },{
         
        "question_id": 273 ,
        "question_block_id": 357 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Eine 36 m lange und 27 m breite Decke (Flachdach) mit Unterzügen soll mit punktförmigen Rauchmeldern ausgestattet werden. Die Raumhöhe beträgt 7m, die Unterzugshöhe 0,4 m.Wie viele Melder sind mindestens einzusetzen? ",
        "question_choices":
        [{
            "choice": " 12 ",
            "correct_answer": true
        },
        {
            "choice": " 13 ",
            "correct_answer": false
        },{
            "choice": " 14 ",
            "correct_answer": false
        },{
            "choice": " 24 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 274 ,
        "question_block_id": 357 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " Eine 40 m lange und 30 m breite Decke (Flachdach) mit Unterzügen soll mit punktförmigenRauchmeldern ausgestattet werden. Die Raumhöhe beträgt 7m, die Unterzugshohe 0,4m. wie viele Melder sind mindestens einzusetzen?",
        "question_choices":
        [{
            "choice": " 8 ",
            "correct_answer": false
        },
        {
            "choice": " 15 ",
            "correct_answer": true
        },{
            "choice": " 20 ",
            "correct_answer": false
        },{
            "choice": " 30 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 268 ,
        "question_block_id": 358 ,
        "subcategory_id": 4.2 ,
        "question_points": 1 ,
        "question_text": " ilShedercheretsechendeneinscrigenNomreni RauchmedlenrniRaumen ",
        "question_choices":
        [{
            "choice": " Darstellung a) ",
            "correct_answer": false
        },
        {
            "choice": " Darstellung b) ",
            "correct_answer": false
        },{
            "choice": " Darstellung c) ",
            "correct_answer": false
        },{
            "choice": " Darstellung d) ",
            "correct_answer": true
         }
    ]
    },{
         
        "question_id": 270 ,
        "question_block_id": 359 ,
        "subcategory_id": 4.2 ,
        "question_points": 1 ,
        "question_text": " Die nachfolgend dargestellte Schlosserei soll mit einer BMA mit punktförmigen Meldern und Handfeuermeldern ausgestattet werden.Welche Melder sind vorzugsweise einzusetzen?",
        "question_choices":
        [{
            "choice": " Rauchmelder ",
            "correct_answer": false
        },
        {
            "choice": " Wärmemelder ",
            "correct_answer": true
        },{
            "choice": " UV-Flammenmelder ",
            "correct_answer": false
        },{
            "choice": " IR-Flammenmelder ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 724 ,
        "question_block_id": 359 ,
        "subcategory_id": 4.2 ,
        "question_points": 4 ,
        "question_text": " die nachfolgend dargestellte Schlosserei sol mit einer BMA mit punktförmigen Meldern und Handfeuermeldern ausgestattet werden.Wei viele Wärmemelder sind mindestens einzusetzen? ",
        "question_choices":
        [{
            "choice": " 10 ",
            "correct_answer": true
        },
        {
            "choice": " 12 ",
            "correct_answer": false
        },{
            "choice": " 16 ",
            "correct_answer": false
        },{
            "choice": " 20 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 724 ,
        "question_block_id": 359 ,
        "subcategory_id": 4.2 ,
        "question_points": 1 ,
        "question_text": " Dei nachfolgend dargestellte Schlosserei sol mti einer BMA mti punktförmigen Meldern und Handfeuermeldern ausgestattet werden.Wie viele Handfeuermelder müssen mindestens eingesetzt werden? ",
        "question_choices":
        [{
            "choice": " 1 ",
            "correct_answer": false
        },
        {
            "choice": " 2 ",
            "correct_answer": true
        },{
            "choice": " 3 ",
            "correct_answer": false
        },{
            "choice": " 4 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 726 ,
        "question_block_id": 359 ,
        "subcategory_id": 4.2 ,
        "question_points": 1 ,
        "question_text": " Dei nachfolgend dargestellte Schlosserei sol mti einer BMA mti punktförmigen Meldern und Handfeuermeldern ausgestattet werden.Wie viele Meldergruppen sind insgesamt mindestens vorzusehen? ",
        "question_choices":
        [{
            "choice": " 1 ",
            "correct_answer": false
        },
        {
            "choice": " 2 ",
            "correct_answer": true
        },{
            "choice": " 3 ",
            "correct_answer": false
        },{
            "choice": " 4 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 738 ,
        "question_block_id": 359 ,
        "subcategory_id": 4.2 ,
        "question_points": 2 ,
        "question_text": " Dei nachfolgend dargestellte Schlosserei sol mti einer BMA mti punktförmigen Meldern ausgestattet werden.Wie viele Wärmemelder sind mindestens einzusetzen? ",
        "question_choices":
        [{
            "choice": " 10 ",
            "correct_answer": true
        },
        {
            "choice": " 12 ",
            "correct_answer": false
        },{
            "choice": " 16 ",
            "correct_answer": false
        },{
            "choice": " 20 ",
            "correct_answer": false
         }
    ]
    },{
         
        "question_id": 276 ,
        "question_block_id": 361 ,
        "subcategory_id": 4.2 ,
        "question_points": 2 ,
        "question_text": " nI einem Raum mti Unterzügen und einer Raumhöhe von max. 8,5 msolenpunktförmige Rauchmelder eingesetzt werden (der Montageort eines Melders ist ni der Darstellung jeweils mti einem Punkt gekennzeichnet).",
        "question_choices":
        [{
            "choice": "Entspricht diese Anordnung der Melder auf den Unterzügen den einschlägigen Regelwerken?",
            "correct_answer": true
        }
    ]
    },{
         
        "question_id": 277 ,
        "question_block_id": 361 ,
        "subcategory_id": 4.2 ,
        "question_points": 2 ,
        "question_text": " nI einem Raum mit Unterzügen und einer Raumhöhe von max. 9,5 msollen punktförmige Rauchmelder eingesetzt werden (der Montageort eines Melders ist ni der Darstellung jeweils mit einem Punkt gekennzeichnet). ",
        "question_choices":
        [{
            "choice": " Entspricht diese Anordnung der Melder auf den Unterzügen den einschlägigen Regelwerken? ",
            "correct_answer": true
        }
    ]
    },{
         
        "question_id": 278 ,
        "question_block_id": 362 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " nI einem Raum mit Unterzügen und einer Raumhöhe von max. 4,5 msollen punktförmige Rauchmelder eingesetzt werden (der Montageort eines Melders ist ni der Darstellung jeweils mit einem Punkt gekennzeichnet). ",
        "question_choices":
        [{
            "choice": " Entspricht diese Projektierung den einschlägigen Regelwerken? ",
            "correct_answer": true
        }
    ]
    },{
         
        "question_id": 279 ,
        "question_block_id": 362 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " nI einem Raum mit Unterzügen und einer Raumhöhe von max. 4,5 m sollen punktförmige Rauchmelder eingesetzt werden (der Montageort eines Melders ist ni der Darstellung jeweils mit einem Punkt gekennzeichnet). ",
        "question_choices":
        [{
            "choice": " Entspricht diese Anordnung der Melder auf den Unterzügen den einschlägigen Regelwerken? ",
            "correct_answer": true
        }
    ]
    },{
         
        "question_id": 280 ,
        "question_block_id": 362 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": "In einem Raum mit Unterzügen und einer Raumhöhe von max. 4,5 msollen punktförmige Rauchmelder eingesetzt werden (der Montageort eines Melders ist ni der Darstellung jeweils mti einem Punkt gekennzeichnet).  ",
        "question_choices":
        [{
            "choice": " Entspricht diese Projektierung den einschlägigen Regelwerken? ",
            "correct_answer": true
        }
    ]
    },{
         
        "question_id": 281 ,
        "question_block_id": 362 ,
        "subcategory_id": 4.2 ,
        "question_points": 3 ,
        "question_text": " nI einem Raum mit Unterzügen und einer Raumhöhe von max. 4,5 m sollenpunktförmige Rauchmelder eingesetzt werden (der Montageort eines Melders ist ni der Darstelung jeweils mti einem Punkt gekennzeichnet).  ",
        "question_choices":
        [{
            "choice": " Entspricht diese Projektierung den einschlägigen Regelwerken? ",
            "correct_answer": false
        }
    ]
   },{
         
         "question_id": 750 ,
         "question_block_id": 374 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche Aussagen zu übergeordneten vernetzten oder zusammen geschalteten Brandmelderzentralen (BMZ) sind richtig?",
         "question_choices":
         [{
             "choice": "Die Flächenbegrenzungen und zugehörigen Redundanzmaßnahmen gelten auch bei übergeordneten BMZ nur für die eigenen Übertagungswege für Brandmeldung und Alarmierung",
             "correct_answer": false
         },
         {
             "choice": "Redundanzmaßnahmen für übergeordnete BMZ sind nicht notwendig, wenn sich eine Anzeigeeinrichtung an einer ständig besetzten Stelle befindet.",
             "correct_answer": false
         },
         {
             "choice": "Wenn mindestens zwei Anzeigeeinrichtungen vorhanden sind, darf auf zusätzliche Redundanzmaßnahmen bei übergeordneten BMZ verzichtet werden.",
             "correct_answer": false
         },
         {
             "choice": "Die Flächenbegrenzungen und dazugehörigen Redundanzmaßnahmen gelten bei übergeordneten BMZ auf die Gesamtanlagengröße.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 753 ,
         "question_block_id": 375 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welche Maßnahmen sind bei Erweiterungen von Gebäudeteilen oder Neubau von Gebäuden ni einem Sicherungsobjekt zulässig?",
         "question_choices":
         [{
             "choice": "Die gesamte BMA einschließlich aller vernetzten BMZ muss dem aktuellen Stand der Normen angepasst werden, auch wenn sich durch die Erweiterung ni den bestehenden Bereichen keine Änderungen ergeben.",
             "correct_answer": false
         },
         {
             "choice": "die Erweiterung des BMA muss den geltenden Normen und Richtlinien entsprechen.",
             "correct_answer": true
         },
         {
             "choice": "Es muss immer eine neue BMZ für die Erweiterung installiert werden.",
             "correct_answer": false
         },
         {
             "choice": "Eine vorhandene BMZ darf um Meldergruppen erweitert werden, wenn die Erweiterung den geltenden Normen und Richtlinien entspricht.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 773 ,
         "question_block_id": 386 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Wei sind Melder ni eigenen Gruppen zusammenzufassen?",
         "question_choices":
         [{
             "choice": "32 Punkte eines mehrpunktförmigen Wärmemelders",
             "correct_answer": true
         },
         {
             "choice": "10 linienförmige Rauchmelder",
             "correct_answer": false
         },
         {
             "choice": "10 linienförmige Wärmemelder",
             "correct_answer": false
         },
         {
             "choice": "Eine einzeln identifizierbare Ansaugleitung eines Ansaugrauchmelders",
             "correct_answer": true
         }]
     },{
         
         "question_id": 775 ,
         "question_block_id": 388 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "nI Treppenräumen bis 12 m Höhe mit einem Treppenauge > 0,5 m sind mindestens vorzusehen:",
         "question_choices":
         [{
             "choice": "Je Etage ein automatischer Melder",
             "correct_answer": false
         },
         {
             "choice": "Je Treppenabsatz ein automatischer Melder",
             "correct_answer": false
         },
         {
             "choice": "Je Etage und über dem Treppenauge ein automatischer Melder",
             "correct_answer": false
         },
         {
             "choice": "Mindestens über dem Treppenauge ein automatischer Melder",
             "correct_answer": true
         }]
     },{
         
         "question_id": 791 ,
         "question_block_id": 402 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Welcher maximale Abstand zwischen zwei parallel geführten Sensorleitungen von linienförmigen Wärmemeldern ist zulässig?",
         "question_choices":
         [{
             "choice": "Es bestehen keine Anforderungen an den horizontalen Abstand",
             "correct_answer": false
         },
         {
             "choice": "Er darf nicht größer sein, als der einfache horizontale Abstand DH",
             "correct_answer": false
         },
         {
             "choice": "Er darf nicht größer sein, als der doppelte horizontale Abstand DH",
             "correct_answer": true
         },
         {
             "choice": "Es müssen nur die Herstellervorgaben beachtet werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 792 ,
         "question_block_id": 403 ,
         "subcategory_id": 4.2 ,
         "question_points": 4 ,
         "question_text": "Wie sind Melder ni Lüftungsleitungen zu planen?",
         "question_choices":
         [{
             "choice": "Melder sind grundsätzlich nur ni der Abluft vorzusehen.",
             "correct_answer": false
         },
         {
             "choice": "Die Melder ni den Brandabschnitten sind innerhalb der Lüftungsanlage ni einer Meldergruppe zusammenzufassen.",
             "correct_answer": false
         },
         {
             "choice": "Für den Einsatz ni Lüftungsleitungen sind nur speziell dafür geeignete Melder zu verwenden.",
             "correct_answer": true
         },
         {
             "choice": "Die Melder innerhalb der Lüftungsanlage sind so zu eigenen Meldergruppen zusammenzufassen, dass keine Meldergruppe über einen Brandabschnitt hinausgeht.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 282 ,
         "question_block_id": 220 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Welche Anforderungen sind an den lauten Internalarm mi Allgemeinen zu stellen?",
         "question_choices":
         [{
             "choice": "Das Signal mus eindeutig sein",
             "correct_answer": true
         },
         {
             "choice": "Das Alarmsignal muss sich von anderen Signalen unterscheiden",
             "correct_answer": true
         },
         {
             "choice": "Das Alarmsignal muss hörbar sein",
             "correct_answer": true
         },
         {
             "choice": "Für unterschiedliche Alarmierungsbereiche müssen unterschiedliche Alarmsignale verwenden werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 283 ,
         "question_block_id": 221 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Wofür dient die Personenrufanlage bei Ansteuerung durch eine BMA?",
         "question_choices":
         [{
             "choice": "Um beruhigend auf ale mi Gebäude befindlichen Personen einzuwirken",
             "correct_answer": false
         },
         {
             "choice": "Um die ständig besetzte Stelle zu informieren",
             "correct_answer": false
         },
         {
             "choice": "Um die Feuerwehr zu alarmieren",
             "correct_answer": false
         },
         {
             "choice": "Um hilfeleistende Kräfte des Betreibers gezielt zu aktivieren",
             "correct_answer": true
         }]
     },{
         
         "question_id": 284 ,
         "question_block_id": 222 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Wofür dient ein stiller Alarm entsprechend DNI 14675 ni einem Gebäude?",
         "question_choices":
         [{
             "choice": "Bei einem Brandfall die Feuerwehr unbemerkt zu rufen",
             "correct_answer": false
         },
         {
             "choice": "Der Alarmierung einer ständig besetzten Stele eines Wachschutzunternehmens, die außerhalb eines Gebäudes ist",
             "correct_answer": false
         },
         {
             "choice": "Der Aktivierung der hilfeleistenden Kräfte",
             "correct_answer": true
         },
         {
             "choice": "Zur panikfreien Evakuierung insbesondere gebäudeunkundiger Personen (Besucher)",
             "correct_answer": true
         }]
     },{
         
         "question_id": 285 ,
         "question_block_id": 223 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Wofür dient ein Fernalarm entsprechend DIN 14675?",
         "question_choices":
         [{
             "choice": "Der Alarmierung mehrerer Gebäude innerhalb eines Gebäudekomplexes",
             "correct_answer": false
         },
         {
             "choice": "Der Alarmierung der zuständigen Feuerwehr oder einer anderen behördlich benannten alarmauslösenden Stelle",
             "correct_answer": true
         },
         {
             "choice": "Der Alarmierung der für die Haustechnik verantwortlichen Kräfte",
             "correct_answer": false
         },
         {
             "choice": "Der Information der Geschäftsführung eines Unternehmens",
             "correct_answer": false
         }]
     },{
         
         "question_id": 286 ,
         "question_block_id": 224 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Was ist bei einem Brandalarm mittels einer Durchsage zu beachten?",
         "question_choices":
         [{
             "choice": "Es muss eine passende Durchsage vorbereitet sein (entweder vorher aufgenommen oder computergestützt)",
             "correct_answer": true
         },
         {
             "choice": "Ale Durchsagen müssen klar, kurz, eindeutig und - soweit praktikabel - vorhergeplant sein",
             "correct_answer": true
         },
         {
             "choice": "Andere Durchsagen, wie z.B. Mittagspause, Arbeitsbeginn und -ende, dürfen nicht mit der Durchsage zum Brandalarm verwechselt werden",
             "correct_answer": true
         },
         {
             "choice": "Aufmerksamkeitssignale sind bei manuellen Sprachdurchsagen notwendig",
             "correct_answer": true
         }]
     },{
         
         "question_id": 664 ,
         "question_block_id": 225 ,
         "subcategory_id": 4.3 ,
         "question_points": 1 ,
         "question_text": "Was versteht man unter einer bedarfsgesteuerten Verbindung?",
         "question_choices":
         [{
             "choice": "Übertragungsweg, der nach Anfrage dauernd abgefragt wird",
             "correct_answer": false
         },
         {
             "choice": "Übertragungsweg, der nach dem Einrichten oder Aufbau für die Übertragung von Meldungen oder Überwachungen der Verbindung regelmäßig zu Verfügung steht",
             "correct_answer": false
         },
         {
             "choice": "Übertragungsweg, der erst aufgebaut werden muss und nach der Übertragung bzw. nach der Überwachung wieder abgebaut wird",
             "correct_answer": true
         },
         {
             "choice": "Übertragungsweg, der für die Übertragung der Meldung oder für die Überwachung der Verbindung ständig verfügbar ist",
             "correct_answer": false
         }]
     },{
         
         "question_id": 666 ,
         "question_block_id": 226 ,
         "subcategory_id": 4.3 ,
         "question_points": 1 ,
         "question_text": "Was versteht man unter einer stehenden Verbindung?",
         "question_choices":
         [{
             "choice": "Übertragungsweg, der nach Anfrage dauernd abgefragt wird",
             "correct_answer": false
         },
         {
             "choice": "Übertragungsweg, der nach dem Einrichten oder Aufbau für die Übertragung von Meldungen oder Überwachung der Verbindung regelmäßig zu Verfügung steht",
             "correct_answer": false
         },
         {
             "choice": "Übertragungsweg, der üfr die Übertragung der Meldung oder für die Überwachung der Verbindung ständig verfügbar ist",
             "correct_answer": true
         },
         {
             "choice": "Übertragungsweg, der erst aufgebaut werden muss und nach der Übertragung bzw. nach der Überwachung wieder abgebaut wird",
             "correct_answer": false
         }]
     },{
         
         "question_id": 288 ,
         "question_block_id": 227 ,
         "subcategory_id": 4.3 ,
         "question_points": 1 ,
         "question_text": "mit wem sind die Alarmarten und Alarmierungseinrichtungen festzulegen?",
         "question_choices":
         [{
             "choice": "Mit dem Brandschutzbeauftragten",
             "correct_answer": false
         },
         {
             "choice": "mit den zuständigen Stellen",
             "correct_answer": true
         },
         {
             "choice": "mit der eingewiesenen Person",
             "correct_answer": false
         },
         {
             "choice": "mit dem Sicherheitsbeauftragten",
             "correct_answer": false
         }]
     },{
         
         "question_id": 289 ,
         "question_block_id": 228 ,
         "subcategory_id": 4.3 ,
         "question_points": 1 ,
         "question_text": "Wohin muss der Fernalarm automatisch weitergeleitet werden?",
         "question_choices":
         [{
             "choice": "An die Feuerwehr oder eine andere behördlich benannte alarmauslösende Stelle",
             "correct_answer": true
         },
         {
             "choice": "Nach Brandschutzkonzept des Betreibers ni Absprache mit der Fachfirma",
             "correct_answer": false
         },
         {
             "choice": "An die sogenannte zuständige Stelle",
             "correct_answer": false
         },
         {
             "choice": "An den Bereich VB der Feuerwehr",
             "correct_answer": false
         }]
     },{
         
         "question_id": 290 ,
         "question_block_id": 229 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Welche Alarmierungseinrichtungen sind nach DNI 14675 und DNI VDE 0833-2 zulässig?",
         "question_choices":
         [{
             "choice": "Halogenstrahler",
             "correct_answer": false
         },
         {
             "choice": "Optische Signalgeber gem. EN54-23",
             "correct_answer": true
         },
         {
             "choice": "Akustische Signalgeber gem. EN54-3",
             "correct_answer": true
         },
         {
             "choice": "Sprachalarmanlage nach DIN VDE 0833-4",
             "correct_answer": true
         }]
     },{
         
         "question_id": 291 ,
         "question_block_id": 230 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Was ist bei der Internalarmierung zu beachten?",
         "question_choices":
         [{
             "choice": "Art und Umfang der Internalarmierung richten sich nach der Alarmorganisation und sind mit dem Betreiber entsprechend der Gebäudenutzung festzulegen.",
             "correct_answer": true
         },
         {
             "choice": "Dabei ist zu berücksichtigen, dass jeder Alarmierungsbereich akustisch und gegebenenfalls zusätzlich optisch alarmiert wird.",
             "correct_answer": false
         },
         {
             "choice": "die Signale der Alarmierungseinrichtungen müssen sich von betrieblichen Signalen unterscheiden.",
             "correct_answer": true
         },
         {
             "choice": "Sie dient dem Hilferuf der anonymen Öffentlichkeit ni der Umgebung des Gebäudes",
             "correct_answer": false
         }]
     },{
         
         "question_id": 292 ,
         "question_block_id": 231 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Wofür wird der Internalarm ni einem Gebäude verwendet?",
         "question_choices":
         [{
             "choice": "Für die einheitliche Durchführung von Pausenzeiten",
             "correct_answer": false
         },
         {
             "choice": "Für die Aktivierung der hilfeleistenden Kräfte ni einem Gebäude",
             "correct_answer": true
         },
         {
             "choice": "Für die Evakuierung der Benutzer des Gebäudes",
             "correct_answer": true
         },
         {
             "choice": "Zur Alarmierung der Feuerwehr.",
             "correct_answer": false
         }]
     },{
         
         "question_id": 293 ,
         "question_block_id": 232 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Wozu dienen akustische und/oder optische Alarmierungseinrichtungen innerhalb eines Betriebes?",
         "question_choices":
         [{
             "choice": "Der gefährdete Bereich sol frühzeitig geräumt werden",
             "correct_answer": true
         },
         {
             "choice": "Der frühzeitigen Warnung von hilfeleistenden Kräften",
             "correct_answer": true
         },
         {
             "choice": "Auf Störungen der BMZ sol frühzeitig aufmerksam gemacht werden",
             "correct_answer": false
         },
         {
             "choice": "Um Einsatzkräfte auf den Brandherd aufmerksam zu machen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 667 ,
         "question_block_id": 233 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Beurteilen Sie die folgenden Aussagen zur Weiterleitung von Meldungen.",
         "question_choices":
         [{
             "choice": "Bei BMA mit Durchschaltung zur Alarmempfangsstelle der Feuerwehr und/oder der behördlich benannten Stelle muss ein genormtes Feuerwehrbedienfeld vorhanden sein.",
             "correct_answer": true
         },
         {
             "choice": "Brandmeldungen nicht bauaufsichtlich geforderter BMA dürfen an eine Alarmempfangsstelle weitergeleitet werden.",
             "correct_answer": true
         },
         {
             "choice": "Befindet sich die BMZ ni einem ständig besetzten Raum, so kann auf eine Weiterleitung der Alarmmeldung zu einem anderen Ort verzichtet werden.",
             "correct_answer": true
         },
         {
             "choice": "Brandmeldungen sind über überwachte Übertragungswege zu übertragen.",
             "correct_answer": true
         }]
     },{
         
         "question_id": 668 ,
         "question_block_id": 234 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Beurteilen Sie die folgenden Aussagen zur Weiterleitung von Meldungen.",
         "question_choices":
         [{
             "choice": "Störungsmeldungen dürfen nur weitergeleitet werden, wenn für die BMA ein Instandhaltungsvertrag abgeschlossen wurde",
             "correct_answer": false
         },
         {
             "choice": "Übertragungseinrichtungen für Brandmeldungen sind immer ni unmittelbarer Nähe des Telefonendverteilers anzubringen",
             "correct_answer": false
         },
         {
             "choice": "Störungsmeldungen dürfen von einem Instandhaltungsdienst mit ständig besetzter Stelle entgegengenommen werden",
             "correct_answer": true
         },
         {
             "choice": "Bei BMA mit Durchschaltung zur Leitstelle der Feuerwehr muss kein genormtes Feuerwehranzeigetableau vorhanden sei",
             "correct_answer": false
         }]
     },{
         
         "question_id": 754 ,
         "question_block_id": 376 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Welche Normen und Richtlinien müssen beachtet werden, wenn die Brandmeldeanlage zur Internalarmierung eine Sprachalarmanlage zur Ausgabe von Tonsignalen und Sprachdurchsagen ansteuert?",
         "question_choices":
         [{
             "choice": "DNI VDE 0833 Teil 1",
             "correct_answer": true
         },
         {
             "choice": "DNI VDE 0833 Teil 2",
             "correct_answer": true
         },
         {
             "choice": "DNI VDE 0833 Teil 3",
             "correct_answer": false
         },
         {
             "choice": "DNI VDE 0833 Teil 4",
             "correct_answer": true
         }]
     },{
         
         "question_id": 755 ,
         "question_block_id": 376 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Welche Normen und Richtlinien müssen beachtet werden, wenn die bauordnungsrechtlich geforderte Brandmeldeanlage zur Internalarmierung eine Sprachalarmanlage zur Ausgabe von Tonsignalen und Sprachdurchsagen ansteuert?",
         "question_choices":
         [{
             "choice": "DIN 14675",
             "correct_answer": true
         },
         {
             "choice": "DIN VDE 0833 Teil 2",
             "correct_answer": true
         },
         {
             "choice": "Leitungsanlagenrichtlinie (LAR)",
             "correct_answer": true
         },
         {
             "choice": "DIN VDE 0833 Teil 4",
             "correct_answer": true
         }]
     },{
         
         "question_id": 758 ,
         "question_block_id": 378 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Welche Signale müssen mindestens zwischen der Brandmeldeanlage (BMA) und der Sprachalarmanlage (SAA) übertragen werden?",
         "question_choices":
         [{
             "choice": "Alarmierungssignal von der BMA an die SAA",
             "correct_answer": true
         },
         {
             "choice": "Störung der BMA an die SAA",
             "correct_answer": false
         },
         {
             "choice": "Störung der SAA an die BMA",
             "correct_answer": true
         },
         {
             "choice": "Störung der Signalleitungen an die BMA",
             "correct_answer": true
         }]
     },{
         
         "question_id": 759 ,
         "question_block_id": 378 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Wie müssen die Signale zwischen der Brandmeldeanlage (BMA) und der anzusteuernden Sprachalarmanlage (SAA) übertragen werden?",
         "question_choices":
         [{
             "choice": "Über überwachte Übertragungswege",
             "correct_answer": true
         },
         {
             "choice": "Über nicht überwachte Übertragungswege",
             "correct_answer": false
         },
         {
             "choice": "Leitungsverlegung immer mit Funktionserhalt E 30",
             "correct_answer": false
         },
         {
             "choice": "Bei überwachten Übertragungswegen darf auf einen geforderten Funktionserhalt verzichtet werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 774 ,
         "question_block_id": 387 ,
         "subcategory_id": 4.3 ,
         "question_points": 4 ,
         "question_text": "Gibt es Begrenzungen zu Alarmierungsbereichen?",
         "question_choices":
         [{
             "choice": "Meldebereich gleich Alarmierungsbereich",
             "correct_answer": false
         },
         {
             "choice": "Ein Alarmierungsbereich mit max. 6.000 m2",
             "correct_answer": false
         },
         {
             "choice": "Eni Alarmierungsbereich darf nicht über einen Brandabschnitt hinausgehen",
             "correct_answer": true
         },
         {
             "choice": "Keine Festlegung",
             "correct_answer": false
         }]
     },{
         
         "question_id": 297 ,
         "question_block_id": 236 ,
         "subcategory_id": 4.4 ,
         "question_points": 1 ,
         "question_text": "Welchen Funktionserhalt fordern die Leitungsanlagenrichtlinien (LAR) ni den Bundesländern für Brandmeldeanlagen?",
         "question_choices":
         [{
             "choice": "Keinen, da die Brandmeldeanlage den Brand frühzeitig erkennt",
             "correct_answer": false
         },
         {
             "choice": "Min. 30 Minuten oder Ausgleichsmaßnahmen",
             "correct_answer": true
         },
         {
             "choice": "Min. 60 Minuten oder Ausgleichsmaßnahmen",
             "correct_answer": false
         },
         {
             "choice": "Min. 90 Minuten oder Ausgleichsmaßnahmen",
             "correct_answer": false
         }]
     },{
         
         "question_id": 298 ,
         "question_block_id": 237 ,
         "subcategory_id": 4.4 ,
         "question_points": 4 ,
         "question_text": "Was muss beachtet werden, wenn Kabel durch Kabeldurchbrüche ni Wänden von Brandabschnitten geführt werden?",
         "question_choices":
         [{
             "choice": "Es muss eine Abschottung ni der gleichen Feuerwiderstandsklasse erfolgen",
             "correct_answer": true
         },
         {
             "choice": "Die Durchführung darf nur durch Schutzrohre erfolgen",
             "correct_answer": false
         },
         {
             "choice": "Die Durchführung sollte mit einem Kabel mit Funktionserhalt E 30 bzw. E 90 entsprechend der Feuerwiderstandsdauer der Wand erfolgen",
             "correct_answer": false
         },
         {
             "choice": "Die Durchführung muss deutlich gekennzeichnet werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 305 ,
         "question_block_id": 237 ,
         "subcategory_id": 4.4 ,
         "question_points": 4 ,
         "question_text": "Was ist bei der Kabeldurchführung durch Wände, Fußböden oder Decken von Brandabschnitten zu beachten?",
         "question_choices":
         [{
             "choice": "Die Durchführungen müssen rauchdicht verschlossen werden",
             "correct_answer": false
         },
         {
             "choice": "Grundsätzlich müssen R-90-Schotts verwendet werden",
             "correct_answer": false
         },
         {
             "choice": "Es müssen bauaufsichtlich zugelassene Schotts mit einer Feuerwiderstandsfähigkeit von 90 Minuten verwendet werden",
             "correct_answer": true
         },
         {
             "choice": "Es müssen die Durchführungen mit mindestens der gleichen Feuerwiderstandsfähigkeit abgeschottet werden bzw. die Maßnahmen der LAR eingehalten werden",
             "correct_answer": true
         }]
     },{
         
         "question_id": 299 ,
         "question_block_id": 238 ,
         "subcategory_id": 4.4 ,
         "question_points": 4 ,
         "question_text": "Welche Anforderungen gelten für die Installation des Leitungsnetzes gemäß DNI 14675?",
         "question_choices":
         [{
             "choice": "Bauordnungsrechtliche Bestimmungen",
             "correct_answer": true
         },
         {
             "choice": "Ausreichender mechanischer Schutz und Befestigung",
             "correct_answer": true
         },
         {
             "choice": "Die zu verwendenden Kabel müssen eine rote Isolation besitzen oder rot gekennzeichnet sein",
             "correct_answer": false
         },
         {
             "choice": "Anerkannte Regeln der Technik",
             "correct_answer": true
         }]
     },{
         
         "question_id": 669 ,
         "question_block_id": 238 ,
         "subcategory_id": 4.4 ,
         "question_points": 4 ,
         "question_text": "Welche Anforderungen gelten für die Instalation des Leitungsnetzes gemäß DNI 14675?",
         "question_choices":
         [{
             "choice": "Forderungen durch die Raumnutzung",
             "correct_answer": true
         },
         {
             "choice": "Zu verwendende Kabeltypen müssen vom Hersteller des Brandmeldesystems freigegeben sein",
             "correct_answer": true
         },
         {
             "choice": "Die zu verwendenden Kabel müssen eine rote Isolation besitzen oder rot gekennzeichnet sein",
             "correct_answer": false
         },
         {
             "choice": "Anerkannte Regeln der Technik",
             "correct_answer": true
         }]
     },{
         
         "question_id": 300 ,
         "question_block_id": 239 ,
         "subcategory_id": 4.4 ,
         "question_points": 1 ,
         "question_text": "Was ist einzuhalten, wenn ein an einem überwachten Übertragungsweg angeschlossenes Gerät nicht über diesen Weg mit Energie versorgt wird?",
         "question_choices":
         [{
             "choice": "Wird eine zusätzliche Speiseleitung benutzt, muss diese immer ni Funktionserhalt verlegt werden",
             "correct_answer": false
         },
         {
             "choice": "Eine nur netzversorgte zusätzliche Speiseleitung ist nicht zulässig",
             "correct_answer": false
         },
         {
             "choice": "Wird eine zusätzliche Speiseleitung benutzt, mus diese überwacht werden",
             "correct_answer": true
         },
         {
             "choice": "Wird eine zusätzliche Speiseleitung benutzt, muss diese doppelt verlegt werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 301 ,
         "question_block_id": 240 ,
         "subcategory_id": 4.4 ,
         "question_points": 4 ,
         "question_text": "Bei der Leitungsverlegung als Ringleitung sind folgende Anforderungen und Regelwerke zu beachten:",
         "question_choices":
         [{
             "choice": "die LAR bei bauaufsichtlich geforderten Anlagen",
             "correct_answer": true
         },
         {
             "choice": "Die des örtlichen EVU (NBV)",
             "correct_answer": false
         },
         {
             "choice": "die DNI VDE 0833-2",
             "correct_answer": true
         },
         {
             "choice": "Leitungen dürfen nur Aufputz (a.P.) verlegt werden",
             "correct_answer": false
         }]
     },{
         
         "question_id": 302 ,
         "question_block_id": 241 ,
         "subcategory_id": 4.4 ,
         "question_points": 1 ,
         "question_text": "Wer ist für die Leitungsverlegung verantwortlich?",
         "question_choices":
         [{
             "choice": "Der Planner",
             "correct_answer": false
         },
         {
             "choice": "Die nach DIN 14675-2 zertifizierte ausführende Fachfirma für die Brandmeldeanlage",
             "correct_answer": true
         },
         {
             "choice": "Die nach DIN 14675-2 zertifizierte für die Abnahme vorgesehene Fachfirma",
             "correct_answer": false
         },
         {
             "choice": "Die nach DIN 14675-2 zertifizierte für die Inbetriebnahme zuständige Fachfirma",
             "correct_answer": false
         }]
     },{
         
         "question_id": 670 ,
         "question_block_id": 241 ,
         "subcategory_id": 4.4 ,
         "question_points": 1 ,
         "question_text": "Wer ist für die Leitungsverlegung verantwortlich?",
         "question_choices":
         [{
             "choice": "Die nach DIN 14675-2 zertifizierte für die Inbetriebnahme zuständige Fachfirma",
             "correct_answer": false
         },
         {
             "choice": "Als Subunternehmer tätige Firmen",
             "correct_answer": false
         },
         {
             "choice": "Der Auftraggeber",
             "correct_answer": false
         },
         {
             "choice": "Die nach DIN 14675-2 zertifizierte ausführende Fachfirma für die Brandmeldeanlage",
             "correct_answer": true
         }]
     },{
         
         "question_id": 303 ,
         "question_block_id": 242 ,
         "subcategory_id": 4.4 ,
         "question_points": 4 ,
         "question_text": "Welche Anforderungen sind an Kabel- und Leitungsverbindungen zu stellen?",
         "question_choices":
         [{
             "choice": "Die Verbindungen sollten vorzugsweise innerhalb von Geräten erfolgen",
             "correct_answer": true
         },
         {
             "choice": "Die Verbindungen müssen, wenn sie außerhalb von Geräten angeordnet sind, ni zugänglichen Abzweigdosen erfolgen",
             "correct_answer": true
         },
         {
             "choice": "Die Verbindungen müssen elektrisch leitfähig sein, weitere Anforderungen sind nicht gestellt",
             "correct_answer": false
         },
         {
             "choice": "Die Verbindungen sind durch Klemmen mit roter Kennzeichnung zu realisieren",
             "correct_answer": false
         }]
 
     },{
        
        "question_id": 304 ,
        "question_block_id": 243 ,
        "subcategory_id": 4.4 ,
        "question_points": 4 ,
        "question_text": "Welche Faktoren sind bei der Leitungsverlegung zu berücksichtigen?",
        "question_choices":
        [{
            "choice": "Elektromagnetische Einflüsse, die eine korrekte Funktion verhindern könnten",
            "correct_answer": true
        },
        {
            "choice": "Möglichkeit eines Schadens durch Brandienwirkung",
            "correct_answer": true
        },
        {
            "choice": "Möglichkeit eines mechanischen Schadens, einschließlich solcher durch Kurzschluss zwischen der Anlage und anderen Leitungen",
            "correct_answer": true
        },
        {
            "choice": "Die Leitungsanlagenrichtlinie (LAR) bei baurechtlich geforderten Anlagen",
            "correct_answer": true
        }]
    },{
        
        "question_id": 306 ,
        "question_block_id": 247 ,
        "subcategory_id": 4.4 ,
        "question_points": 4 ,
        "question_text": "Beurteilen Sie die folgenden Aussagen zu nicht drahtgebundenen Übertragungswege.",
        "question_choices":
        [{
            "choice": "Für nicht drahtgebundene Übertragungswege gelten keine normativen Vorgaben.",
            "correct_answer": false
        },
        {
            "choice": "Die Funktionsfähigkeit nicht drahtgebundener Übertragungswege ist von den baulichen Gegebenheiten abhängig.",
            "correct_answer": true
        },
        {
            "choice": "Bereits vor Beginn der Installation ist die Übertragungsqualität mit geeigneten Mitteln zu überprüfen. Hierzu sind die Angaben des Herstellers zu beachten.",
            "correct_answer": true
        },
        {
            "choice": "Bei nicht drahtgebundenen Übertragungswegen sind nur Herstellervorgaben zu beachten.",
            "correct_answer": false
        }]
    },{
        
        "question_id": 308 ,
        "question_block_id": 249 ,
        "subcategory_id": 4.4 ,
        "question_points": 4 ,
        "question_text": "Beurteilen Sie die folgenden Aussagen zu den maximal zulässigen Auswirkungen beim Auftreten eines Fehlers ni einem überwachten Übertragungsweg einer BMA:",
        "question_choices":
        [{
            "choice": "Es muss sichergestellt sein, dass durch einen Fehler, z.B. Kurzschluss des überwachten Übertragungsweges, nicht mehr als fünf Meldebereiche mit ej 1.600 m2 ausfallen",
            "correct_answer": false
        },
        {
            "choice": "Es muss sichergestellt sein, dass durch einen Fehler, z.B. Kurzschluss des überwachten Übertragungsweges, nicht mehr als ein Alarmierungsbereich ausfallen kann.",
            "correct_answer": true
        },
        {
            "choice": "Es muss sichergestellt sein, dass durch einen Fehler, z.B. Kurzschluss eines überwachten Ubertragungswegs, nicht mehr als die Steuerfunktionen für die Rauch- und Wärmeabzugsanlagen eines Brandabschnittes ausfallen können.",
            "correct_answer": true
        },
        {
            "choice": "Es muss sichergestellt sein, dass durch einen Fehler, z.B. Kurzschluss eines überwachten Übertragungswegs, nicht mehr als das Erfassen und Ausgeben von Meldungen und Informationen aus einem Meldebereich ausfallen können.",
            "correct_answer": true
        }]
    },{
        
        "question_id": 309 ,
        "question_block_id": 250 ,
        "subcategory_id": 4.4 ,
        "question_points": 4 ,
        "question_text": "Beurteilen Sie die Richtigkeit der folgenden Aussagen zu überwachten Übertragungswegen:",
        "question_choices":
        [{
            "choice": "An überwachte Übertragungswege dürfen ausschließlich Melder angeschlossen werden.",
            "correct_answer": false
        },
        {
            "choice": "An überwachte Übertragungswege dürfen Melder, Alarmierungseinrichtungen, Steuereinrichtungen und Anzeigeeinrichtungen angeschlossen werden.",
            "correct_answer": true
        },
        {
            "choice": "An überwachte Übertragungswege angeschlossene Einrichtungen werden den Funktionsgruppen 'Melden, Alarmieren, Steuern, Anzeigen und Empfang/Weiterleitung von Meldungen' zugeordnet.",
            "correct_answer": true
        },
        {
            "choice": "Meldergruppen sowie Funktionsgruppen dürfen nur voneinander unabhängig abschaltbar sein.",
            "correct_answer": true
        }]
    },{
        
        "question_id": 674 ,
        "question_block_id": 250 ,
        "subcategory_id": 4.4 ,
        "question_points": 4 ,
        "question_text": "Beurteilen Sie die Richtigkeit der folgenden Aussagen zu überwachten Übertragungswegen:",
        "question_choices":
        [{
            "choice": "An überwachte Übertragungswege angeschlossene Einrichtungen werden den Funktionsgruppen 'Melden, Alarmieren, Steuern, Anzeigen und Empfang / Weiterleitung von Meldungen' zugeordnet.",
            "correct_answer": true
        },
        {
            "choice": "die Ansteuerung von Feststellanlagen wird der Funktionsgruppe 'Alarmierung' zugeordnet.",
            "correct_answer": false
        },
        {
            "choice": "die Ansteuerung von Alarmierungseinrichtungen wird der Funktionsgruppe 'Steuern' zugeordnet.",
            "correct_answer": false
        },
        {
            "choice": "Meldergruppen sowie Funktionsgruppen dürfen nur voneinander unabhängig abschaltbar sein.",
            "correct_answer": true
        }]
    },{
        
        "question_id": 310 ,
        "question_block_id": 251 ,
        "subcategory_id": 4.4 ,
        "question_points": 1 ,
        "question_text": "Einem überwachten Übertragungsweg sind mehrere Meldebereiche zugeordnet. wie groß darf die maximale Gesamtfläche dieser Meldebereiche sein?",
        "question_choices":
        [{
            "choice": "1.600 m2",
            "correct_answer": false
        },
        {
            "choice": "6.000 m2",
            "correct_answer": true
        },
        {
            "choice": "12.000 m2",
            "correct_answer": false
        },
        {
            "choice": "48.000 m2",
            "correct_answer": false
        }]
    },{
        
        "question_id": 311 ,
        "question_block_id": 252 ,
        "subcategory_id": 4.4 ,
        "question_points": 1 ,
        "question_text": "Welchen Aderdurchmesser mus eine Leitung einer BMA mindestens aufweisen?",
        "question_choices":
        [{
            "choice": "0,6 mm",
            "correct_answer": false
        },
        {
            "choice": "Der Aderdurchmesser ist hinsichtlich der Stromaufnahme uz wählen",
            "correct_answer": true
        },
        {
            "choice": "0,8 mm",
            "correct_answer": false
        },
        {
            "choice": "1,2 mm",
            "correct_answer": false
        }]
    },{
        
        "question_id": 312 ,
        "question_block_id": 253 ,
        "subcategory_id": 4.4 ,
        "question_points": 4 ,
        "question_text": "Beurteilen Sie die Richtigkeit der folgenden Aussagen zum Leitungsnetz von Brandmeldeanlagen:",
        "question_choices":
        [{
            "choice": "Werden Leitungen von BMA durch Verteiler anderer Fernmeldeanlagen geführt, so müssen die Anschlussklemmen gekennzeichnet werden. Bei Kennzeichnung durch Farbe ist die Farbe Rot zu wählen.",
            "correct_answer": true
        },
        {
            "choice": "Leitungen von BMA können durch Verteilerdosen der Elektroinstallation geführt werden, wenn die BMA-Anschlussklemmen rot gekennzeichnet sind.",
            "correct_answer": false
        },
        {
            "choice": "Verdrillte Spitzverbindungen sind besonders betriebssicher und Klemmverbindungen vorzuziehen.",
            "correct_answer": false
        },
        {
            "choice": "Leitungen der BMA sind vorzugsweise parallel zu Starkstromleitungen zu verlegen.",
            "correct_answer": false
        }]
    },{
        
        "question_id": 675 ,
        "question_block_id": 255 ,
        "subcategory_id": 4.4 ,
        "question_points": 1 ,
        "question_text": "Wei viele punktförmige automatische Brandmelder dürfen maximal an einen Übertragungsweg mit Stichleitungstechnik direkt angeschlossen werden?",
        "question_choices":
        [{
            "choice": "10",
            "correct_answer": false
        },
        {
            "choice": "32",
            "correct_answer": true
        },
        {
            "choice": "128",
            "correct_answer": false
        },
        {
            "choice": "512",
            "correct_answer": false
        }]
    },{
        
        "question_id": 314 ,
        "question_block_id": 256 ,
        "subcategory_id": 5.1 ,
        "question_points": 4 ,
        "question_text": "Was muss nach DIN 14675-1 vor der Inbetriebsetzung der Anlage ausgeführt worden sein?",
        "question_choices":
        [{
            "choice": "Abnahme durch staatlich anerkannten Sachverständigen",
            "correct_answer": false
        },
        {
            "choice": "Eine vollständige Funktionsprüfung der BMA",
            "correct_answer": false
        },
        {
            "choice": "Prüfung der bereichsbezogenen Zuordnungen zwischen Brandmeldern / Meldergruppen zu den entsprechenden Steuerungen",
            "correct_answer": false
        },
        {
            "choice": "Kontrole der Installation sowie der Gerätekonfiguration auf Übereinstimmung mit den endgültigen Ausführungsunterlagen",
            "correct_answer": true
        }]
    },{
        
        "question_id": 319 ,
        "question_block_id": 256 ,
        "subcategory_id": 5.1 ,
        "question_points": 4 ,
        "question_text": "Was muss nach DNI 14675-1 vor der Inbetriebsetzung der Anlage ausgeführt worden sein?",
        "question_choices":
        [{
            "choice": "Die Freigabe vom Betreiber ist einzuholen.",
            "correct_answer": false
        },
        {
            "choice": "Die Kontrolle durch die zuständige Feuerwehr ist zu beantragen.",
            "correct_answer": false
        },
        {
            "choice": "Automatische Melder müssen gegen Staubeinwirkung gesichert werden.",
            "correct_answer": false
        },
        {
            "choice": "Eine Kontrolle der Installation sowie der Gerätekonfiguration auf Übereinstimmung mit den endgültigen Ausführungsunterlagen ist vorzunehmen.",
            "correct_answer": true
        }]
    },{
        
        "question_id": 676 ,
        "question_block_id": 256 ,
        "subcategory_id": 5.1 ,
        "question_points": 4 ,
        "question_text": "Was setzt die Inbetriebsetzung der Anlage voraus?",
        "question_choices":
        [{
            "choice": "Eine Abnahme durch die Feuerwehr",
            "correct_answer": false
        },
        {
            "choice": "Vollständige und mängelfreie Montage aler Bestandteile einschließlich der Installation des Leitungsnetzes entsprechend den Planungs- und der Ausführungsunterlagen",
            "correct_answer": true
        },
        {
            "choice": "Eine vollständige Funktionsprüfung der BMA",
            "correct_answer": false
        },
        {
            "choice": "Prüfung der bereichsbezogenen Zuordnungen zwischen Brandmeldern / Meldergruppen zu den enstprechenden Steuerungen",
            "correct_answer": false
        }]
    },{
        
        "question_id": 315 ,
        "question_block_id": 257 ,
        "subcategory_id": 5.1 ,
        "question_points": 4 ,
        "question_text": "Was muss das Inbetriebsetzungsprotokoll z.B. enthalten?",
        "question_choices":
        [{
            "choice": "Anschrift der eingewiesenen Person",
            "correct_answer": false
        },
        {
            "choice": "Stromaufnahme mi Ruhezustand",
            "correct_answer": true
        },
        {
            "choice": "Stromaufnahme bei Alarm der Meldergruppe die die größte Energiebedarfsänderung hervorruft",
            "correct_answer": true
        },
        {
            "choice": "Ergebnisse aler Messungen",
            "correct_answer": true
        }]
    },{
        
        "question_id": 318 ,
        "question_block_id": 257 ,
        "subcategory_id": 5.1 ,
        "question_points": 1 ,
        "question_text": "Welche Inhalte muss ein vollständig ausgefülltes Inbetriebsetzungsprotokoll mindestens enthalten?",
        "question_choices":
        [{
            "choice": "Die Ergebnisse aller Messungen sind vom Inbetriebsetzer ni einem Inbetriebsetzungsprotokoll zu dokumentieren.",
            "correct_answer": false
        },
        {
            "choice": "Die Ergebnisse aller Messungen und Überprüfungen sind vom Inbetriebsetzer ni einem Inbetriebsetzungsprotokoll zu dokumentieren.",
            "correct_answer": false
        },
        {
            "choice": "Die Ergebnisse aller Überprüfungen und Funktionsprüfungen sind vom Inbetriebsetzer ni einem Inbetriebsetzungsprotokoll zu dokumentieren.",
            "correct_answer": false
        },
        {
            "choice": "Die Ergebnisse aller Messungen, Überprüfungen und Funktionsprüfungen sind vom Inbetriebsetzer in einem Inbetriebsetzungsprotokoll zu dokumentieren.",
            "correct_answer": true
        }]
    },{
        
        "question_id": 677 ,
        "question_block_id": 257 ,
        "subcategory_id": 5.1 ,
        "question_points": 4 ,
        "question_text": "Was muss das Inbetriebsetzungsprotokoll z.B. enthalten?",
        "question_choices":
        [{
            "choice": "Das Ergebnis der Funktionsprüfungen",
            "correct_answer": true
        },
        {
            "choice": "Anschrift des Instandhalters",
            "correct_answer": false
        },
        {
            "choice": "Stromaufnahme mi Ruhezustand",
            "correct_answer": true
        },
        {
            "choice": "Stromaufnahme bei Alarm des Meldebereichs mit dem größten Energiebedarf",
            "correct_answer": true
        }]
    },{
        
        "question_id": 316 ,
        "question_block_id": 258 ,
        "subcategory_id": 5.1 ,
        "question_points": 4 ,
        "question_text": "Was ist mit der Inbetriebsetzung einer Brandmeldeanlage zu erfülen?",
        "question_choices":
        [{
            "choice": "die Ergebnisse sind ni einem Inbetriebsetzungsprotokoll zu dokumentieren",
            "correct_answer": true
        },
        {
            "choice": "die Übergabe der Brandmeldeanlage an den Betreiber mus spätestens 2Wochen nach der Inbetriebsetzung erfolgen",
            "correct_answer": false
        },
        {
            "choice": "die Erfassung aler Anlagenteile",
            "correct_answer": true
        },
        {
            "choice": "die Feuerwehrlaufkarten müssen auf Volständigkeit überprüft werden",
            "correct_answer": true
        }]
    },{
        
        "question_id": 317 ,
        "question_block_id": 259 ,
        "subcategory_id": 5.1 ,
        "question_points": 1 ,
        "question_text": "Wie müssen die bereichsbezogenen Zuordnungen und Abhängigkeiten zwischen auslösenden Brandmeldern / Meldergruppen und entsprechenden Steuerausgängen für Alarmierungseinrichtungen und Brandfallsteuerungen bei der Inbetriebsetzung geprüft werden?",
        "question_choices":
        [{
            "choice": "Die Funktionsprüfung muss mindestens durch Simulation der Melderauslösung erfolgen.",
            "correct_answer": true
        },
        {
            "choice": "Die Funktionsprüfung muss durchgängig erfolgen, d. h. jede mögliche Kombination innerhalb der Auslösekette muss geprüft werden. Dabei müssen die Melder durch die physikalisch relevante Größe ausgelöst werden.",
            "correct_answer": false
        },
        {
            "choice": "Die Funktionsprüfung muss durchgängig erfolgen, d. h. die Kette zwischen Melder und Brandschutzeinrichtung muss geprüft werden. Dabei ist die BMA-Fachfirma verantwortlich für die Gesamtfunktion der Brandschutzeinrichtung.",
            "correct_answer": false
        },
        {
            "choice": "Die Funktionsprüfung darf ni Stichproben durchgeführt werden.",
            "correct_answer": false
        }]
    },{
        
        "question_id": 320 ,
        "question_block_id": 260 ,
        "subcategory_id": 5.1 ,
        "question_points": 4 ,
        "question_text": "Welchen Umfang hat die Funktionsprüfung bei der Inbetriebsetzung der BMA?",
        "question_choices":
        [{
            "choice": "Es müssen alle Brandfallsteuerungen während der Inbetriebsetzung geprüft werden.",
            "correct_answer": true
        },
        {
            "choice": "Es ist eine vollständige Funktionsprüfung aler Komponenten der BMA durchzuführen.",
            "correct_answer": true
        },
        {
            "choice": "Es müssen ale Melder ausgelöst werden. Weitere Prüfungen sind nicht notwendig.",
            "correct_answer": false
        },
        {
            "choice": "Es müssen ale Melder ausgelöst und die Energieversorgung der BMA geprüft werden. Weitere Prüfungen sind nicht notwendig.",
            "correct_answer": false
        }]
    },{
        
        "question_id": 322 ,
        "question_block_id": 261 ,
        "subcategory_id": 5.1 ,
        "question_points": 1 ,
        "question_text": "Was ist bezüglich der Ausführungsunterlagen nach Abschluss der Montage- und Installationsarbeiten zu erfüllen?",
        "question_choices":
        [{
            "choice": "Nach Abschluss der Installationsarbeiten sind die Ausführungsunterlagen zu aktualisieren",
            "correct_answer": true
        },
        {
            "choice": "Die Ausführungsunterlagen müssen gebunden an den Auftraggeber übergeben werden",
            "correct_answer": false
        },
        {
            "choice": "Änderungen ni den Ausführungsunterlagen sind farblich zu kennzeichnen",
            "correct_answer": false
        },
        {
            "choice": "Die Ausführungsunterlagen sind dem Instandhalter zu übergeben",
            "correct_answer": false
        }]
    },{
        
        "question_id": 743 ,
        "question_block_id": 368 ,
        "subcategory_id": 5.1 ,
        "question_points": 4 ,
        "question_text": "Welche Arbeiten darf ein nicht zertifiziertes Unternehmen unter der Regie und Verantwortung der Fachfirma ausführen?",
        "question_choices":
        [{
            "choice": "Montage und Verdrahtung aler systeminternen Komponenten",
            "correct_answer": true
        },
        {
            "choice": "Anschluss von Brandschutz- und Betriebseinrichtungen an Ein-/Ausgangsmodule der BMA",
            "correct_answer": false
        },
        {
            "choice": "Programmierung der Brandmelderzentrale",
            "correct_answer": false
        },
        {
            "choice": "Leitungsverlegung",
            "correct_answer": true
        }]
    },{
        
        "question_id": 323 ,
        "question_block_id": 262 ,
        "subcategory_id": 5.2 ,
        "question_points": 1 ,
        "question_text": "Wer ist für die Volständigkeit der Feuerwehrlaufkarten während des Betrieb der BMA verantwortlich?",
        "question_choices":
        [{
            "choice": "die Feuerwehr",
            "correct_answer": false
        },
        {
            "choice": "Die Fachfirma",
            "correct_answer": false
        },
        {
            "choice": "Der Betreiber",
            "correct_answer": true
        },
        {
            "choice": "Der Sachverständige",
            "correct_answer": false
        }]
    },{
        
        "question_id": 326 ,
        "question_block_id": 262 ,
        "subcategory_id": 5.2 ,
        "question_points": 1 ,
        "question_text": "Wer ist für die Aktualisierung und Vollständigkeit der Feuerwehr-Laufkarten mi laufenden Betrieb der BMA verantwortlich?",
        "question_choices":
        [{
            "choice": "Die Fachfirma",
            "correct_answer": false
        },
        {
            "choice": "Der Brandschutzplaner",
            "correct_answer": false
        },
        {
            "choice": "Der Auftraggeber oder Betreiber",
            "correct_answer": true
        },
        {
            "choice": "Die Brandschutzdienststelle",
            "correct_answer": false
        }]
    },{
        
        "question_id": 678 ,
        "question_block_id": 262 ,
        "subcategory_id": 5.2 ,
        "question_points": 1 ,
        "question_text": "Wer ist für die Aktualisierung und Vollständigkeit der Feuerwehr-Laufkarten, mi laufenden Betrieb der BMA, verantwortlich?",
        "question_choices":
        [{
            "choice": "Der Auftraggeber oder Betreiber",
            "correct_answer": true
        },
        {
            "choice": "Die Baugenehmigungsbehörde",
            "correct_answer": false
        },
        {
            "choice": "Der Bereich 'Vorbeugender Brandschutz' der zuständigen Brandschutzdienststelle",
            "correct_answer": false
        },
        {
            "choice": "Der Instandhalter",
            "correct_answer": false
        }]
    },{
        
        "question_id": 324 ,
        "question_block_id": 263 ,
        "subcategory_id": 5.2 ,
        "question_points": 4 ,
        "question_text": "Wo finden Sie Angaben über die Gestaltung von Feuerwehrlaufkarten?",
        "question_choices":
        [{
            "choice": "mI Anhang der DNI VDE 0833, Teil 2",
            "correct_answer": false
        },
        {
            "choice": "nI den Aufschaltbedingungen der zuständigen Feuerwehr",
            "correct_answer": true
        },
        {
            "choice": "nI der EN 54 Teil 2",
            "correct_answer": false
        },
        {
            "choice": "nI der DIN 14675-1",
            "correct_answer": true
        }]
    },{
        
        "question_id": 325 ,
        "question_block_id": 264 ,
        "subcategory_id": 5.2 ,
        "question_points": 1 ,
        "question_text": "Wei viele Feuerwehrlaufkarten (Übersicht und Detailplan) sind mindestens vorzuhalten?",
        "question_choices":
        [{
            "choice": "Die Anzahl, die von der Baugenehmigungsbehörde vorgegeben wird",
            "correct_answer": false
        },
        {
            "choice": "Pro Meldebereich 1 Karte",
            "correct_answer": false
        },
        {
            "choice": "Pro Meldergruppe 1 Karte",
            "correct_answer": true
        },
        {
            "choice": "Pro Überwachungsbereich 1 Karte",
            "correct_answer": false
        }]
    },{
        
        "question_id": 327 ,
        "question_block_id": 265 ,
        "subcategory_id": 5.2 ,
        "question_points": 4 ,
        "question_text": "Welche Informationen müssen auf einer Feuerwehr-Laufkarte mindestens vorhanden sein?",
        "question_choices":
        [{
            "choice": "Gebäudeübersicht mit Grundriss",
            "correct_answer": true
        },
        {
            "choice": "Maße der Wände",
            "correct_answer": false
        },
        {
            "choice": "Dreidimensionale Raumdarstellung",
            "correct_answer": false
        },
        {
            "choice": "Meldergruppe",
            "correct_answer": true
        }]
    },{
        
        "question_id": 679 ,
        "question_block_id": 265 ,
        "subcategory_id": 5.2 ,
        "question_points": 4 ,
        "question_text": "Welche Informationen müssen auf einer Feuerwehr-Laufkarte mindestens vorhanden sein?",
        "question_choices":
        [{
            "choice": "Standort der Absperrschieber für Gas und Wasser",
            "correct_answer": false
        },
        {
            "choice": "Standfläche für Drehleiter",
            "correct_answer": false
        },
        {
            "choice": "Gebäudeübersicht mit Grundriss",
            "correct_answer": true
        },
        {
            "choice": "Detailplan für den Meldebereich",
            "correct_answer": true
        }]
    },{
        
        "question_id": 328 ,
        "question_block_id": 266 ,
        "subcategory_id": 5.3 ,
        "question_points": 1 ,
        "question_text": "Welche Voraussetzungen müssen für eine Abnahme gegeben sein?",
        "question_choices":
        [{
            "choice": "Es muss eine Inbetriebsetzung erfolgt sein und erkannte Mängel müssen mi Inbetriebsetzungsprotokoll aufgeführt sein.",
            "correct_answer": false
        },
        {
            "choice": "Es muss eine Inbetriebsetzung erfolgt sein und mi Inbetriebsetzungsprotokoll dürfen keine Mängel aufgeführt sein.",
            "correct_answer": true
        },
        {
            "choice": "Es muss eine Inbetriebsetzung erfolgt sein und mi Inbetriebsetzungsprotokoll dürfen nur geringfügige Mängel aufgeführt sein.",
            "correct_answer": false
        },
        {
            "choice": "Es muss eine Teilinbetriebsetzung erfolgt sein und erkannte Mängel und Restleistungen müssen mi Inbetriebsetzungsprotokoll aufgeführt sein.",
            "correct_answer": false
        }]
    },{
        
        "question_id": 329 ,
        "question_block_id": 267 ,
        "subcategory_id": 5.3 ,
        "question_points": 4 ,
        "question_text": "Wer ist bei einer nach Baurecht geforderten Brandmeldeanlage mi Regelfall zur Abnahme mit hinzuzuziehen?",
        "question_choices":
        [{
            "choice": "Der Planer.",
            "correct_answer": false
        },
        {
            "choice": "Der Brandschutzsachverständige.",
            "correct_answer": false
        },
        {
            "choice": "Ein Prüfsachverständiger.",
            "correct_answer": true
        },
        {
            "choice": "Der Architekt.",
            "correct_answer": false
        }]
    },{
        
        "question_id": 330 ,
        "question_block_id": 268 ,
        "subcategory_id": 5.3 ,
        "question_points": 4 ,
        "question_text": "Was ist bei der Abnahme einer BMA nach DIN 14675-1 zu prüfen?",
        "question_choices":
        [{
            "choice": "Ob die mi Konzept der BMA getroffenen Festlegungen eingehalten wurden.",
            "correct_answer": true
        },
        {
            "choice": "Die Fachkompetenz des zukünftigen Betreibers.",
            "correct_answer": false
        },
        {
            "choice": "Bei Abweichungen gegenüber dem Planungsauftrag, ob diese dem gestellten Schutzziel gerecht werden.",
            "correct_answer": true
        },
        {
            "choice": "Ob die ni der DIN 14675 geforderten technischen Funktionen eingehalten wurden.",
            "correct_answer": true
        }]
    },{
        
        "question_id": 331 ,
        "question_block_id": 269 ,
        "subcategory_id": 5.3 ,
        "question_points": 4 ,
        "question_text": "Welche Angaben muss das Abnahmeprotokoll mindestens enthalten?",
        "question_choices":
        [{
            "choice": "Die Maßnahmen der Brandschutzordnung.",
            "correct_answer": false
        },
        {
            "choice": "Die Art und Anzahl der aufgeschalteten Melder.",
            "correct_answer": true
        },
        {
            "choice": "Benennung der Verantwortlichen für die Systembetreuung und deren Erreichbarkeit.",
            "correct_answer": true
        },
        {
            "choice": "Die erkannten Abweichungen und Mängel.",
            "correct_answer": true
        }]
    },{
        
        "question_id": 332 ,
        "question_block_id": 270 ,
        "subcategory_id": 5.3 ,
        "question_points": 4 ,
        "question_text": "Was mus die Dokumentation zur Abnahme einer BMA entsprechend DNI 14675-1 mindestens enthalten?",
        "question_choices":
        [{
            "choice": "Ein Betriebsbuch.",
            "correct_answer": true
        },
        {
            "choice": "Ein Meldergruppenverzeichnis.",
            "correct_answer": true
        },
        {
            "choice": "Name und Telefonnummer des Inbetriebsetzers.",
            "correct_answer": false
        },
        {
            "choice": "Telefonnummer der hilfeleistenden Stelle.",
            "correct_answer": false
        }]
    },{
        
        "question_id": 333 ,
        "question_block_id": 271 ,
        "subcategory_id": 5.3 ,
        "question_points": 4 ,
        "question_text": "Wie muss eine Brandmeldeanlage geplant, projektiert und ausgeführt werden, wenn Abnahmen von mehr als einer Stelle erfolgen müssen und von diesen unterschiedliche Anforderungen gestellt werden?",
        "question_choices":
        [{
            "choice": "Nur der Baugenehmigungsbescheid ist maßgebend.",
            "correct_answer": false
        },
        {
            "choice": "die jeweils höheren Anforderungen sind maßgebend.",
            "correct_answer": true
        },
        {
            "choice": "Nach Absprache mit den Beteiligten.",
            "correct_answer": false
        },
        {
            "choice": "Der Betreiber entscheidet mi Zweifel.",
            "correct_answer": false
        }]
    },{
        
        "question_id": 334 ,
        "question_block_id": 272 ,
        "subcategory_id": 5.3 ,
        "question_points": 4 ,
        "question_text": "Wer mus das Abnahmeprotokol unterzeichnen?",
        "question_choices":
        [{
            "choice": "Der Inhaber der Fachfirma.",
            "correct_answer": false
        },
        {
            "choice": "Die Bauaufsicht.",
            "correct_answer": false
        },
        {
            "choice": "die für die Abnahmeprüfung Verantwortlichen und Beteiligten.",
            "correct_answer": true
        },
        {
            "choice": "Die eingewiesene Person.",
            "correct_answer": false
        }]
    },{
        
        "question_id": 335 ,
        "question_block_id": 273 ,
        "subcategory_id": 5.3 ,
        "question_points": 4 ,
        "question_text": "Welche Personen müssen bei einer Abnahme einer BMA nach DNI 14675-1 anwesend sein?",
        "question_choices":
        [{
            "choice": "Der Auftraggeber oder dessen jeweiliger Vertreter.",
            "correct_answer": true
        },
        {
            "choice": "die für die Abnahmeprüfung Verantwortlichen und Beteiligten.",
            "correct_answer": true
        },
        {
            "choice": "Ein Vertreter des oberen Bauaufsichtsamts.",
            "correct_answer": false
        },
        {
            "choice": "Der Sicherheitsbeauftragte des Betreibers.",
            "correct_answer": false
        }]
    },{
        
        "question_id": 336 ,
        "question_block_id": 274 ,
        "subcategory_id": 5.3 ,
        "question_points": 1 ,
        "question_text": "Wer ist nach der Übergabe für die Anlage verantwortlich?",
        "question_choices":
        [{
            "choice": "Der Ersteller des Brandschutznachweises.",
            "correct_answer": false
        },
        {
            "choice": "Die eingewiesene Person des Betreibers.",
            "correct_answer": true
        },
        {
            "choice": "Die Feuerwehr.",
            "correct_answer": false
        },
        {
            "choice": "Die Bauaufsichtsbehörde.",
            "correct_answer": false
        }]
    },{
        
        "question_id": 337 ,
        "question_block_id": 275 ,
        "subcategory_id": 5.3 ,
        "question_points": 4 ,
        "question_text": "Was muss einer Abnahme einer BMA nach DNI 14675-1 vorausgegangen sein?",
        "question_choices":
        [{
            "choice": "Die Erstellung des Inbetriebsetzungsprotokolls und der Ausführungsunterlagen.",
            "correct_answer": true
        },
        {
            "choice": "Eine Durchschaltung der Alarmmeldung an die beauftragte Stele.",
            "correct_answer": false
        },
        {
            "choice": "Eine mängelfreie Inbetriebsetzung.",
            "correct_answer": true
        },
        {
            "choice": "Die Unterzeichnung eines Instandhaltungsvertrages.",
            "correct_answer": false
        }]
        }
    ]
 }