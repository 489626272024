import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//redux store import
import Questions from './Questions';
import { MoveNextQuestion, MovePrevQuestion } from '../hooks/FetchQuestion';
import { PushAnswer } from '../hooks/setResult';
import { getNumQuestionsAnswered } from '../helper/helper';
import { resetAllAction } from '../redux/question_reducer';
import { resetResultAction } from '../redux/result_reducer';
import textToTranslate from '../database/text';


export default function Quiz() {

  const [check, setChecked] = useState(undefined);
  const result = useSelector(state => state.result.result);
  const { trace, queu } = useSelector(state => state.questions);
  const dispatch = useDispatch();
  const attempts = getNumQuestionsAnswered(result);
  const language = 'eng';
  const text = textToTranslate[language];

  console.log((result.length == queu.length).toString())
  function onRestart(){ //reset state when restart is pressed
    dispatch(resetAllAction());
    dispatch(resetResultAction());
  }

  /* prev button event handler*/
  function onPrev(){
    if (trace > 0){
      dispatch(MovePrevQuestion());
    }    
  };

  function onChecked(check){
    setChecked(check); //making it a global value
    
  }

  function onNext(){
    if(trace < queu.length){
      // console.log(queu);
      dispatch(MoveNextQuestion());
      if(result.length <= trace){
        dispatch(PushAnswer(check)); 
        console.log('answer pushed');//answer array [true, false, true, true ] to be pushed to the results array
      }}
    setChecked(undefined);
  };
  console.log(trace)
  return (
    <div className='container'>
        <h3 style={{ color: 'white' }}>{text.quizDin}</h3>
        {/*display questions*/}
        {(trace == 0 || trace != queu.length) &&  <Questions onChecked={onChecked}/>}
        <div>

        
        {
        
          trace == queu.length && 
          (
            attempts == queu.length ? 
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh'}}>
              <h1 style={{ color: 'white', marginBottom: '20px' }}>{text.submitQuizConfirmation}</h1>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <button className='btn prev' onClick={onPrev}>{text.back}</button>
                <Link className='btn next' to={'/result'}>{text.submitQuiz}</Link>
              </div>
            </div>

             :
             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
              <h1 style={{color:'white'}}>{text.missingAnswerWarning}</h1>
              <button className='btn prev' onClick={onPrev}>{text.back}</button>
             </div>
             
          )
        }
        <buttons style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '20px 0px' }}>
            <div>
              {trace == 0 && (
                  <Link className='btn prev' to={'/'} onClick={onRestart}>{text.resetQuiz}</Link>
                )}
                {trace > 0 && trace < queu.length && (
                  <button className='btn prev' onClick={onPrev}>{text.previous}</button>
                )}
            </div>
            <div>
              {trace < queu.length && (
              <button className='btn next' onClick={onNext}>{text.next}</button> )}
            </div>
        </buttons>
      
      </div>
          
    </div>
  )
}